import { Component, Prop, Watch, Vue } from "nuxt-property-decorator";
import _ from 'lodash'
import type {
    MetricValue,
} from './index'
import {
    isNum,
} from './utils'

@Component
export class MetricTrend extends Vue {
    item : MetricValue = null;
    lastData : number;
    lastDataTime: number;
    sensitive : number

    setup(item : MetricValue, sensitive : number) {
        this.item = item;
        this.lastData = item.valueRaw;
        this.lastDataTime = item.time;
        this.sensitive = sensitive;
    }

    value : 'nochange' | 'up' | 'down' = 'nochange';

    get currentValue() {
        return this.item?.valueRaw;
    }

    @Watch('currentValue')
    onValue() {
        if(this.item.time === this.lastDataTime) return;
        const newData = this.item.valueRaw;
        if(isNum(this.lastData)) {
            if(Math.abs(this.lastData - newData) > this.sensitive) {
                this.value = newData > this.lastData ? "up" : "down";
            } else {
                this.value = 'nochange';
            }
        }
        if(isNum(newData)) {
            this.lastData = newData;
            this.lastDataTime = this.item.time;
        }
    }

    toString() {
        return this.value
    }
}


