export default {
    //lightBlue: '#1183a1',
    //darkBlue: '#094554',
    //greyBorder: '#b7b7b7',
    //greyBtn: '#d1d1d1', '#D9D9D9'
    //greyInactive: '#f2f2f2', '#666666'
    //primary: '#53B7D5'
    //qt4: '#656565'
    //greyText: '#c2c2c2'
    //headerbg: '#d8d8d8', '#A4A4A4'
    //blueText: '#10809d', '#61A0B2'
    //mainBG: '#fff', '#818181'

    icons: {
        iconfont: "md",
    },
    theme: {
        dark: false,
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                primary: "#0F3C45",
                label: "#707070",
                secondary: "#00BCE5",
                accent: "#f0f8ff",
                info: "#324664",
                border: "#f5f6f9",
                blueBg: "#e9f5fe",
                warning: "#FB9119",
                error: "#BE2D2E",
                success: "#5CBF39",
                theme: "#29459E",
                darkTheme: "#283569",
                cardBG: "#fff",
                greyText: "#91AEC2",
                greyIcon: "#767676",
                blueText: "#094251",
                greyWhiteText: "#f6f7f8",
                mainBG: "#175566",
                darken1: "#757575",
                white: "#000",
                greyInactive: "#fff",
                greyBG: "#D3D3D3",
                mainBtn: "#0fadcf",
                headerBG: "#00BCE5",
                alertIcon: "#9E9E9E",
                alertBadges: "#62bcf5",
                primary0: "#9DF2FF",
                primary1: "#00BCE5",
                primary2: "#18687C",
                primary3: "#0F3C45",
                grey0: "#91AEC2",
                grey1: "#2E2E2E",
                grey2: "#181A1B",
                red0: "#F47171",
                white: "#fff",
                bg: "#175566",
                bgTrans: "#12414E",
            },
        },
    },
};
