
import helper from '~/plugins/helper'
import uuid from 'uuid/v4'

import _ from 'lodash'
import {EventEmitter} from 'events'

interface MessageHandler<T = any> {
    resolve?: (resp? : T) => void
    reject?: (e : any) => void
    callback?: (resp? : T) => void
}

interface CommandType {
    name? : string
    args? : any[]
    ns?: string
    id?: string
    type?: 'call' | 'on' | 'off' | 'reply' | 'callback'
    data? : any
    error? : any
}

export class MessageQueue {
    dict : {
        [key : string] : MessageHandler
    } = {}
    constructor(public ch : string, public postMessage? : (m : CommandType) => void) {
        this.dict = {};
        if(!this.postMessage) {
            this.postMessage = (cmd) => window.parent.postMessage(JSON.stringify(cmd), '*');
        }
    }

    call<T>(name : string, ...args) {
        const cmd = <CommandType>{
            name,
            args,
            ns: this.ch,
            id: uuid(),
            type: 'call',
        }
        return new Promise<T>((resolve, reject) => {
            this.dict[cmd.id] = { resolve, reject }
            this.postMessage(cmd);
        })
    }

    on<T = any>(name : string, handler : (resp? : T) => void) {
        const cmd = <CommandType>{
            name,
            ns: this.ch,
            id: uuid(),
            type: 'on',
        }
        this.dict[cmd.id] = { 
            callback: handler
        }
        this.postMessage(cmd);
        return cmd.id;
    }

    off(uuid : string) {
        const cmd = <CommandType>{
            ns: this.ch,
            id: uuid,
            type: 'off',
        }
        this.postMessage(cmd);
        delete this.dict[cmd.id];
    }

    resolve<T>(id : string, resp : T) {
        const item = this.dict[id];
        if(item) {
            delete this.dict[id];
            if(item.resolve) item.resolve(resp);
        }            
    }

    reject(id : string, resp : any) {
        const item = this.dict[id];
        if(item) {
            delete this.dict[id];
            if(item.reject) item.reject(resp);
        }            
    }

    callback<T>(id : string, resp : T) {
        const item = this.dict[id];
        if(item) {
            if(item.callback) item.callback(resp);
        }
    }
}

export function getVersion() {
    const version = window.navigator.userAgent.match(/BOXSPOS\/(\d+\.\d+\.\d+)/);
    return version && version[1];
}

export class MainMessageQueue extends MessageQueue {
    queues : {
        [key : string] : MessageQueue
    } = {};

    handlers: {
        [key : string] : {
            [key : string] : (...args : any[]) => any
        }
    } = {};

    supported : boolean = !!getVersion();

    register(ns : string, handler : { [key : string] : (...args : any[]) => any}) {
        _.merge(this.handlers, {
            [ns]: handler
        })
    }

    unregister(ns : string) {
        delete this.handlers[ns];
    }

    ns(ns : string) {
        if(!this.queues[ns]) this.queues[ns] = new MessageQueue(ns, this.postMessage);
        return this.queues[ns];
    }

    session = 0;
    reset() {
        this.session++;
    }

    listeners : {
        [key : string] : {
            cb: (data : any) => void;
            name : string
        }
    } = {};

    onMessage(data : CommandType) {
        const ns = data.ns || '';
        const q = this.ns(ns);
        const session = this.session;
        switch(data.type) {
            case 'reply': {
                if(data.error) {
                    q.reject(data.id, data.error);
                } else {
                    q.resolve(data.id, data.data);
                }
                break;
            }

            case 'callback': {
                q.callback(data.id, data.data);
                break;
            }

            case 'call':
                Promise.resolve().then(() => {
                    const q = this.handlers[data.ns || ''];
                    if(!q) throw new Error(`Namespace ${data.ns} not found`);
                    if(!q[data.name]) throw new Error(`Function ${data.ns}.${data.name} not found`);
                    return q[data.name](...data.args);
                }).then(resp => {
                    if(session !== this.session) return;
                    this.postMessage({
                        ns: data.ns,
                        id: data.id,
                        name: data.name,
                        type: 'reply',
                        data: resp,
                    });
                }).catch(e => {
                    if(session !== this.session) return;
                    console.warn(e);
                    this.postMessage({
                        ns: data.ns,
                        id: data.id,
                        name: data.name,
                        type: 'reply',
                        error: e.message || true,
                    });
                })
                break;

            case 'on': {
                const q = this.handlers[data.ns || ''];
                if(!q) return;

                let handler = (data) => {
                    if(session !== this.session) return;
                    this.postMessage({
                        ns: data.ns,
                        id: data.id,
                        name: data.name,
                        type: 'callback',
                        data: data,
                    });
                };

                this.listeners[data.id] = {
                    cb: handler,
                    name: data.name
                }

                q['on'](data.name, handler);
                break;
            }

            case 'off': {
                let item = this.listeners[data.id];
                if(!item) return;
                const q = this.handlers[data.ns || ''];
                if(q) {
                    q['off'](data.name, item.cb);
                }
                delete this.listeners[data.id];
                break;
            }
        }
    }
}

export let self : MainMessageQueue;

if(!process.server) {
    document.addEventListener('message', <any>((e : MessageEvent) => {
        let data = e.data;
        // console.log(data);
        if(typeof data === 'string') {
            try {
                data = JSON.parse(data);
            } catch(e) {
                return;
            }
        }
        self.onMessage(data);
    }));
    
    self = new MainMessageQueue('');
}


export const ns = self ? self.ns.bind(self) : null;

declare module 'vue/types/vue' {
    export interface Vue {
        $messageQueue : typeof self
    }
}

export default helper('messageQueue', (ctx) => {
    return self;
});
