
import _ from 'lodash'

export interface MetricDef {
    name: string
    key: string
    icon: string
    icon2: string
    component: string
    hasGraph?: boolean
    expandToTop?: boolean
    hasDetails?: boolean
    expandClass?: string
    defClass? : string
}

export const metrics = [
    {
        name: "dashboard.allMetrics.temp",
        key: 'temp',
        icon: require("~/assets/images/outdoor.png"),
        icon2: require("~/assets/images/outdoor2.png"),
        component: "weather-outdoor-card",
    },
    {
        name: "dashboard.allMetrics.wind",
        key: 'wind',
        icon: require('~/assets/images/wind.png'),
        icon2: require("~/assets/images/wind2.png"),
        component: "weather-wind-card",
    },
    {
        name: "dashboard.allMetrics.baro",
        key: 'baro',
        icon: require('~/assets/images/pressure.png'),
        icon2: require("~/assets/images/pressure2.png"),
        component: "weather-baro-card",
    },
    {
        name: "dashboard.allMetrics.rain",
        key: 'rain',
        icon: require('~/assets/images/rain.png'),
        icon2: require("~/assets/images/rain2.png"),
        component: "weather-rain-card",
    },
    {
        name: "dashboard.allMetrics.indoor",
        key: 'indoor',
        icon: require('~/assets/images/indoor.png'),
        icon2: require("~/assets/images/indoor2.png"),
        component: "weather-indoor-card",
    },
    {
        name: "dashboard.allMetrics.uv",
        key: 'uv',
        icon: require('~/assets/images/uvlight.png'),
        icon2: require("~/assets/images/uvlight2.png"),
        component: "weather-uv-card",
    },
    {
        name: "dashboard.allMetrics.light",
        key: 'light',
        icon: require('~/assets/images/light.png'),
        icon2: require("~/assets/images/light2.png"),
        component: "weather-light-card",
    },
    {
        name: "dashboard.allMetrics.sunmoon",
        key: 'sunmoon',
        icon: require('~/assets/images/sunmoon.png'),
        icon2: require("~/assets/images/sunmoon2.png"),
        component: "weather-sunmoon-card",
        hasGraph: false,
    },
    {
        name: "dashboard.allMetrics.forecast",
        key: 'forecast',
        icon: require('~/assets/images/forecast.png'),
        icon2: require("~/assets/images/forecast2.png"),
        component: "weather-forecast-card",
        expandClass: "fullRow responFullRow responForecast",
        hasGraph: false,
        expandToTop: true,
    },
    {
        name: "dashboard.allMetrics.overview",
        key: 'overview',
        icon: require('~/assets/images/overview.png'),
        icon2: require("~/assets/images/overview2.png"),
        component: "weather-overview-card",
        hasGraph: false,
    },
    {
        name: "dashboard.allMetrics.sky",
        key: 'sky',
        icon: require('~/assets/images/sky.png'),
        icon2: require("~/assets/images/sky2.png"),
        component: "weather-sky-card",
        hasGraph: false,
        hasDetails: false,
    },
    {
        name: "dashboard.allMetrics.channelN",
        key: 'channel',
        loop: 7,
        icon: require('~/assets/images/ch.png'),
        icon2: require("~/assets/images/ch2.png"),
        component: "weather-channel-card",
    },
    {
        name: "dashboard.allMetrics.waterLeakage",
        key: 'waterLeakage',
        icon: require('~/assets/images/waterleakage.png'),
        icon2: require('~/assets/images/waterleakage2.png'),
        component: "weather-leakage-card",
        hasDetails: false,
        hasGraph: false,
    },
    {
        name: "dashboard.allMetrics.pm",
        key: 'pm',
        icon: require('~/assets/images/pm.png'),
        icon2: require('~/assets/images/pm1.png'),
        component: "weather-pm-card",
    },
    {
        name: "dashboard.allMetrics.pmChannelN",
        key: 'pmChannel',
        loop: 4,
        icon: require('~/assets/images/pm.png'),
        icon2: require('~/assets/images/pm1.png'),
        component: "weather-pm-card",
    },
    {
        name: "dashboard.allMetrics.lightning",
        key: 'lightning',
        icon: require('~/assets/images/lightning.png'),
        icon2: require("~/assets/images/lightning2.png"),
        component: "weather-lightning-card",
        hasDetails: false,
    },
    {
        name: "dashboard.allMetrics.hcho",
        key: 'hcho',
        icon: require('~/assets/images/HCHO_hidden.png'),
        icon2: require("~/assets/images/HCHO_show.png"),
        component: "weather-hcho-card",
        hasDetails: true,
    },
    {
        name: "dashboard.allMetrics.co",
        key: 'co',
        icon: require('~/assets/images/CO_hidden.png'),
        icon2: require("~/assets/images/CO_show.png"),
        component: "weather-co-card",
        hasDetails: true,
    },
    {
        name: "dashboard.allMetrics.co2",
        key: 'co2',
        icon: require('~/assets/images/CO2_hidden.png'),
        icon2: require("~/assets/images/CO2_show.png"),
        component: "weather-co2-card",
        hasDetails: true,
    },
    {
        name: "dashboard.allMetrics.batt",
        key: 'batt',
        icon: require('~/assets/images/battery_hidden.png'),
        icon2: require("~/assets/images/battery_show.png"),
        component: "weather-batt-card",
        hasDetails: false,
        hasGraph: false
    },
    {
        name: "dashboard.allMetrics.wbgt",
        key: 'wbgt',
        icon: require('~/assets/images/wbgt2.png'),
        icon2: require("~/assets/images/wbgt.png"),
        component: "weather-wbgt-card",
        hasDetails: true,
    },
] as const;

export interface AlertType {
    name: string,
    key: string,
    metrics: AlertMetric[]
    n? : number
}

export const alerts = [
    {
        name: 'alertCard.device',
        key: 'device',
        metrics: [
            {
                name: 'alertCard.lostData',
                key: 'lostuploadi',
                conditions: null,
            },
            {
                name : 'alertCard.dataNotStable',
                key: 'unstablei',
                conditions: null,
            },
        ] as AlertMetric[]
    },
    {
        name: 'alertCard.device',
        key: 'device-indoor',
        metrics: [
            {
                name: 'alertCard.lostData',
                key: 'lostuploadi',
                conditions: null,
            },
            {
                name : 'alertCard.dataNotStable',
                key: 'unstablei',
                conditions: null,
            },
            {
                name : "alertCard.indoorTemp",
                key: 'tempi',
                unit: 'temperature',
            },
            {
                name: "alertCard.indoorHumi",
                key: 'humi',
                unit: 'humidity'
            },
            {
                name: "alertCard.baro",
                key: 'relbaroi',
                conditions: ['overOrEqual'],
                unit: 'baroPressure'
            },
        ] as AlertMetric[]
    },
    {
        name: 'alertCard.outdoor',
        key: 'outdoor',
        metrics: [
            {
                name: 'alertCard.lostLink',
                key: 'lostlinkc0',
                conditions: null,
            },
            // {
            //     name : 'alertCard.lowBattery',
            //     key: 'lowbattc0',
            //     conditions: null,
            // },
            {
                name : "alertCard.temp",
                key: 'tempc0',
                unit: 'temperature',
            },
            {
                name: "alertCard.humi",
                key: 'humc0',
                unit: 'humidity',
            },
            {
                name : "alertCard.feelsLike",
                key: 'feelslike',
                unit: 'temperature',
                min: -65,
                max: 50,
            },
            {
                name : "alertCard.dewpoint",
                key: 'dewpoint',
                unit: 'temperature',
                max: 80,
            },
            {
                name : "alertCard.heatIndex",
                key: 'heatindex',
                unit: 'temperature',
                conditions: ['above'],
                min: 26,
                max: 50,
            },
            {
                name : "Wind chill",
                key: 'windchill',
                unit: 'temperature',
                conditions: ['below'],
                min: -65,
                max: 18,
            },
            {
                name : "alertCard.windSpeed",
                key: 'windspd',
                unit: 'windSpeed',
                conditions: ['above'],
            },
            {
                name : "alertCard.rainRate",
                key: 'rainrate',
                unit: 'rain',
                conditions: ['above'],
            },
            {
                name : "alertCard.uvIndex",
                key: 'uvi',
                unit: 'uvi',
                conditions: ['above'],
            },
            {
                name : "alertCard.light",
                key: 'solarrad',
                unit: "lightIntensity",
                conditions: ['above'],
            },
        ] as AlertMetric[]
    },
    {
        name: 'alertCard.channelN',
        key: 'channel',
        metrics: [
            {
                name: 'alertCard.lostLink',
                key: 'lostlinkc',
                conditions: null,
            },
            // {
            //     name: 'alertCard.lowBattery',
            //     key: 'lowbattc',
            //     conditions: null,
            // },
            {
                name: 'alertCard.temp',
                key: 'tempc',
                unit: 'temperature'
            },
            {
                name: 'alertCard.humi',
                key: 'humc',
                unit: 'humidity',
            },
            // {
            //     name: 'Soil moisture',
            //     key: 'humc',
            //     unit: 'humidity'
            // },
        ] as AlertMetric[]
    },
    {
        name: 'alertCard.pmN',
        key: 'pm',
        metrics: [
            {
                name: 'alertCard.lostLink',
                key: 'lostlink_pmc',
                conditions: null,
            },
            // {
            //     name: 'alertCard.lowBattery',
            //     key: 'lowbatt_pmc',
            //     conditions: null,
            // },
            {
                name: 'alertCard.pm25',
                key: 'tp8pm2p5c',
                min: 1,
                max: 500,
                conditions: ['above'],

            },
            {
                name: 'alertCard.pm25aqi',
                key: 'tp8pm2p5aqic',
                conditions: ['above'],
                min: 1,
                max: 500,
            },
            {
                name: 'alertCard.pm10',
                key: 'tp8pm10c',
                conditions: ['above'],
                min: 1,
                max: 500,

            },
            {
                name: 'alertCard.pm10aqi',
                key: 'tp8pm10aqic',
                conditions: ['above'],
                min: 1,
                max: 604,
            },
        ] as AlertMetric[]
    },
    {
        name: 'alertCard.pm',
        key: 'pmNoChannel',
        metrics: [
            {
                name: 'alertCard.lostLink',
                key: 'lostlink_pmc1',
                conditions: null,
            },
            // {
            //     name: 'alertCard.lowBattery',
            //     key: 'lowbatt_pmc',
            //     conditions: null,
            // },
            {
                name: 'alertCard.pm25',
                key: 'tp8pm2p5c1',
                min: 1,
                max: 500,
                conditions: ['above'],
                unit: 'pm2510'
            },
            {
                name: 'alertCard.pm25aqi',
                key: 'tp8pm2p5aqic1',
                conditions: ['above'],
                min: 1,
                max: 500,
            },
            {
                name: 'alertCard.pm10',
                key: 'tp8pm10c1',
                conditions: ['above'],
                min: 1,
                max: 500,
                unit: 'pm2510'
            },
            {
                name: 'alertCard.pm10aqi',
                key: 'tp8pm10aqic1',
                conditions: ['above'],
                min: 1,
                max: 604,
            },
        ] as AlertMetric[]
    },
    {
        name: 'alertCard.leakageN',
        key: 'waterLeakage',
        metrics: [
            {
                name: 'alertCard.lostLink',
                key: 'lostlink_leakc',
                conditions: null,
            },
            // {
            //     name: 'alertCard.lowBattery',
            //     key: 'lowbatt_leakc',
            //     conditions: null,
            // },
            {
                name: 'componentCard.leakage.leakage',
                key: 'tp6wlsc',
                conditions: null,
            },
        ] as AlertMetric[]
    },
    {
        name: 'alertCard.lightning',
        key: 'lightning',
        metrics: [
            {
                name: 'alertCard.lostLink',
                key: 'lostlink_light',
                conditions: null,
            },
            // {
            //     name: 'alertCard.lowBattery',
            //     key: 'lowbatt_light',
            //     conditions: null,
            // },
            {
                name: 'alertCard.strikeHour',
                key: 'solarrad',
                unit: '',
                conditions: ['above'],
                min: 1,
                max: 1000,
            },
            {
                name: "alertCard.strikeDistance",
                key: 'tp5ch0lskm',
                unit: 'distance',
                min: 1,
                max: 40,
            },
        ] as AlertMetric[]
    },
    {
        name: 'alertCard.co2',
        key: 'co2',
        metrics: [
            {
                name: 'alertCard.lostLink',
                key: 'lostlink_co2',
                conditions: null,
            },
            {
                name: 'dashboard.showNameMapping.co2',
                key: 'tp10co2c1',
                min: 401,
                max: 5000,
                conditions: ['above'],
                unit: "co2"
            },
        ] as AlertMetric[]
    },
    {
        name: 'alertCard.hcho',
        key: 'hcho',
        metrics: [
            {
                name: 'alertCard.lostLink',
                key: 'lostlink_hcho',
                conditions: null,
            },
            {
                name: 'dashboard.showNameMapping.hcho',
                key: 'tp9hchoc1',
                unit: "hcho",
                min: 1,
                max: 1000,
                conditions: ['above'],
            },
        ] as AlertMetric[]
    },
    {
        name: 'alertCard.wbgt',
        key: 'wbgt',
        metrics: [
            {
                name: 'dashboard.showNameMapping.wbgt',
                key: 'tp1wgbtc0',
                unit: "temperature",
                min: 10,
                max: 50,
                conditions: ['above'],
            },
        ] as AlertMetric[]
    },
    {
        name: 'alertCard.co',
        key: 'co',
        metrics: [
            {
                name: 'alertCard.lostLink',
                key: 'lostlink_co',
                conditions: null,
            },
        ] as AlertMetric[]
    },
] as const;

export type MetricKey = (typeof metrics)[number]['key']
export type AlertKey = (typeof alerts)[number]['key']
export interface MetricOpts {
    key: MetricKey
    count?: number
    props? : any
    hidden? : boolean | boolean[]
    name?: string
}

export interface AlertOpts {
    key: AlertKey
    count?: number
}

export interface AlertMetric {
    name: string
    key: string
    conditions? : string[]
    unit?: string
    min? : number
    max?: number
}

export interface DeviceType {
    id: number
    metrics: (MetricKey | MetricOpts)[]
    alerts: (AlertKey | AlertOpts)[]
    name: any
    icon?: string
    icond?: string
    subscription?: boolean
}

export const deviceTypes = [
    {
        id: 0,
        name: "",
        metrics: [
            {
                key: 'overview',
                props: {
                    hasLightning: true,
                    hasPm: true,
                    hasIndoor: true,
                }
            },
            'forecast',
            'wind',
            'baro',

            'temp',
            'uv',
            'light',
            'rain',

            'indoor',
            'sky',
            'sunmoon',

            {
                key: 'channel',
                count: 7,
                hidden: [false, true, true, true, true, true, true],
            },

            {
                key: 'waterLeakage',
                hidden: true
            },
            {
                key: 'lightning',
                hidden: true
            },
            {
                key: 'pmChannel',
                count: 4,
                hidden: true
            },
            
        ],
        alerts: [
            'device-indoor',
            'outdoor',
            {
                key: 'channel',
                count: 7,
            },
            {
                key: 'pmChannel',
                count: 4,
            },
            {
                key: 'waterLeakage',
                count: 7,
            },
            'lightning',
        ]
    },

    {
        id: 1,
        icon: require("~/assets/images/device-type-1.png"),
        icond: require("~/assets/images/device-type-1-dark.png"),
        name: "TFT weather station",
        metrics: [
            {
                key: 'overview',
                props: {
                    hasIndoor: true,
                }
            },
            'forecast',
            'wind',
            'baro',

            'temp',
            'uv',
            'light',
            'rain',

            'indoor',
            'sky',
            'sunmoon',

            {
                key: 'channel',
                count: 7,
                hidden: [false, true, true, true, true, true, true],
            },
            {
                key: 'batt',
            },
        ],
        alerts: [
            'device-indoor',
            'outdoor',
            {
                key: 'channel',
                count: 7,
            },
        ]
    },

    {
        id: 2,
        icon: require("~/assets/images/device-type-2.png"),
        icond: require("~/assets/images/device-type-2-dark.png"),
        name: "7-in-1 weather station",
        metrics: [
            'overview',
            'forecast',
            'wind',
            'baro',

            'temp',
            'uv',
            'light',
            'rain',

            'sky',
            'sunmoon',

            {
                key: 'channel',
                count: 7,
                hidden: [false, true, true, true, true, true, true],
            },
            {
                key: 'batt',
            },
        ],
        alerts: [
            'device',
            'outdoor',
            {
                key: 'channel',
                count: 7,
            },
        ]
    },

    {
        id: 3,
        icon: require("~/assets/images/device-type-3.png"),
        icond: require("~/assets/images/device-type-3-dark.png"),
        name: "Multi-day forecast weather station",
        metrics: [
            {
                key: 'overview',
                props: {
                    hasLightning: true,
                    hasPm: true,
                    hasIndoor: true,
                }
            },
            'forecast',
            'wind',
            'baro',

            'temp',
            'uv',
            'light',
            'rain',

            'indoor',
            'sky',
            'sunmoon',

            {
                key: 'channel',
                count: 7,
            },

            {
                key: 'waterLeakage',
                hidden: true
            },
            {
                key: 'lightning',
                hidden: true
            },
            {
                key: 'pmChannel',
                count: 4,
                hidden: true
            },
            {
                key: 'batt',
            },
        ],
        alerts: [
            'device-indoor',
            'outdoor',
            {
                key: 'channel',
                count: 7,
            },
            {
                key: 'pm',
                count: 4,
            },
            {
                key: 'waterLeakage',
                count: 7,
            },
            'lightning',
        ]
    },

    {
        id: 4,
        icon: require("~/assets/images/device-type-4.png"),
        icond: require("~/assets/images/device-type-4-dark.png"),
        name: "Tuya smart weather station",
        metrics: [
            {
                key: 'overview',
                props: {
                    hasLightning: true,
                    hasIndoor: true,
                }
            },
            'wind',
            'baro',
            'temp',
            
            'uv',
            'light',
            'rain',
            'indoor',

            'sunmoon',

            {
                key: 'channel',
                count: 3,
                hidden: false,
            },
        ],
        alerts: [
            'device-indoor',
            'outdoor',
            {
                key: 'channel',
                count: 3,
            },
        ]
    },

    {
        id: 5,
        icon: require("~/assets/images/device-type-5.png"),
        icond: require("~/assets/images/device-type-5-dark.png"),
        name: "4-day forecast weather station",
        metrics: [
            {
                key: 'overview',
                props: {
                    hasIndoor: true,
                }
            },
            'forecast',
            'wind',
            'baro',

            'temp',
            'uv',
            'light',
            'rain',

            'indoor',
            'sky',
            'sunmoon',

            {
                key: 'channel',
                count: 7,
                hidden: false,
            },

            {
                key: 'waterLeakage',
                hidden: true
            },
            {
                key: 'batt',
            },
        ],
        alerts: [
            'device-indoor',
            'outdoor',
            {
                key: 'channel',
                count: 7,
            },
            {
                key: 'waterLeakage',
                count: 7,
            },
        ]
    },

    {
        id: 6,
        icon: require("~/assets/images/device-type-5.png"), // seems currently not used
        icond: require("~/assets/images/device-type-5-dark.png"), // seems currently not used
        name: "24H 10-Day Forecast Weather Station",
        metrics: [
            {
                key: 'overview',
                props: {
                    hasIndoor: true,
                    hasAirQuality: true,
                    hasLightning: true
                }
            },
            'forecast',
            'wind',
            'baro',

            'temp',
            'uv',
            'light',
            'rain',

            'indoor',
            'sky',
            'sunmoon',

            {
                key: 'channel',
                count: 7,
                hidden: false,
            },

            {
                key: 'waterLeakage',
                hidden: true
            },

            {
                key: 'lightning',
                hidden: true
            },
            {
                key: 'pm',
                hidden: true
            },

            {
                key: 'hcho',
                hidden: true
            },
            {
                key: 'co2',
                hidden: true
            },
            {
                key: 'co',
                hidden: true
            },

            {
                key: 'batt',
            },
        ],
        alerts: [
            'device-indoor',
            'outdoor',
            {
                key: 'channel',
                count: 7,
            },
            {
                key: 'waterLeakage',
                count: 7,
            },
            'lightning',
            'pmNoChannel',
            {
                key: 'hcho'
            },
            {
                key: 'co'
            },
            {
                key: 'co2'
            },
        ]
    },

    {
        id: 7,
        subscription: true,
        icon: require("~/assets/images/device-type-5.png"), // seems currently not used
        icond: require("~/assets/images/device-type-5-dark.png"), // seems currently not used
        name: "HD TFT Weather Station",
        metrics: [
            {
                key: 'overview',
                props: {
                    hasIndoor: true,
                    hasAirQuality: true,
                    hasLightning: true
                }
            },
            'forecast',
            'wind',
            'baro',

            'temp',
            'wbgt',
            'uv',
            'light',

            'rain',
            'indoor',
            'sky',
            'sunmoon',

            {
                key: 'channel',
                count: 7,
                hidden: true,
            },

            {
                key: 'waterLeakage',
                hidden: true
            },

            {
                key: 'lightning',
                hidden: true
            },
            {
                key: 'pm',
                hidden: true
            },

            {
                key: 'hcho',
                hidden: true
            },
            {
                key: 'co2',
                hidden: true
            },
            {
                key: 'co',
                hidden: true
            },

            {
                key: 'batt',
            },
        ],
        alerts: [
            'device-indoor',
            'outdoor',
            {
                key: 'channel',
                count: 7,
            },
            {
                key: 'waterLeakage',
                count: 7,
            },
            'lightning',
            'pmNoChannel',
            {
                key: 'hcho'
            },
            {
                key: 'co'
            },
            {
                key: 'co2'
            },
            {
                key: 'wbgt'
            },
        ]
    },
    
    {
        id: 8,
        icon: require("~/assets/images/device-type-1.png"), // seems currently not used
        icond: require("~/assets/images/device-type-1-dark.png"), // seems currently not used
        name: "Air quality Weather Station",
        metrics: [
            {
                key: 'overview',
                props: {
                    hasIndoor: true,
                    hasAirQuality: true,
                    hasLightning: false
                }
            },
            'forecast',
            'wind',
            'baro',

            'temp',
            'uv',
            'light',
            'rain',

            'indoor',
            'sky',
            'sunmoon',

            {
                key: 'channel',
                count: 7,
                hidden: true,
            },
            {
                key: 'pm',
                hidden: true
            },

            {
                key: 'hcho',
                hidden: true
            },
            {
                key: 'co2',
                hidden: true
            },
            {
                key: 'co',
                hidden: true
            },

            {
                key: 'batt',
            },
        ],
        alerts: [
            'device-indoor',
            'outdoor',
            {
                key: 'channel',
                count: 7,
            },
            'pmNoChannel',
            {
                key: 'hcho'
            },
            {
                key: 'co'
            },
            {
                key: 'co2'
            },
        ]
    },

    {
        id: 9,
        icon: require("~/assets/images/device-type-1.png"), // seems currently not used
        icond: require("~/assets/images/device-type-1-dark.png"), // seems currently not used
        name: "Air quality Weather Station",
        metrics: [
            {
                key: 'overview',
                props: {
                    hasIndoor: true,
                    hasAirQuality: true,
                    hasLightning: false
                }
            },
            'forecast',
            'wind',
            'baro',

            'temp',
            'uv',
            'light',
            'rain',

            'indoor',
            'sky',
            'sunmoon',
            {
                key: 'channel',
                count: 3,
                hidden: true,
            },
            {
                key: 'waterLeakage',
                hidden: true
            },
            {
                key: 'pm',
                hidden: true
            },

            {
                key: 'hcho',
                hidden: true
            },
            {
                key: 'co2',
                hidden: true
            },
            {
                key: 'co',
                hidden: true
            },

            {
                key: 'batt',
            },
        ],
        alerts: [
            'device-indoor',
            'outdoor',
            {
                key: 'channel',
                count: 3,
            },
            'pmNoChannel',
            {
                key: 'waterLeakage',
                count: 7,
            },
            {
                key: 'hcho'
            },
            {
                key: 'co'
            },
            {
                key: 'co2'
            },
        ]
    },

    {
        id: 10,
        subscription: true,
        icon: require("~/assets/images/device-type-5.png"), // seems currently not used
        icond: require("~/assets/images/device-type-5-dark.png"), // seems currently not used
        name: "Weather Station Gateway",
        metrics: [
            {
                key: 'overview',
                props: {
                    hasIndoor: true,
                    hasAirQuality: true,
                    hasLightning: true
                }
            },
            'forecast',
            {
                key: 'wind',
                hidden: true
            },
            'baro',

            {
                key: 'temp',
                hidden: true
            },
            {
                key: 'wbgt',
                hidden: true
            },
            {
                key: 'uv',
                hidden: true
            },
            {
                key: 'light',
                hidden: true
            },

            {
                key: 'rain',
                hidden: true
            },
            'indoor',
            'sky',
            'sunmoon',

            {
                key: 'channel',
                count: 7,
                hidden: true,
            },

            {
                key: 'waterLeakage',
                hidden: true
            },

            {
                key: 'lightning',
                hidden: true
            },
            {
                key: 'pm',
                hidden: true
            },

            {
                key: 'hcho',
                hidden: true
            },
            {
                key: 'co2',
                hidden: true
            },
            {
                key: 'co',
                hidden: true
            },

            {
                key: 'batt',
            },
        ],
        alerts: [
            'device-indoor',
            'outdoor',
            {
                key: 'channel',
                count: 7,
            },
            {
                key: 'waterLeakage',
                count: 7,
            },
            'lightning',
            'pmNoChannel',
            {
                key: 'hcho'
            },
            {
                key: 'co'
            },
            {
                key: 'co2'
            },
            {
                key: 'wbgt'
            },
        ]
    },

    {
        id: 11,
        subscription: true,
        icon: require("~/assets/images/device-type-5.png"), // seems currently not used
        icond: require("~/assets/images/device-type-5-dark.png"), // seems currently not used
        name: "WBGT Weather Station",
        metrics: [
            {
                key: 'overview',
                props: {
                    hasIndoor: true,
                    hasAirQuality: false,
                    hasLightning: false
                }
            },
            'forecast',
            {
                key: 'wind',
            },
            'baro',

            {
                key: 'temp',
            },
            {
                key: 'wbgt',
            },
            {
                key: 'uv',
            },
            {
                key: 'light',
            },

            {
                key: 'rain',
            },
            'indoor',
            'sky',
            'sunmoon',

            {
                key: 'channel',
                count: 7,
                hidden: true,
            },

            {
                key: 'batt',
            },
        ],
        alerts: [
            'device-indoor',
            'outdoor',
            {
                key: 'channel',
                count: 7,
            },
            {
                key: 'waterLeakage',
                count: 7,
            },
            'lightning',
            'pmNoChannel',
            {
                key: 'wbgt'
            },
        ]
    },
] as DeviceType[]

export const deviceTypeDict = _.fromPairs(_.map(deviceTypes, t => [t.id, t]))
export const metricDict = _.fromPairs(_.map(metrics, t => [t.key, t]))

