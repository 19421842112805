<script>

import { Line, mixins } from 'vue-chartjs'
import _ from 'lodash'
import reactive from './reactive'

import Chart from 'chart.js'
import { generateChart } from 'vue-chartjs'
Chart.controllers.LineWithLine = Chart.controllers.line.extend({
    draw: function(ease) {
        Chart.controllers.line.prototype.draw.call(this, ease);
        

        if (this.chart.tooltip._active && this.chart.tooltip._active.length) {
            var activePoint = this.chart.tooltip._active[0],
                ctx = this.chart.ctx,
                x = activePoint.tooltipPosition().x,
                topY = this.chart.scales['y-axis-0'].top,
                bottomY = this.chart.scales['y-axis-0'].bottom;

            // draw line
            ctx.save();
            ctx.beginPath();
            ctx.lineWidth = 1;
            ctx.moveTo(x, topY);
            ctx.lineTo(x, bottomY);
            ctx.strokeStyle = '#999999';
            ctx.stroke();
            ctx.restore();
        }
    }
});
const CustomLine = generateChart('custom-line', 'LineWithLine')

let charts = [];

export default {
    extends: CustomLine,
    mixins: [reactive({chartData: 'mchartData', options: 'moptions'})],
    computed: {
        moptions() {
            return _.merge({
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                max: this.max,
                            }
                        }
                    ]
                }
            }, this.options || {});
        },
        mchartData() {
            return _.merge({
                datasets: _.map((this.chartData || {}).datasets || [], (dataset, idx) => ({
                    backgroundColor: (idx % 2) ? this.gradient2 : this.gradient,
                }))
            }, this.chartData || {});
        }
    },
    props: ['labels', 'data', 'options', 'max'],
    data() {
        return {
            gradient: null,
            gradient2: null,
        };
    },
    mounted() {
        const ctx = this.$el.getElementsByTagName("canvas")[0].getContext("2d");
        this.gradient = ctx.createLinearGradient(0, 0, 0, 400);
        this.gradient2 = ctx.createLinearGradient(0, 0, 0, 400);

        this.gradient2.addColorStop(0, "rgba(3, 85, 179, 0.9)");
        this.gradient2.addColorStop(0.5, "rgba(3, 85, 179, 0.5)");
        this.gradient2.addColorStop(1, "rgba(2, 27, 121, 0.25)");

        this.gradient.addColorStop(0, "rgba(132, 30, 122, 1)");
        this.gradient.addColorStop(0.5, "rgba(132, 30, 122, 0.8)");
        this.gradient.addColorStop(1, "rgba(90, 33, 122, 0.75)");

        this.renderChart(this.mchartData, this.moptions)
        this.chart = this.$data._chart;
        Chart.Legend.prototype.afterFit = function() {
            this.height = this.height + 20;
        };
        if(this.chart) {
            charts.push(this.chart);
            this.chart._onEvent = this.onChartEvent;
        }
    },
    beforeDestroy() {
        if(this.chart) {
            delete this.chart._onEvent;
            const idx = charts.indexOf(this.chart);
            charts.splice(idx, 1);
            this.chart = null;
        }
    },
    methods: {
        onChartEvent(e) {
            if(e.type !== 'mouseout' && e.type !== 'mouseenter' && e.type !== 'mousemove') return;
            charts.forEach(t => {
                if(t !== this.chart) {
                    t.eventHandler(e, true);
                }
            })
        }
    }
}
</script>