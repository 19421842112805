import axios from "axios";
import _ from "lodash";
import moment from "moment";
import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const mappedLocales = {
    "zh-hk": "cht",
    "zh-cn": "chs",
    "en-us": "en",
    "en-hk": "en",
    "en": "en",
    "it": "it",
    "fr": "fr",
    "de": "de",
    "es": "es",
    "nl": "nl",
    "cs": "cs",
};

const unmappedLocales = {
    cht: "zh-hk",
    chs: "zh-cn",
};

export function bind(el, binding, vnode) {
    t(el, binding, vnode);
}

function localeEqual(el, vnode) {
    const vm = vnode.context;
    return el._locale === vm.$i18n.locale;
}

export function update(el, binding, vnode, oldVNode) {
    if (
        localeEqual(el, vnode) &&
        _.isEqualWith(binding.value, binding.oldValue)
    )
        return;
    t(el, binding, vnode);
}

function t(el, binding, vnode) {
    let value = binding.value;
    let result = "";

    const vm = vnode.context;
    if (!vm) {
        warn("not exist Vue instance in VNode context");
        return;
    }
    const i18n = vm.$i18n;
    if (!i18n) {
        warn("not exist VueI18n instance in Vue instance");
        return;
    }

    if (value) {
        let $ta = value.$ta;
        let $a = value.$a;
        if (value.path) {
            if (value.args) $ta = value.args;
            value = value.path;
        }

        if (typeof value === "string") {
            result = value;
        } else if (typeof value === "object") {
            if (value.$t) {
                if ($ta) {
                    const args = {
                        ...$a,
                        ..._.mapValues($ta, (it) => vm.$td(it)),
                    };
                    result = vm.$i18n.t(value.$t, args);
                } else if ($a) {
                    result = vm.$i18n.t(value.$t, $a);
                } else {
                    result = i18n.t(value.$t);
                }
            } else {
                result =
                    value[i18n.locale] ||
                    value["en"] ||
                    _.map(value, (it) => it)[0] ||
                    "";
            }
        }
    }

    el._vt = el.textContent = result;
    el._locale = i18n.locale;
}

Vue.directive("td", {
    bind,
    update,
});

let i18NInit = false;

Vue.prototype.$ensureI18N = function() {
    if (process.browser) {
        if (i18NInit) return;
        moment.locale(this.$store.getters.localeCode);
        i18NInit = true;
    }
};

Vue.prototype.$td = function(item, locale) {
    if (typeof item === "string") return item;
    else if (!item) return "";

    const i18n = this.$i18n || this.$root.$i18n;
    const unmapLocale = locale
        ? unmappedLocales[locale] || locale
        : i18n.locale;
    locale = locale || i18n.locale;
    locale = mappedLocales[locale] || locale;

    if (item.$join) {
        return _.map(item.$join, (it) => this.$td(it, locale)).join("");
    } else if (item.$t) {
        if (item.$ta) {
            const args = {
                ...item.$a,
                ..._.mapValues(item.$ta, (it) => this.$td(it, locale)),
            };
            return i18n.t(item.$t, unmapLocale, args);
        } else if (item.$a) {
            return i18n.t(item.$t, unmapLocale, item.$a);
        } else {
            return i18n.t(item.$t, unmapLocale, {});
        }
    } else if (_.isArray(item)) {
        let enValue, locValue, defValue;
        _.each(item, (v) => {
            if (v.lang === locale) locValue = v.value;
            if (v.lang === "en") enValue = v.value;
            defValue = v.value;
        });
        return locValue || enValue || defValue;
    }

    return item[locale] || item["en"] || _.map(item, (it) => it)[0] || "";
};



const i18n = ({ app, store }) => {
    const dict = {};
    let missingTranslationSetup = true;
    app.i18n = new VueI18n({
        locale: store.state.locale,
        fallbackLocale: "en",
        silentTranslationWarn: true,
        async missing(lang, key) {
            if (!PRODUCTION_MODE) {
                if (!dict[key]) {
                    dict[key] = true;
                    if(missingTranslationSetup){
                        try {
                            await axios.post(`${API_URL}/api/missingTranslations`, {
                                key,
                            }); 
                        } catch (error) {
                            console.warn(`[i18n] ${API_URL}/api/missingTranslations was not set up!`);
                            missingTranslationSetup = false;
                        }
                    }
                    console.log(`[i18n] missing ${lang} translation: ${key}`)
                }
            }
        },
        messages: {
            en: require("~/locales/en.json"),
            it: require("~/locales/it.json"),
            fr: require("~/locales/fr.json"),
            de: require("~/locales/de.json"),
            es: require("~/locales/es.json"),
            nl: require("~/locales/nl.json"),
            cs: require("~/locales/cs.json"),
        },
    });

    app.$t = app.i18n.t.bind(app.i18n);

    if (module.hot) {
        module.hot.accept(
            ["~/locales/en.json", "~/locales/it.json"],
            function() {
                app.i18n.setLocaleMessage("en", require("~/locales/en.json"));
                app.i18n.setLocaleMessage("it", require("~/locales/it.json"));
                app.i18n.setLocaleMessage("fr", require("~/locales/fr.json"));
                app.i18n.setLocaleMessage("de", require("~/locales/de.json"));
                app.i18n.setLocaleMessage("es", require("~/locales/es.json"));
                app.i18n.setLocaleMessage("nl", require("~/locales/nl.json"));
            }
        );
    }
};

export default i18n;
