<template>
    <!-- :light="!dark"
    :dark="dark"-->
    <v-app
        v-action.fullscreen="fullscreen"
        ref="app"
        :class="{ 'full-page-app': fullPage }"
        @touchstart.native="startMove($event)"
        @touchmove.native="canMove($event)"
        @touchend.native="endMove($event)"
        v-event.openDialog="openDialog"
        v-event.modalResult="modalResult"
        class="mainBg"
        :style="`${$vuetify.theme.dark ? 'background-color: #707070;' : 'background-color: #175566;'}`"
    >
        <v-snackbar top v-model="error">
            {{ errorMessage }}
            <v-btn text color="pink" @click.native="error = false" v-t="'basic.close'"></v-btn>
        </v-snackbar>

        <v-snackbar top v-model="success">
            {{ successMessage }}
            <v-btn text color="green" @click.native="success = false" v-t="'basic.close'"></v-btn>
        </v-snackbar>

        <v-dialog v-for="dialog in dialogs" :key="dialog.key" v-model="dialog.show" v-bind="dialog.modal">
            <template v-if="dialog.loading">
                <v-card>
                    <v-card-text style="text-align: center">
                        <v-progress-circular indeterminate color="blue"></v-progress-circular>
                    </v-card-text>
                </v-card>
            </template>
            <template v-else>
                <component :is="dialog.component" v-bind="dialog.props" :modalId="dialog.key" />
            </template>
        </v-dialog>

        <v-content :class="['gradientBg', { 'fill-height': fullPage }]">
            <v-container fluid style="height:100%;" class="gradientBg">
                <transition name="fade" mode="out-in">
                    <nuxt></nuxt>
                </transition>
            </v-container>
        </v-content>

        <v-fab-transition>
            <v-btn @click="exitFullscreen" v-if="$store.state.fullscreen" dark fab fixed bottom right>
                <v-icon>fullscreen_exit</v-icon>
            </v-btn>
        </v-fab-transition>
    </v-app>
</template>

<script>
import _ from "lodash";
import Vue from "vue";
import moment from "moment";
import { mapGetters } from "vuex";
import uuid from "uuid/v4";

export default {
    data() {
        return {
            drawer: null,
            mini: false,
            lang: false,
            dialogs: [],
            cLang: "EN",
        };
    },
    head() {
        return {
            htmlAttrs: {
                style: this.fullPage
                    ? `overflow: hidden; height: 100%; --top-height: ${this.$vuetify.breakpoint.mdAndUp ? 60 : 48}px;`
                    : `overflow: auto; --top-height: ${this.$vuetify.breakpoint.mdAndUp ? 60 : 48}px;`,
            },
            bodyAttrs: {
                style: (() => {
                    const string1 = this.fullPage ? `overflow: hidden; height: 100%; ` : "";
                    const string2 = string1 + (this.$vuetify.theme.dark ? "background-color: #707070;" : "background-color: #1a7e97;");
                    return string2;
                })(),
            },
        };
    },
    computed: {
        hideLang() {
            return this.$route.name === "help";
        },
        fullPage() {
            return this.$store.state.fullPage;
        },
        mfullscreen() {
            return this.$store.state.fullscreen;
        },
        languages() {
            return [
                {
                    href: "/dashboard",
                    title: "ENGLISH",
                    value: "EN",
                },
                {
                    href: "/dashboard",
                    title: "DEUTSCH",
                    value: "DE",
                },
                {
                    href: "/dashboard",
                    title: "Français",
                    value: "FR",
                },
                {
                    href: "/dashboard",
                    title: "Italiano",
                    value: "IT",
                },
                {
                    href: "/dashboard",
                    title: "español",
                    value: "ES",
                },
                {
                    href: "/dashboard",
                    title: "Nederland",
                    value: "NL",
                },
            ];
        },

        dark() {
            return this.$vuetify.theme.dark;
        },
        error: {
            get() {
                return !!this.$store.state.error;
            },
            set(v) {
                if (!v) {
                    this.$store.commit("SET_ERROR");
                }
            },
        },

        errorMessage() {
            return this.$store.state.error;
        },

        success: {
            get() {
                return !!this.$store.state.success;
            },
            set(v) {
                if (!v) {
                    this.$store.commit("SET_SUCCESS");
                }
            },
        },

        successMessage() {
            return this.$store.state.success;
        },

        ...mapGetters(["attributes", "role"]),
    },
    components: {},
    async beforeMount() {
        await this.$store.dispatch("initLang", {
            ctx: this,
        });
        this.$vuetify.theme.dark = false;
        // if(this.$messageQueue.supported) {
        //     this.$vuetify.theme.dark = await this.$messageQueue.ns('auth').call('getTheme');
        // } else {
        //     if (this.$store.getters.displaySetting.bgColor == 'Light') {
        //         this.$vuetify.theme.dark = false;
        //     } else {
        //         this.$vuetify.theme.dark = true;
        //     }
        // }

        var ua = window.navigator.userAgent;
        var msie = ua.indexOf("MSIE ");
        if (msie > 0) {
            // If Internet Explorer, return version number
            alert("Please use Chrome or Firefox instead");
        }
    },
    async mounted() {
        document.addEventListener("fullscreenchange", this.fullscreenChange);
        document.addEventListener("webkitFullscreenchange", this.fullscreenChange);
        document.addEventListener("gesturestart", this.gesture);

        this.updateFullPage();
    },
    beforeDestroy() {
        document.removeEventListener("fullscreenchange", this.fullscreenChange);
        document.removeEventListener("webkitFullscreenchange", this.fullscreenChange);
        document.removeEventListener("gesturestart", this.gesture);
    },
    methods: {
        setLang(lang) {
            this.cLang = lang.value;
        },
        gesture(e) {
            e.preventDefault();
        },
        fullscreen() {
            if (document.fullscreenElement) {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                    this.$store.commit("SET_FULLSCREEN", false);
                }
                return;
            }
            if (document.webkitFullscreenElement) {
                if (document.webkitFullscreenElement) {
                    document.webkitExitFullscreen();
                    this.$store.commit("SET_FULLSCREEN", false);
                }
            }
            if (!window.navigator.standalone) {
                const elem = this.$refs.app.$el;
                const rfq = elem.requestFullscreen || elem.webkitRequestFullscreen || elem.mozRequestFullScreen || elem.msRequestFullscreen;
                if (!rfq) return;
                rfq.call(elem);
            }
            this.$store.commit("SET_FULLSCREEN", true);
        },

        exitFullscreen() {
            if (document.fullscreenElement && document.exitFullscreen) document.exitFullscreen();
            if (document.webkitFullscreenElement && document.webkitExitFullscreen) document.webkitExitFullscreen();
            this.$store.commit("SET_FULLSCREEN", false);
        },

        fullscreenChange(event) {
            this.$store.commit("SET_FULLSCREEN", event.fullscreen);
        },

        startMove(event) {
            if (event.targetTouches.length === 1) {
                this._clientX = event.targetTouches[0].clientX;
                this._clientY = event.targetTouches[0].clientY;
            }
        },

        canMove(event) {
            if (this.$store.state.fullPage) {
                event.stopPropagation();
                if (event.targetTouches.length === 1) {
                    if (
                        this.hasScrollable(
                            event.target,
                            event.targetTouches[0].clientX - this._clientX,
                            event.targetTouches[0].clientY - this._clientY
                        )
                    ) {
                        this._scrolling = true;
                    } else if (!this._scrolling) {
                        event.preventDefault();
                    }
                }
            }
        },

        hasScrollable(target, clientX, clientY) {
            if (target.classList.contains("scrollable") || target.classList.contains("v-data-table__wrapper")) {
                if (target.scrollHeight > target.clientHeight && Math.abs(clientY) > Math.abs(clientX)) {
                    if ((target.scrollTop > 0 || clientY < 0) && (target.scrollHeight - target.scrollTop > target.clientHeight || clientY > 0)) {
                        return true;
                    }
                }
                if (target.scrollWidth > target.clientWidth && Math.abs(clientX) > Math.abs(clientY)) {
                    if ((target.scrollLeft > 0 || clientX < 0) && (target.scrollWidth - target.scrollLeft > target.clientWidth || clientX > 0)) {
                        return true;
                    }
                }
            }
            return target.parentElement && this.hasScrollable(target.parentElement, clientX, clientY);
        },

        endMove(event) {
            if (event.targetTouches.length === 0) {
                this._scrolling = false;
            }
        },

        updateFullPage() {
            if (process.server) return;
            const nuxt = document.getElementById("__nuxt");
            if (this.fullPage) nuxt.classList.add("fill-height");
            else nuxt.classList.remove("fill-height");

            const layout = document.getElementById("__layout");
            if (this.fullPage) layout.classList.add("fill-height");
            else layout.classList.remove("fill-height");
        },

        toggleDrawer() {
            if (this.drawer) {
                this.mini = !this.mini;
            } else {
                this.drawer = true;
                this.mini = false;
            }
        },

        openDialog(e) {
            let item;
            const hide = (result, err) => {
                if (!item._show) return;
                item._show = false;

                if (err) {
                    e.reject(err);
                } else {
                    e.resolve(result);
                }

                setTimeout(() => {
                    const idx = this.dialogs.indexOf(item);
                    idx !== -1 && this.dialogs.splice(idx, 1);
                }, 500);
            };
            item = {
                key: uuid(),
                props: e.props,
                loading: false,
                _show: true,
                get show() {
                    return this._show;
                },
                set show(v) {
                    if (!v) {
                        hide();
                    }
                },
                hide,
            };
            if (e.component.then) {
                item.loading = true;
                item.modal = { maxWidth: "100px", persistent: true };
                e.component
                    .then((c) => {
                        item.loading = false;
                        item.modal = e.modal;
                        item.component = c.default || c;
                    })
                    .catch((e) => {
                        console.warn(e);
                        hide(undefined, e);
                    });
            } else {
                item.component = e.component;
                item.modal = e.modal;
            }
            this.dialogs.push(item);
        },

        modalResult(e) {
            const item = this.dialogs.find((it) => it.key === e.id);
            if (item) {
                item.hide(e.result);
            }
        },
    },
    watch: {
        fullPage(val) {
            this.updateFullPage();
        },
        mfullscreen(val) {
            if (val) this.drawer = false;
        },
        drawer(val) {
            if (val && this.mfullscreen) {
                Vue.nextTick(() => {
                    this.drawer = false;
                });
            }
        },
        dark(val) {
            this.$vuetify.theme.dark = val;
            if (val) {
                this.mini = true;
            } else {
                this.mini = false;
            }
        },
    },
};
</script>

<style>
a.v-list-item--active.v-list-item.v-list-item--link + a.v-list-item.v-list-item--link {
    border-radius: 0 32px 0 0 !important;
}

.v-applicaiton {
    width: "100%";
}

.full-page-app {
    height: 100%;
}

.full-page-app .v-application--wrap {
    min-height: initial;
}

.v-card {
    border: none !important;
}

.v-application .display-4,
.v-application .display-3,
.v-application .display-2,
.v-application .display-1,
.v-application .headline,
.v-application .title,
.v-application .subtitle-1,
.v-application .subtitle-2,
.v-application .body-1,
.v-application .body-2,
.v-application .caption,
.v-application .overline {
    font-family: "Lato", sans-serif !important;
}

.fusioncharts-container > svg > g:nth-child(4) {
    display: none !important;
}

input {
    font-family: "Lato", sans-serif !important;
}
div {
    font-family: "Lato", sans-serif !important;
}
span {
    font-family: "Lato", sans-serif !important;
}
</style>

<style scoped>
.v-toolbar {
    transition: 0.5s all;
}

.v-list__tile__action {
    justify-content: center;
    min-width: 28px;
    margin-right: 16px;
}

.v-list__tile__content ~ .v-list__tile__action:not(.v-list__tile__action--stack) {
    margin-right: 0px;
    margin-left: 16px;
    justify-content: center;
}

.scroll-area {
    position: relative;
    margin: auto;
    width: 100%;
    height: 100%;
    flex: 1;
    flex-grow: 1;
}

.v-navigation-drawer >>> .v-navigation-drawer__content {
    padding-top: constant(safe-area-inset-top) !important;
    padding-top: env(safe-area-inset-top) !important;
}

.top_bar * {
    user-select: none;
}

.top_bar {
    padding-top: constant(safe-area-inset-top) !important;
    padding-top: env(safe-area-inset-top) !important;
    height: calc(constant(safe-area-inset-top) + var(--top-height)) !important;
    height: calc(env(safe-area-inset-top) + var(--top-height)) !important;

    background: linear-gradient(to bottom, #1183a1, #094554);
}

.top_bar >>> .v-toolbar__content {
    height: var(--top-height) !important;
    padding: 0px !important;
}

.v-content {
    padding-top: calc(constant(safe-area-inset-top) + var(--top-height)) !important;
    padding-top: calc(env(safe-area-inset-top) + var(--top-height)) !important;
}

.v-list {
    padding: 0px !important;
}

.menuText {
    letter-spacing: 1.5px;
}

.v-btn::before {
    opacity: 0 !important;
}

.v-list-item--active:focus::before {
    opacity: 0 !important;
}

.v-list-item:focus::before {
    opacity: 0 !important;
}

.v-list-item--active::before {
    background: #0fadcf;
}

.top_bar >>> .theme--light.v-btn {
    color: transparent !important;
}

.gradientBg {
    background: linear-gradient(180deg, #1a7e97 0%, #1e6d81 49.48%, #21616d 100%);
}
</style>
