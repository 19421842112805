import { Component, Prop, Watch, Vue, mixins } from "nuxt-property-decorator";
import _, { add } from 'lodash'
import type {
    WeatherManager,
    WeatherDevice,
    CurrentDataType,
} from './index'
import {
    MetricInstance
} from './metric'
import {
    DisplaySetting,
    arrayToObj,
    msgpack,
    getOptions,
    graphFilters,
} from './utils'
import {
    MetricValue
} from './value'
import { deviceTypeDict, MetricOpts, MetricKey, metricDict } from './deviceTypes'

import moment from 'moment-timezone'
import { GraphMixin } from "./deviceGraph";
import { DeviceBase } from "./deviceBase";
import { AlertMixin } from "./deviceAlert";
import { ForecastInstance } from "./forecast";
import CardBase from "~/components/weather/CardBase";

moment.relativeTimeThreshold('s', 60)
moment.relativeTimeThreshold('ss', 1);
moment.relativeTimeThreshold('m', 60)

@Component
export class WeatherDeviceManager extends mixins(DeviceBase, AlertMixin, GraphMixin) {
    id : string = null;

    order = -1;
    parent : WeatherManager;
    device : WeatherDevice = null;
    plan: any = null
    displaySetting : DisplaySetting = null;
    metricsList: MetricInstance[] = [];
    prepared = false;
    metricInstances : {
        [key : string]: MetricInstance
    } = {};
    metricValues : {
        [key : string]: MetricValue
    } = {};
    updateTime = 0;
    updateText = '';

    get owned() {
        return this.device.user === this.$store.getters.userId;
    }

    get shared() {
        return _.indexOf(this.device.shareUsers, this.$store.getters.userId) !== -1
    }

    get public() {
        return !this.owned && !this.shared;
    }

    get sortGroup() {
        return this.owned ? 0 : this.shared ? 1 : 2;
    }

    @Watch('updateTime')
    updateLastUpdate() {
        this.updateText = this.updateTime ? moment(this.updateTime * 1000).fromNow() : '-'
    }

    loading = false;

    // non reactive
    dailyData : CurrentDataType[];
    dailyFrom : number = Number.MAX_SAFE_INTEGER;
    minuteData : CurrentDataType[];
    // minuteFrom : number = Number.MAX_SAFE_INTEGER;
    minuteFrom = Number.MAX_SAFE_INTEGER;
    // get minuteFrom() { return this.mminuteFrom }
    // set minuteFrom(v) { this.mminuteFrom = v; debugger}

    currentData : CurrentDataType = null;
    lastDatas: CurrentDataType[] = [];
    accumMin : CurrentDataType;
    accumDay : CurrentDataType;
    historyLoaded = false;

    get timezone() {
        return (this.device?.timezone ?? 'Asia/Hong_Kong')
    }

    unixUtcStart : number
    unixUtcStartEnd : number
    unixLocalStart : number
    unixLocalStartEnd : number
    getUnixUtcToday() {
        if(!this.unixUtcStartEnd || Date.now() > this.unixUtcStartEnd) {
            const m = moment.utc().startOf('day');
            this.unixUtcStart = m.unix();
            this.unixUtcStartEnd = m.endOf('day').toDate().getTime();
        }
        return this.unixUtcStart;
    }

    getUnixLocalToday() {
        if(!this.unixLocalStartEnd || Date.now() > this.unixLocalStartEnd) {
            const m = moment.tz(this.timezone).startOf('day');
            this.unixLocalStart = m.unix();
            this.unixLocalStartEnd = m.endOf('day').toDate().getTime();
        }
        return this.unixLocalStart;
    }

    unixMinuteStart : number;
    unixMinuteStartEnd : number;
    getUnixMinute() {
        if(!this.unixMinuteStartEnd || Date.now() > this.unixMinuteStartEnd) {
            let m = moment().startOf('minute');
            m = m.subtract((m.minute() % 5), 'minute');
            this.unixMinuteStart = m.unix();
            this.unixMinuteStartEnd = m.endOf('minute').add(4, 'minute').toDate().getTime();
        }
        return this.unixMinuteStart;
    }

    metric(key : string, name? : string) {
        let metric = this.metricValues[key];
        if(!metric) {
            const def = this.parent.metrics.find(it => it.key === key);
            if(!def) {
                console.warn("Metric", key, "not found");
                return null;
            }
            metric = new MetricValue(getOptions(this.$options));
            metric.init(this, def);
            if(name) metric.nameKey = name;
            this.metricValues[key] = metric;
        }
        if(name && !metric.nameKey) metric.nameKey = name;
        return metric;
    }

    get name() {
        return this.device?.name;
    }

    get deviceTypeId() {
        return this.device?.consoletype ?? 0;
    }

    get deviceTypeInfo() {
        const deviceInfo = deviceTypeDict[this.deviceTypeId];
        if(!deviceInfo) {
            console.warn("Unknown device type", this.deviceTypeId)
        }
        return deviceInfo ?? deviceTypeDict[0];
    }

    get channelInfo() : MetricOpts {
        return <MetricOpts>this.deviceTypeInfo.metrics?.find(it => typeof it === 'object' && it.key === 'channel');
    }

    get availableMetrics() {
        const info = this.deviceTypeInfo;
        const metrics : MetricInstance[] = [];
        for(let metric of info.metrics) {
            let info = typeof metric === 'string' ? {
                key: <MetricKey>metric,
            } : metric;
            for(let i = 1; i <= (info.count ?? 1); i++) {
                const item = metricDict[info.key];
                if(!item) {
                    console.warn("Cannot find metric", info.key);
                }
                const key = info.count ? item.key + i : item.key;
                let instance = this.metricInstances[key];
                if(!instance) {
                    instance = new MetricInstance(getOptions(this.$options));
                    this.metricInstances[key] = instance;
                    instance.init(<any>item, key, i, info);
                }
                metrics.push(instance);
            }
        }
        return metrics;
    }

    get selectedDict() {
        return _.fromPairs(this.metricsList.map(it => [it.key, it]))
    }

    get metricDict() {
        return _.fromPairs(this.availableMetrics.map(it => [it.key, it]))
    }

    async queryLatest() {
        this.historyLoaded = false;
        await this.$feathers.service('weatherDevices/lastData').find({
            query: {
                device: this.id,
                range: true,
            }
        })
        await this.updateAlerts();
    }

    async preloadData(type : '5m' | '1d') {
        const db = this.parent.db;
        let from : number;
        let fromData : CurrentDataType;
        {
            const tx = db.transaction(type === '5m' ? 'metrics' : 'metricsDaily', 'readonly');
            const idx = tx.store.index("byDeviceTime")
    
            const cursor = await idx.openKeyCursor(IDBKeyRange.bound([this.id, 0], [this.id, Number.MAX_SAFE_INTEGER]), 'prev');
            await tx.done;
            from = cursor?.key?.[1] ?? 0;
            if(type === '5m' && cursor?.primaryKey) {
                fromData = await db.get("metrics", cursor?.primaryKey);
            }
        }
        let fromDate = moment.unix(from);
        console.log(type, fromDate.toDate());

        if(type === '5m') {
            if(fromDate.isBefore(moment().subtract(2, 'days'))) {
                fromDate = moment().subtract(2, 'days');
            }
            fromDate = fromDate.startOf('minute');
            fromDate = fromDate.subtract((fromDate.minute() % 5), 'minute');
        } else {
            if(fromDate.isBefore(moment().utc().subtract(366, 'days'))) {
                fromDate = moment().utc().subtract(366, 'days');
            }
            fromDate = fromDate.utc().startOf('day');
        }

        const toDate = type === '5m' ? moment.unix(this.getUnixMinute() - 1).toDate() : moment.utc().startOf('day').toDate();

        console.log('Query', type,' from date', fromDate.toString());
        const data = msgpack.decode(await this.$feathers.service('weatherMetrics/query').find({
            query: {
                device: this.id,
                from: fromDate.toDate(),
                to: toDate,
                type: type
            }
        }));

        const convert = arrayToObj(data[0], this.id);
        const allData : CurrentDataType[] = data[1].map(convert);

        // for current 5min accum data, don't store
        let lastData : CurrentDataType = type === '5m' && allData[allData.length - 1];
        if(lastData) {
            if(this.getUnixMinute() === lastData.time) {
                allData.pop();
            } else lastData = null;
        }

        {
            const lastItem = allData[allData.length - 1];
            if(lastItem) {
                from = lastItem.time;
            }
            const fromUnix = moment(fromDate).unix();
            const toUnix = moment(toDate).unix();
            
            const tx = db.transaction(type === '5m' ? 'metrics' : 'metricsDaily', 'readwrite');
            await Promise.all([
                ...allData.filter(it => it.time > fromUnix && it.time < toUnix).map(item => <any>tx.store.add(item)),
                tx.done,
            ])
            let dirty = false;
            for(let i = allData.length - 1; i >= 0; i--) {
                const it = allData[i];
                if(this.insertHistory(type, it)) {
                    dirty = true;
                }
            }
            if(dirty) this.$emit("accumReset", type);
        }

        if(type === '5m' && from && !this.updateTime) {
            this.updateTime = from;
            _.forEach(lastData || fromData, (v, k) => {
                this.currentData[k] = v;
            });
        }
        if(lastData && !this.accumDay) {
            if(this.getUnixMinute() === lastData.time) {
                this.accumDay = this.getEmptyData(lastData);
            }
        }
    }

    preloadDBPromise : { [key: string] : Promise<void> };
    preloadDBData(type : '5m' | '1d') {
        if(!this.preloadDBPromise) this.preloadDBPromise = {};
        return this.preloadDBPromise[type] || (this.preloadDBPromise[type] = this.preloadDBDataCore(type));
    }

    async preloadDBDataCore(type: '5m' | '1d') {
        const fromKey = type === '5m' ? 'minuteFrom' : 'dailyFrom'
        const loadFrom = type === '5m' ? this.getMinMinuteLoad() : this.getMinDailyLoad();
        const loadTo = this[fromKey]
        console.log('preload', type, moment.unix(loadFrom).format(), moment.unix(loadTo).format());
        const allData = await this.parent.db.getAllFromIndex(type === '5m' ? 'metrics' : 'metricsDaily', 'byDeviceTime', IDBKeyRange.bound([this.id, loadFrom], [this.id, loadTo], true, false));

        let dirty = false;
        for(let i = allData.length - 1; i >= 0; i--) {
            const it = allData[i];
            if(this.insertHistory(type, it)) {
                dirty = true;
            }
        }
        if(!allData.length && loadTo === Number.MAX_SAFE_INTEGER) {
            let m = moment().startOf('minute');
            m = m.subtract((m.minute() % 5), 'minute');
            this[fromKey] = type === '5m' ? m.unix() : moment().startOf('day').unix();
            dirty = true;
        }
        this[fromKey] = loadFrom;
        console.log('preload done', type, dirty);
        if(dirty) this.$emit("accumReset", type);
    }

    insertHistory(type: '5m' | '1d', item : CurrentDataType) {
        const curFromKey = type === '5m' ? 'minuteFrom' : 'dailyFrom';
        const curDataKey = type === '5m' ? 'minuteData' : 'dailyData';

        const curFrom = this[curFromKey];
        let curData = this[curDataKey];
        if(!curData) curData = this[curDataKey] = [];
        const insertIndex = _.sortedIndexBy(curData, item, it => it.time);
        const curDataItem = curData[insertIndex];
        if(curDataItem && curDataItem.time === item.time) {
            console.warn("Skip duplicated item", curDataItem, item);
            // return false;
            return true;
        }

        if(item.time < curFrom) {
            this[curFromKey] = item.time;
        }

        curData.splice(insertIndex, 0, item);
        if(type === '5m' && item.time >= this.getUnixUtcToday()) {
            if(!this.accumDay || this.accumDay.time !== this.getUnixUtcToday()) {
                if(this.accumDay) {
                    this.appendDayData(Object.freeze({...this.accumDay}))
                }
                this.accumDay = this.getAccumData(this.getUnixUtcToday());
            }
            if(this.appendAccumData(this.accumDay, item, true)) {
                // accum will reset, so no need to update indvidual data
                // this.$emit('updateAccumDay', this.accumDay);
            }
        }
        return true;
    }

    getMinMinuteLoad() {
        return this.getUnixMinute() - 3600 * 48;
    }

    getMinDailyLoad() {
        return this.getUnixUtcToday() - 3600 * 366 * 24;
    }

    ensureHistory(from : number, to : number) {
        if(this.loading) return;
        const minMinuteLoad = this.getMinMinuteLoad();
        const minDailyLoad = this.getMinDailyLoad();
        let valid = true;
        const minuteCovered = from >= minMinuteLoad;
        if(to >= minMinuteLoad && Math.max(minMinuteLoad, from) < this.minuteFrom) {
            // need to load minute
            valid = false;
            console.log('5m', moment.unix(from).format(), moment.unix(to).format(), moment.unix(minMinuteLoad).format(), moment.unix(this.minuteFrom).format());
            this.preloadDBData('5m')
        }
        if(!minuteCovered && to >= minDailyLoad && from < this.dailyFrom) {
            // need to load daily
            valid = false;
            console.log('1d', moment.unix(from).format(), moment.unix(to).format(), moment.unix(minDailyLoad).format(), moment.unix(this.dailyFrom).format());
            this.preloadDBData('1d')
        }
        return valid;
    }

    async preloadLatest() {
        try {
            this.loading = true;
            await Promise.all([
                this.queryLatest(),
                Promise.race([
                    setTimeout((resolve) => setTimeout(resolve, 1000)),
                    new Promise((resolve) => this.$once('metrics', resolve)),
                ])
            ])
        } finally {
            this.loading = false;
        }
    }

    async preload() {
        try {
            this.loading = true;
            await Promise.all([
                this.queryLatest(),
                this.preloadData('5m'),
                this.preloadData('1d'),
                Promise.race([
                    setTimeout((resolve) => setTimeout(resolve, 1000)),
                    new Promise((resolve) => this.$once('metrics', resolve)),
                ]),
                this.startRefresh(),
            ])
        } finally {
            this.loading = false;
        }
        this.$emit("accumReset", '5m');
        this.$emit("accumReset", '1d');
    }

    async prepare() {
        if(this.prepared) return;
        this.displaySetting = (<any> await this.$feathers.service("deviceDisplaySettings").find({
            query: {
                weatherDevice: this.id,
                $limit: 1,
                $paginate: false
            }
        }))[0] || {
            metricsList: this.availableMetrics.filter(it => !it.defaultHidden).map(it => it.key),
            detailList: ['pmChannel1','pmChannel2','pmChannel3','pmChannel4'],
            graphList: [],
        };
        this.updateDisplay(this.displaySetting);
        this.prepared = true;
    }

    setup(parent : WeatherManager, device : WeatherDevice) {
        this.device = device;
        this.parent = parent;
        this.id = device._id;
        this.currentData = this.getEmptyData();
        this.updatePlan();
    }

    async updatePlan() {
        if (this.device.mac) {
            this.plan = (await this.$feathers.service('deviceSubscriptionPlan').find({
                query: {
                    mac: this.device.mac,
                    $populate: ['subscriptionPlan'],
                    $limit: 1,
                },
                paginate: false
            }))[0] ?? null;
        }
    }

    getEmptyData(prefill? : CurrentDataType, time? : number) : CurrentDataType {
        const item = {
            time: 0,
            device: this.id,
            ..._.fromPairs(_.map(this.parent.metrics.filter(it => it.type !== 'str'), m => [m.key, null])),
            ...(prefill || {}),
        }
        if(time) item.time = time;
        return item;
    }

    getAccumData(time = 0) : CurrentDataType {
        return {
            time: time,
            device: this.id,
            accumTime: null,
            ..._.fromPairs(
                _.flatMap(this.parent.metrics.filter(it => it.type !== 'str'), m => [
                    ['last_' + m.key, null],
                    ['sum_' + m.key, 0],
                    ['count_' + m.key, 0],
                    ['min_' + m.key, null],
                    ['max_' + m.key, null],
                    ['mean_' + m.key, null],
                ])
            )
        }
    }

    get appendAccumData() : (accum : CurrentDataType, item : CurrentDataType, force? : boolean) => boolean {
        return <any>new Function("accum", "item", "force", `
            if(accum.accumTime && item.time <= accum.accumTime) {
                if(!force) return false;
            } else {
                accum.accumTime = item.time;
            }
            ${
                _.map(this.parent.metrics, m => `
                    const cur_${m.key} = item["${m.key}"];
                    if(typeof cur_${m.key} === 'number') {
                        accum["last_${m.key}"] = cur_${m.key};
                        accum["sum_${m.key}"] += cur_${m.key};
                        accum["count_${m.key}"]++;
                        accum["mean_${m.key}"] = accum["sum_${m.key}"] / accum["count_${m.key}"];
                        if(accum["min_${m.key}"] === null || cur_${m.key} < accum["min_${m.key}"]) {
                            accum["min_${m.key}"] = cur_${m.key};
                        }
                        if(accum["max_${m.key}"] === null || cur_${m.key} > accum["max_${m.key}"]) {
                            accum["max_${m.key}"] = cur_${m.key};
                        }
                    }
                `).join('\n')
            }
            return true;
        `);
    }

    update(data : WeatherDevice) {
        if(!this.device) this.device = data;
        else {
            _.forEach(data, (v, k) => {
                Vue.set(this.device, k, v);
            });
        }
    }

    async updateDisplay(data : DisplaySetting) {
        if(!data) return;
        if(!this.displaySetting) {
            this.displaySetting = data;
        } else {
            _.forEach(data, (v, k) => {
                Vue.set(this.displaySetting, k, v);
            });
        }
        this.metricsList = this.displaySetting.metricsList.map(it => this.metricDict[it]).filter(it => !!it);
        this.availableMetrics.forEach(it => {
            it.showDetail = _.indexOf(this.displaySetting.detailList, it.key) !== -1;
        })
        let added = false;
        for(let graph of (this.displaySetting.graphList || []).slice().reverse()) {
            const current = this.graphs.find(it => it.metricKey === graph);
            if(!current) {
                const metric = this.availableMetrics.find(it => it.key === graph);
                if(metric) {
                    const name = metric.component.replace(/^weather/, '').replace(/-(.)/g, (m, c) => c.toUpperCase());
                    const context = require.context("~/components/weather", true, /\.vue$/, 'lazy');
                    const com = await context(`./${name}.vue`);
                    const c = new com.default({
                        propsData: {
                            device: this,
                            metric: metric,
                            ...metric.props,
                        },
                        parent: this,
                    }) as CardBase;

                    if(c.graphOptions) {
                        this.addGraph(c.graphOptions, metric.key);
                        added = true;
                    }
                    c.$destroy();
                }
            }
        }
        for(let graph of this.graphs.slice()) {
            const cur = (this.displaySetting.graphList || [])?.find?.(it => it === graph.metricKey);
            if(cur) continue;
            this.removeGraph(graph.key);
        }
        if(this.displaySetting.graphRange && this.displaySetting.graphRange !== this.graphFilter?.key) {
            const filter = graphFilters.find(it => it.key == this.displaySetting.graphRange);
            if(filter) {
                this.graphFilter = filter;
            }
        }
        if(added) {
            await this.startRefresh();
        }
    }

    async uploadDisplay() {
        if(this.displaySetting && this.displaySetting._id) {
            this.displaySetting = ( <any> await this.$feathers.service("deviceDisplaySettings").patch(this.displaySetting._id, {
                metricsList: this.metricsList.map(it => it.key),
                detailList: this.availableMetrics.filter(it => it.showDetail).map(it => it.key),
                graphList: this.graphs.map(g => g.metricKey),
                graphRange: this.graphFilter?.key || this.displaySetting?.graphRange,
            }))
        } else {
            this.displaySetting = ( <any> await this.$feathers.service("deviceDisplaySettings").create({
                metricsList: this.metricsList.map(it => it.key),
                weatherDevice: this.id,
                detailList: this.availableMetrics.filter(it => it.showDetail).map(it => it.key),
                graphList: this.graphs.map(g => g.metricKey),
                graphRange: this.graphFilter?.key || this.displaySetting?.graphRange,
            }))
        }
    }

    async toggleMetric(key : string, to? : boolean) {
        const cur = !!this.selectedDict[key];
        const item = this.metricDict[key];
        to = to ?? !cur;
        if(cur === to) return;
        if(to) {
            this.metricsList.push(item);
        } else {
            const idx = this.metricsList.indexOf(item);
            idx !== -1 && this.metricsList.splice(idx, 1);
        }
        await this.uploadDisplay();
    }

    appendDayData(item : CurrentDataType) {
        if(!this.dailyData) this.dailyData = [];
        this.dailyData.push(item);
        if(item.time < this.dailyFrom) this.dailyFrom = item.time;
        this.$emit('appendAccumDay', item);
    }

    appendMinuteData(item : CurrentDataType) {
        if(!this.minuteData) this.minuteData = [];
        this.minuteData.push(item);
        if(item.time < this.minuteFrom) this.minuteFrom = item.time;
        this.$emit('appendAccumMin', item);

        const startDay = this.getUnixUtcToday();
        if(!this.accumDay || this.accumDay.time !== startDay) {
            if(this.accumDay) {
                this.appendDayData(Object.freeze({...this.accumDay}))
            }
            this.accumDay = this.getAccumData(startDay);
            this.appendAccumData(this.accumDay, item);
            this.$emit('updateAccumDay', this.accumDay);
        }
        else if(this.appendAccumData(this.accumDay, item)) {
            this.$emit('updateAccumDay', this.accumDay);
        }
    }

    updateMetrics(data : any) {
        this.updateTime = data.time;
        this.$emit('metrics', data);
        this.lastDatas.push(Object.freeze(data));
        const before5m = this.getUnixMinute() - 300;

        while(this.lastDatas.length && this.lastDatas[0].time < before5m) {
            this.lastDatas.shift();
        }
        if(this.updateTime > moment().subtract(1, 'day').unix()) {
            _.forEach(data, (v, k) => {
                this.currentData[k] = v;
            });
        }
        if(data.time >= this.getUnixUtcToday()) {
            const minTime =  ((data.time / 300) | 0) * 300;
            if(!this.accumMin || this.accumMin.time !== ((data.time / 300) | 0) * 300) {
                if(this.accumMin) {
                    this.appendMinuteData(Object.freeze({...this.accumMin}))
                }
                this.accumMin = this.getEmptyData(data, minTime);
            } else {
                _.forEach(data, (v, k) => {
                    if(typeof v === 'number' && k !== 'time') {
                        this.accumMin[k] = v;
                    }
                });
            }
            this.$emit('updateAccumMin', this.accumMin);
        }
    }

    updateMetricsHistory(data : any) {
        if(this.historyLoaded) return;
        const convert = arrayToObj(data.metrics, this.id);
        data.items = data.items.map(it => convert(it));
        this.lastDatas.push(...data.items.slice(0, -1).map(data => Object.freeze(data)));
        if(data.items.length) {
            this.historyLoaded = true;
            this.updateMetrics(data.items[data.items.length - 1]);
        }
    }

    getChannelName(key : string) {
        return this.device?.channelNameMapping?.find(it => it.channel === key)?.name || '---';
    }

    async setChannelName(key : string, name : string) {
        try {
            var channelNameMapping = this.device.channelNameMapping?.filter(it => it.channel !== key) || [];
            channelNameMapping.push({
                channel: key,
                name: name,
            })
            const device = await this.$feathers.service("weatherDevices").patch(this.id, {
                channelNameMapping: channelNameMapping
            });
            this.device = <any>device;
        } catch (e) {
            console.log((e as Error).message);
        }
    }

    async setDeviceData(data : Partial<WeatherDevice>) {
        const device = await this.$feathers.service("weatherDevices").patch(this.id, data);
        this.device = <any>device;
    }

    forecasts: {
        [key: string]: ForecastInstance
    } = {};

    forecast(type : string) {
        let forecast = this.forecasts[type];
        if(!forecast) {
            this.forecasts[type] = forecast = new ForecastInstance(getOptions(this.$options));
            forecast.init(this, type);
        }
        return forecast;
    }

    updateForecast(data : any) {
        const forecast = this.forecasts[data.type];
        if(!forecast) return;
        forecast.update(data);
    }

    reloadForecast() {
        _.forEach(this.forecasts, f => f.loadData());
    }

}
