var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c("v-card-title", [_vm._v(_vm._s(_vm.label))]),
      _vm._v(" "),
      _c(
        "v-card-text",
        _vm._l(_vm.inputValue, function(entry, idx) {
          return _c(
            "v-layout",
            {
              key: idx,
              attrs: {
                row: "",
                "align-content-center": "",
                "justify-center": "",
                "align-center": ""
              }
            },
            [
              _c(
                "div",
                { staticStyle: { "flex-grow": "1" } },
                [_vm._t("item", null, { item: entry })],
                2
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      _vm.inputValue = _vm.inputValue
                        .slice(0, idx)
                        .concat(_vm.inputValue.slice(idx + 1))
                    }
                  }
                },
                [_c("v-icon", [_vm._v("delete")])],
                1
              )
            ],
            1
          )
        }),
        1
      ),
      _vm._v(" "),
      !_vm.noAdd
        ? _c(
            "v-btn",
            {
              attrs: { icon: "" },
              on: {
                click: function($event) {
                  _vm.inputValue
                    ? (_vm.inputValue = _vm.inputValue.concat([
                        _vm.getDefault()
                      ]))
                    : (_vm.inputValue = [])
                }
              }
            },
            [_c("v-icon", [_vm._v("add")])],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }