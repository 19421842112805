import axios from "axios";
import { GoogleMapResult, LatLng, LocationPrediction } from "./utils";
export type { GoogleMapResult, LatLng, LocationPrediction };

declare var GOOGLE_MAPS_API_KEY: string;

// detect location from browser
export async function geolocate(): Promise<LatLng> {
    return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition((pos) => {
            try {
                resolve({ lat: pos.coords.latitude, lng: pos.coords.longitude });
            } catch (error) {
                reject(error);
            }
        });
    });
}

// get locations details by latitude and longitude
export async function getLocationByLatLng(lat: number | string, lng: number | string, lang?: string | any) {
    try {
        let locale = lang || 'en';
        
        var data = (await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&language=${locale}&key=${GOOGLE_MAPS_API_KEY}`)).data;
        if (data.error_message) {
            console.log('[Google Maps]', data.error_message);
        } else {
            return data.results as GoogleMapResult[];
        }
    } catch (error) {
        console.log('[Google Maps]',error);
    }
}

export async function getLocationByPlaceId(placeId: string, lang?: string | any) {
    try {
        if (!placeId) return;
        
        let locale = lang || 'en';
        var data = (await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?place_id=${placeId}&language=${locale}&key=${GOOGLE_MAPS_API_KEY}`)).data;
        if (data.results) {
           return (data.results as GoogleMapResult[])?.[0]
       }
       
    } catch (error) {
        console.log(error);
    }
}

async function getLocationData(text: string) {
    if (!text || !text.length) return;
    try {
        const url = `https://api.geoapify.com/v1/geocode/autocomplete?format=json&apiKey=e41cd5f86ae9445db4c60e327a28a378&text=${text}`;
        const response = await axios({url: url, method: 'GET', headers: {'Content-Type': 'application/json'}})
        return response;
    } catch (error) {
        console.log(error);
    }
}

export async function searchLocation(text: string): Promise<LocationPrediction[]> {
    if (!text || !text.length) return;
    const res = await getLocationData(text);
    if(res.status === 200){
        const results = res.data.results;
        if(Array.isArray(results)){
            return results;
        }
    }
    return [];
}
