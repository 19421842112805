

import _ from 'lodash'
import { VForm } from 'vuetify/lib'

export default {
    data() {
        return {
            valid: true
        }
    },
    components: {
        VForm
    },
    render(_c) {
        const items = this._t('default', [], {
            valid: this.valid,
        }) || []
        _.each(items, (item) => this.processItem(item));
        return _c('v-form', {
            model: {
                value: !this.valid,
                callback: ($$v) => {
                    this.valid = $$v;
                },
            },
            ref: 'form'
        }, items);
    },
    methods: {
        processItem(item) {
            if(item.componentOptions) {
                const l = item.componentOptions && item.componentOptions.listeners;

                if(l.click) {
                    const c = l.click;
                    const validate = item.key === 'submit';
                    l.click = async ($event) => {
                        try {
                            if(!validate || validate && this.$refs.form && this.$refs.form.validate()) {
                                c($event);
                            }
                        } catch(e) {
                            this.$store.commit('SET_ERROR', e.message);
                        }
                    }
                }
                _.each(item.componentOptions.children, (child) => this.processItem(child));
            }
        }
    }
}
