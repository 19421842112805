var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    {
      directives: [
        {
          name: "action",
          rawName: "v-action.fullscreen",
          value: _vm.fullscreen,
          expression: "fullscreen",
          modifiers: { fullscreen: true }
        },
        {
          name: "event",
          rawName: "v-event.openDialog",
          value: _vm.openDialog,
          expression: "openDialog",
          modifiers: { openDialog: true }
        },
        {
          name: "event",
          rawName: "v-event.modalResult",
          value: _vm.modalResult,
          expression: "modalResult",
          modifiers: { modalResult: true }
        }
      ],
      ref: "app",
      class: [
        "white",
        { blurView: _vm.showDialog, "full-page-app": _vm.fullPage }
      ],
      style:
        "" +
        (_vm.$vuetify.theme.dark
          ? "background-color: #707070 !important;"
          : "background-color: #fff !important;"),
      nativeOn: {
        touchstart: function($event) {
          return _vm.startMove($event)
        },
        touchmove: function($event) {
          return _vm.canMove($event)
        },
        touchend: function($event) {
          return _vm.endMove($event)
        }
      }
    },
    [
      _c(
        "v-snackbar",
        {
          attrs: { top: "", color: "white" },
          model: {
            value: _vm.error,
            callback: function($$v) {
              _vm.error = $$v
            },
            expression: "error"
          }
        },
        [
          _c(
            "v-row",
            {
              staticClass: "pt-4 px-4",
              attrs: { "no-gutters": "", justify: "center" }
            },
            [
              _c(
                "v-col",
                { attrs: { "align-self": "center", align: "center" } },
                [
                  _c(
                    "div",
                    { staticClass: "greyText--text subtitle-2 text-center" },
                    [_vm._v(_vm._s(_vm.errorMessage))]
                  ),
                  _vm._v(" "),
                  _c("v-btn", {
                    directives: [
                      {
                        name: "t",
                        rawName: "v-t",
                        value: "basic.close",
                        expression: "'basic.close'"
                      }
                    ],
                    staticClass: "mt-2 ma-0",
                    attrs: { text: "", color: "red lighten-2" },
                    nativeOn: {
                      click: function($event) {
                        _vm.error = false
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: { top: "", color: "white" },
          model: {
            value: _vm.success,
            callback: function($$v) {
              _vm.success = $$v
            },
            expression: "success"
          }
        },
        [
          _c(
            "v-row",
            {
              staticClass: "pt-4 px-4",
              attrs: { "no-gutters": "", justify: "center" }
            },
            [
              _c(
                "v-col",
                { attrs: { "align-self": "center", align: "center" } },
                [
                  _c(
                    "div",
                    { staticClass: "greyText--text subtitle-2 text-center" },
                    [_vm._v(_vm._s(_vm.successMessage))]
                  ),
                  _vm._v(" "),
                  _c("v-btn", {
                    directives: [
                      {
                        name: "t",
                        rawName: "v-t",
                        value: "basic.close",
                        expression: "'basic.close'"
                      }
                    ],
                    staticClass: "mt-2 ma-0",
                    attrs: { text: "", color: "green" },
                    nativeOn: {
                      click: function($event) {
                        _vm.success = false
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm._l(_vm.dialogs, function(dialog) {
        return _c(
          "v-dialog",
          _vm._b(
            {
              key: dialog.key,
              model: {
                value: dialog.show,
                callback: function($$v) {
                  _vm.$set(dialog, "show", $$v)
                },
                expression: "dialog.show"
              }
            },
            "v-dialog",
            dialog.modal,
            false
          ),
          [
            dialog.loading
              ? [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-text",
                        { staticStyle: { "text-align": "center" } },
                        [
                          _c("v-progress-circular", {
                            attrs: { indeterminate: "", color: "blue" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              : [
                  _c(
                    dialog.component,
                    _vm._b(
                      { tag: "component", attrs: { modalId: dialog.key } },
                      "component",
                      dialog.props,
                      false
                    )
                  )
                ]
          ],
          2
        )
      }),
      _vm._v(" "),
      _c(
        "v-navigation-drawer",
        {
          style:
            "padding: 0px !important; background-color: var(--v-greyInactive-base);}",
          attrs: { dark: "", height: "100%", width: 200, app: "", right: "" },
          model: {
            value: _vm.drawer,
            callback: function($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer"
          }
        },
        [
          _c(
            "v-layout",
            {
              staticClass: "greyInactive",
              attrs: { column: "", "fill-height": "" }
            },
            [
              _c(
                "v-list",
                _vm._l(_vm.items, function(item, index) {
                  return _c(
                    "v-list-item",
                    {
                      key: index,
                      class: {
                        bottomBorder2: index === 6,
                        aboutBorder: index === 0
                      },
                      staticStyle: { "min-height": "40px" },
                      attrs: {
                        to: item.href,
                        target: item.target ? item.target : ""
                      }
                    },
                    [
                      _c(
                        "v-list-item-title",
                        { staticClass: "menuText greyText--text pb-1" },
                        [_vm._v(_vm._s(item.title))]
                      )
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      !_vm.$store.state.fullscreen
        ? _c(
            "v-app-bar",
            {
              staticClass: "top_bar",
              attrs: {
                height: _vm.$vuetify.breakpoint.mdAndUp ? 52 : 40,
                fixed: "",
                app: ""
              }
            },
            [
              _c(
                "v-row",
                {
                  staticClass: "fill-height",
                  attrs: { "no-gutters": "", align: "center", justify: "end" }
                },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "fill-height",
                      attrs: { cols: "11", sm: "10", md: "8" }
                    },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "fill-height",
                          staticStyle: { position: "relative" },
                          attrs: {
                            "no-gutters": "",
                            align: "center",
                            height: _vm.$vuetify.breakpoint.mdAndUp ? 52 : 40
                          }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "px-0",
                              staticStyle: {
                                position: "absolute",
                                left: "0px",
                                top: "0px"
                              },
                              attrs: {
                                text: "",
                                depressed: "",
                                ripple: false,
                                to: "/dashboard"
                              }
                            },
                            [
                              _c("img", {
                                staticStyle: {
                                  "vertical-align": "center",
                                  margin: "auto"
                                },
                                attrs: {
                                  src: require("assets/images/horizontal-logo.png"),
                                  height: _vm.$vuetify.breakpoint.mdAndUp
                                    ? "36px"
                                    : "20px"
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c("v-col", { attrs: { cols: "auto" } }, [
                            _c("div", {
                              staticStyle: { opacity: "0", width: "64px" }
                            })
                          ]),
                          _vm._v(" "),
                          _vm.$vuetify.breakpoint.lgAndUp
                            ? _c(
                                "v-col",
                                {
                                  staticStyle: {
                                    position: "absolute",
                                    left: "50%",
                                    top: "50%",
                                    transform: "translate(-50%,-50%)",
                                    "pointer-events": "none"
                                  },
                                  attrs: {
                                    "align-self": "center",
                                    align: "center"
                                  }
                                },
                                [
                                  _vm.$vuetify.breakpoint.smAndUp
                                    ? _c(
                                        "v-toolbar-title",
                                        {
                                          class: [
                                            "white--text center--text font-weight-bold",
                                            {
                                              headline:
                                                _vm.$vuetify.breakpoint.mdAndUp,
                                              "subtitle-1":
                                                _vm.$vuetify.breakpoint.smOnly,
                                              "subtitle-2":
                                                _vm.$vuetify.breakpoint.xsOnly
                                            }
                                          ]
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.testBanner ? "[TEST]" : ""
                                            ) +
                                              _vm._s(
                                                _vm.$td(_vm.$store.state.title)
                                              )
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("v-spacer"),
                          _vm._v(" "),
                          _vm.$store.state.showStation
                            ? _c(
                                "v-col",
                                {
                                  attrs: {
                                    cols: "auto",
                                    "align-self": "center",
                                    align: "end"
                                  }
                                },
                                [
                                  _c(
                                    "v-form",
                                    {
                                      on: {
                                        submit: function($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          return _vm.checkID($event)
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-text-field",
                                        {
                                          staticClass: "mx-2",
                                          style:
                                            "max-width: " +
                                            (_vm.$vuetify.breakpoint.smAndUp
                                              ? "300px"
                                              : "140px") +
                                            ";",
                                          attrs: {
                                            id: "search",
                                            outlined: "",
                                            "background-color": "white",
                                            dense: "",
                                            "hide-details": "",
                                            placeholder: _vm.$t(
                                              "dashboard.stationID"
                                            )
                                          },
                                          on: { "click:append": _vm.checkID },
                                          model: {
                                            value: _vm.stationID,
                                            callback: function($$v) {
                                              _vm.stationID = $$v
                                            },
                                            expression: "stationID"
                                          }
                                        },
                                        [
                                          _c(
                                            "template",
                                            { slot: "append" },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    size: "19",
                                                    color: "black"
                                                  }
                                                },
                                                [_vm._v("search")]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("v-col", { attrs: { cols: "auto" } }, [
                            _c(
                              "div",
                              { staticClass: "primary pr-4" },
                              [
                                _c(
                                  "v-menu",
                                  {
                                    attrs: { "offset-y": "", left: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            return [
                                              _c(
                                                "div",
                                                { staticClass: "primary" },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      {
                                                        attrs: {
                                                          text: "",
                                                          dark: "",
                                                          ripple: false,
                                                          height: _vm.$vuetify
                                                            .breakpoint.mdAndUp
                                                            ? 52
                                                            : 40
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            _vm.drawer = false
                                                          }
                                                        }
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            size: _vm.$vuetify
                                                              .breakpoint
                                                              .mdAndUp
                                                              ? 32
                                                              : 24
                                                          }
                                                        },
                                                        [_vm._v("fas fa-bars")]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      2014247347
                                    )
                                  },
                                  [
                                    _vm._v(" "),
                                    _c(
                                      "v-list",
                                      { staticClass: "greyInactive" },
                                      _vm._l(_vm.items, function(item, index) {
                                        return _c(
                                          "v-list-item",
                                          {
                                            key: index,
                                            class: {
                                              bottomBorder2: index === 6,
                                              aboutBorder: index === 0
                                            },
                                            staticStyle: {
                                              "min-height": "40px"
                                            },
                                            attrs: {
                                              target: item.target
                                                ? item.target
                                                : "",
                                              to: item.href
                                            }
                                          },
                                          [
                                            _c(
                                              "v-list-item-title",
                                              {
                                                staticClass:
                                                  "menuText greyText--textmenuText greyText--text pb-1"
                                              },
                                              [_vm._v(_vm._s(item.title))]
                                            )
                                          ],
                                          1
                                        )
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.$vuetify.breakpoint.smAndUp
                    ? _c("v-col", {
                        staticClass: "primary fill-height",
                        attrs: { cols: "1", md: "2" }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-content",
        { class: { "fill-height": _vm.fullPage } },
        [
          _c(
            "v-container",
            {
              staticStyle: { height: "100%", padding: "0px" },
              attrs: { fluid: "" }
            },
            [
              _c(
                "transition",
                { attrs: { name: "fade", mode: "out-in" } },
                [_c("nuxt")],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-fab-transition",
        [
          _vm.$store.state.fullscreen
            ? _c(
                "v-btn",
                {
                  attrs: {
                    dark: "",
                    fab: "",
                    fixed: "",
                    bottom: "",
                    right: ""
                  },
                  on: { click: _vm.exitFullscreen }
                },
                [_c("v-icon", [_vm._v("fullscreen_exit")])],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }