
import Vue from 'vue'

Vue.prototype.$openDialog = function(component, props, modal) {
    return new Promise((resolve, reject) => {
        this.$root.$emit('openDialog', {
            component,
            modal,
            props,
            resolve,
            reject,
        });
    })
}

Vue.directive('event', {
    bind(el, binding, vnode) {
        _.each(binding.modifiers, (v, k) => {
            binding.value && vnode.context.$root.$on(k, binding.value);
        });
    },
    update(el, binding, vnode) {
        if(binding.oldValue === binding.value) return;
        _.each(binding.modifiers, (v, k) => {
            binding.oldValue && vnode.context.$root.$off(k, binding.oldValue);
            binding.value && vnode.context.$root.$on(k, binding.value);
        });
    },
    unbind(el, binding, vnode) {
        _.each(binding.modifiers, (v, k) => {
            binding.value && vnode.context.$root.$off(k, binding.value);
        });
    }
});



