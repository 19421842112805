import 'source-map-support/register'
import Vue from 'vue'
import Vuetify from 'vuetify'

import moment from 'moment-timezone'
import Vue2Filters from 'vue2-filters'
import FusionCharts from 'fusioncharts';
import VueFusionCharts from 'vue-fusioncharts';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';

// optional import of scroll behaviour
import { Buffer } from 'buffer';
import DataTable from '~/components/DataTable'
import DataTableEdit from '~/components/DataTableEdit'
import Formatter from '~/components/formatter'
import BForm from '~/components/BForm'
import DatePicker from '~/components/DatePicker'
import ObjectPicker from '~/components/ObjectPicker'
import ImagePicker from '~/components/ImagePicker'
import Uploader from '~/components/Uploader'
import ConstantSelect from '~/components/ConstantSelect'
import PerfectScrollbar from '~/components/PerfectScrollBar'
import SimpleList from '~/components/SimpleList'
import BBtn from '~/components/BBtn'
import ObjectSelect from '~/components/ObjectSelect'
// import BarChart from "~/components/charts/BarChart";
import PieChart from "~/components/charts/PieChart";
import BarChart from "~/components/charts/BarChart";

import LineChart from "~/components/charts/LineChart";
import UVIndex from "~/components/charts/UVIndex";
import Visibility from "~/components/charts/Visibility";


import VueMask from 'v-mask'

import _ from 'lodash'

import store from '~/store/index'

Vue.component('b-form', BForm);
Vue.component('formatter', Formatter);
Vue.component('data-table', DataTable);
Vue.component('data-table-edit', DataTableEdit);
Vue.component('perfect-scrollbar', PerfectScrollbar);
Vue.component('date-picker', DatePicker);
Vue.component('object-picker', ObjectPicker);
Vue.component('image-picker', ImagePicker);
Vue.component('constant-select', ConstantSelect);
Vue.component('uploader', Uploader);
Vue.component('simple-list', SimpleList);
Vue.component('b-btn', BBtn);
 Vue.component('object-select', ObjectSelect);
//  Vue.component("bar-chart", BarChart);
 Vue.component("pie-chart", PieChart);
 Vue.component("bar-chart", BarChart);

 Vue.component("line-chart", LineChart);
 Vue.component("uv-index", UVIndex);
 Vue.component("visibility", Visibility);


Vue.filter('smartSize', (value, showSize = true, showUnit = true) => {
    let unit = 'B';
    if (value > 512) {
        value /= 1024;
        unit = 'KB';
        if (value > 512) {
            value /= 1024;
            unit = 'MB';
            if (value > 512) {
                value /= 1024;
                unit = 'GB';
                if (value > 512) {
                    value /= 1024;
                    unit = 'TB';
                }
            }
        }
        if (value < 1) value = Math.round(value * 100) / 100;
        else if (value < 10) value = Math.round(value * 10) / 10;
        else value = Math.floor(value);
    }
    if (showSize && showUnit) return value + ' ' + unit;
    else if (showSize) return '' + value;
    else if (showUnit) return unit;
})

Vue.filter('beautiMongo', (value) => {
    return new Buffer(value, 'hex').toString('base64').replace(/\+/g, '-').replace(/\//g, '-');
})

Vue.filter('moment', (ctx, value, format) => {
    return value ? (value instanceof moment ? value : moment(value)).locale(ctx.$store.state.locale).format(format || 'lll') : '-'
})

Vue.filter('duration', (ctx, value) => {
    if(!value) return '---';
    const h = moment.duration(value);
    return `${h.asHours() | 0} ${ctx.$t('unit.hours')} ${h.minutes()} ${ctx.$t('unit.minutes')}`
})

Vue.filter('date', (ctx, value) => {
    return value ? (value instanceof moment ? value : moment(value).utcOffset(0)).locale(ctx.$store.state.locale).format('ll') : '-'
})

Vue.filter("numberWithCommas", (ctx, value) => {
    return value && typeof value === "number"
        ? value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
        : "";
});


Vue.use(Vue2Filters)
Vue.use(VueFusionCharts, FusionCharts);
FusionTheme(FusionCharts);

Vue.use(VueMask);

export function setPropHandler(item, $event) {
    //console.log('set prop', item, $event);
    _.each($event, (v, k) => {
        let it = item[k];
        if (v && typeof v === 'object') {
            if (it && typeof it === 'object' && v.$replace === undefined) {
                //console.warn('go inner', JSON.stringify(it), JSON.stringify(v));
                setPropHandler(it, v);
            } else if (v.$replace !== undefined) {
                //console.warn('replace1', JSON.stringify(item), JSON.stringify(k), JSON.stringify(v.$replace));
                Vue.set(item, k, v.$replace);
            }
            else {
                //console.warn('set & go inner', JSON.stringify(item), JSON.stringify(k), JSON.stringify(v));
                Vue.set(item, k, it = {});
                setPropHandler(it, v);
            }
        } else {
            if (v && v.$replace) {
                //console.warn('replace2', JSON.stringify(item), JSON.stringify(k), JSON.stringify(v.$replace));
                Vue.set(item, k, v.$replace);
            } else {
                //console.warn('set', JSON.stringify(item), JSON.stringify(k), JSON.stringify(v));
                Vue.set(item, k, v);
            }
        }
    });
}

Vue.prototype.$setPropHandler = setPropHandler;

Vue.prototype.$dateTime = function(value) {
    return moment(value).fromNow();
};

Vue.prototype.$dateTimeUnix = function(value) {
    return moment.unix(value).fromNow();
};

Vue.prototype.$dewPoint = function(temperatureI, humidityI) {
    if (temperatureI == null || humidityI == null) return null;
    const temperature = +temperatureI;
    const humidity = +humidityI;
    const ews = humidity * 0.01 * Math.exp((17.502*temperature)/(240.9+temperature));
    const num = 240.9 * Math.log(ews);
    const den = 17.5 - Math.log(ews);
    return Number(num / den);
};

Vue.prototype.$heatIndex = function(temperatureI, humidityI){
    if (temperatureI == null || humidityI == null) return null;
    const temperature = +temperatureI;
    const temperatureF = (Number(temperature) * 1.8 + 32)
    const humidity = +humidityI;
    const heatIndexF = -42.379 + (2.04901523*temperatureF) + (10.14333127*humidity) - 0.22475541*(temperatureF*humidity) - (0.00683783* Math.pow(temperatureF,2)) - (0.05481717*Math.pow(humidity,2)) + (0.00122874*(Math.pow(temperatureF, 2)*humidity)) + (0.00085282*(temperatureF*Math.pow(humidity,2))) - (0.00000199*(Math.pow(temperatureF,2)*Math.pow(humidity,2)));
    const heatIndexC = (heatIndexF - 32) / 1.8;
    return heatIndexC;
},

Vue.prototype.$windChill = function(temperatureI, windspdI) {
    if (temperatureI == null || windspdI == null) return null;
    if(temperatureI > 18) {
        return temperatureI
    } else if(temperatureI <= - 50) {
        return 'Lo'
    } else if(temperatureI <= 18 && windspdI <= 100) {
        const temperature = +temperatureI;
        const windspd = +windspdI;
        const c1 = 13.12;
        const c2 = 0.6215;
        const c3 = 11.37;
        const windChill = c1 + c2*temperature - c3*(Math.pow(windspd,0.16)) + 0.3965*temperature*(Math.pow(windspd,0.15));
        return windChill
    } else return '---'
},

Vue.prototype.$feelsLike = function(temperatureI, humidity, windspd) {
    if (temperatureI == null) return null;
    const windKm = (Number(windspd)*3.6);
    const temperature = +temperatureI;
    if (temperature <= 18 && temperature >= -50 && windKm >=4.8 && windKm<=100) {
        return this.$windChill(temperature, windKm);
    } else if (temperature <= 26) {
        return temperature;
    } else {
        return this.$heatIndex(temperature, humidity);
    }
},

Vue.prototype.$baro = function(value, settings, showUnit, noToFix) {
    if (settings&&settings.baroPressure == 'inHg') {
        return noToFix ? 
            Number(value)* 0.02953 + ( showUnit ? 'inHg' : '')
            :(Number(value)* 0.02953).toFixed(2) + ( showUnit ? 'inHg' : '');
    }
    if (settings&&settings.baroPressure == 'mmHg') {
        return noToFix ? 
            Number(value)*0.75006 + ( showUnit ? 'mmHg' : '')
            :(Number(value)*0.75006).toFixed(1) + ( showUnit ? 'mmHg' : '');
    }
    return noToFix ? 
        Number(value) + ( showUnit ? 'hPa' : '')
        :Number(value).toFixed(0) + ( showUnit ? 'hPa' : '');
};

Vue.prototype.$generateLabels = function(type, list) {
    var result = [];
    if (type == 'fullDay') {
        var result = _.map(list, it => moment(it.time).format("HH:mm"))
        const lastOne = _.last(list)
        if (lastOne && lastOne.time) {
            const endOfTheDate = moment(lastOne.time).endOf("day")
            var current = moment(lastOne.time).add(5, 'minutes')
            while (current.isBefore(endOfTheDate)) {
                result.push(current.format("HH:mm"))
                current = moment(current).add(5, 'minutes')
            }
        }
        return result
    }
    if (type == '1d') {
        result = _.map(list, it => moment(it.time).format("HH:mm"));
        return result;
    }
    if (_.indexOf(['past7Day', 'thisweek', 'week'], type) >= 0) {
        var index = 0;
        _.forEach(list, it => {
            if (index % 48 == 0) {
                result.push(moment(it.time).format("YYYY-MM-DD"));
            } else {
                result.push(moment(it.time).format("HH:mm"));
            }
            index += 1;
        });
        return result;
    }
    if (_.indexOf(['past30Day', 'thisMonth', 'month'], type) >= 0) {
        var index = 0;
        _.forEach(list, it => {
            if (index % 24 == 0) {
                result.push(moment(it.time).format("YYYY-MM-DD"));
            } else {
                result.push(moment(it.time).format("HH:mm"));
            }
            index += 1;
        });
        return result;
    }
    
    result = _.map(list, it => moment(it.time).format("YYYY-MM-DD"));
    return result;
}

Vue.prototype.$toPath = function (path, val) {
    return _.reduceRight(('' + path).split('.'), (p, k) => ({ [k]: p }), val);
}

Vue.directive('focus', {
    inserted: function (el, binding) {
        if (binding.value) {
            var input = el.querySelector('input');
            input ? input.focus() : el.focus()
        }
    },
    update(el, binding, vnode, oldVnode) {
        if (binding.value && binding.value !== binding.oldValue) {
            var input = el.querySelector('input');
            input ? input.focus() : el.focus()
        }
    },
})

Vue.prototype.$copy = function (elem, text) {
    if (navigator.clipboard) {
        return navigator.clipboard.writeText(text);
    }
    else if (elem) {
        let el = elem.$el;
        if (el.name !== 'INPUT' || el.name !== 'TEXTAREA')
            el = el.querySelector('input') || el.querySelector('textarea');
        if (el) {
            el.focus();
            el.select();
            document.execCommand('copy');
        }
    }
}



Vue.directive('action', {
    bind(el, binding, vnode) {
        const root = vnode.context.$root;
        const router = vnode.context.$router;
        const store = vnode.context.$store;
        if (!el.handler) {
            el.handler = {};
        }
        _.each(binding.modifiers, (v, k) => {
            el.handler[k] = async (action) => {
                if (binding.value instanceof Function) {
                    store.commit('SET_PROCESSING', action && action.action);
                    try {
                        await binding.value();
                    } catch (e) {
                        store.commit('SET_ERROR', e.message);
                    } finally {
                        store.commit('SET_PROCESSING', null);
                    }
                } else {
                    router.push(binding.value);
                }
            }
            root.$on(k, el.handler[k])
        });
    },
    unbind(el, binding, vnode) {
        const root = vnode.context.$root;
        _.each(binding.modifiers, (v, k) => {
            root.$off(k, el.handler[k])
            delete el.handler[k];
        })
    }
})

Vue.directive('feathers', {
    bind(el, binding, vnode) {
        const feathers = vnode.context.$feathers;
        if (!el.fhandler) {
            el.fhandler = {};
        }
        _.each(binding.modifiers, (v, k) => {
            if (k === 'created' || k === 'patched' || k === 'removed' || k === 'updated') return;
            el.fhandler[k] = (data) => {
                if (binding.value) binding.value(data);
            }
            const s = feathers.service(k);
            if (binding.modifiers.created) s.on('created', el.fhandler[k]);
            if (binding.modifiers.patched) s.on('patched', el.fhandler[k]);
            if (binding.modifiers.removed) s.on('removed', el.fhandler[k]);
            if (binding.modifiers.updated) s.on('updated', el.fhandler[k]);
        });
    },
    unbind(el, binding, vnode) {
        const feathers = vnode.context.$feathers;
        _.each(binding.modifiers, (v, k) => {
            if (k === 'created' || k === 'patched' || k === 'removed' || k === 'updated') return;
            const s = feathers.service(k);
            if (binding.modifiers.created) s.removeListener('created', el.fhandler[k]);
            if (binding.modifiers.patched) s.removeListener('patched', el.fhandler[k]);
            if (binding.modifiers.removed) s.removeListener('removed', el.fhandler[k]);
            if (binding.modifiers.updated) s.removeListener('updated', el.fhandler[k]);
            delete el.fhandler[k];
        })
    }
});


Vue.directive('focus', {
    inserted: function (el, binding) {
        if (binding.value) {
            var input = el.querySelector('input');
            input ? input.focus() : el.focus()
        }
    },
    update(el, binding, vnode, oldVnode) {
        if (binding.value && binding.value !== binding.oldValue) {
            var input = el.querySelector('input');
            input ? input.focus() : el.focus()
        }
    },
})


Vue.directive('escape', {
    bind(el, binding) {
        el.$escape = () => {
            return binding.value;
        };
        if (!window.$escape) {
            window.$escape = (e) => {
                if (e.keyCode === 27) {
                    const h = window.$escape.handlers;
                    for (var i = h.length - 1; i >= 0; i--) {
                        if (h[i] && h[i]() && h[i]()(e)) {
                            e.stopPropagation();
                            e.preventDefault();
                            break;
                        }
                    }
                }
            };
            window.$escape.handlers = [];
            window.addEventListener('keydown', window.$escape);
        }
        window.$escape.handlers.push(el.$escape);
    },
    unbind(el, binding) {
        if (el.$escape) {
            if (window.$escape) {
                const h = window.$escape.handlers;
                const idx = h.indexOf(el.$escape);
                idx !== -1 && h.splice(idx, 1);
            }
            delete el.$escape;
        }
    }
})

Vue.directive('press', {
    bind(el, binding, vnode) {
        let p = el.$press = {
            mousedown(e) {
                if (p.igs) {
                    p.igs = false;
                    return;
                }
                p.cancel();
                p.int = setTimeout(p.long, 1000);
            },
            mouseup(e) {
                if (p.ige) {
                    p.ige = false;
                    return;
                }
                if (p.ig) {
                    p.cancel();

                    // prevent long hold event bubbling down to inner elements
                    window.addEventListener(
                        'click',
                        p.captureClick,
                        true
                    );
                    setTimeout(() => {
                        window.removeEventListener('click', p.captureClick, true);
                    }, 10);
                    return;
                }
                p.cancel();
                p.ic = true;
            },
            captureClick(e) {
                e.stopPropagation();
                e.preventDefault();
            },
            mouseleave(e) {
                p.cancel();
            },
            click(e) {
                if (p.ic) {
                    emit(vnode, 'shortpress');
                    p.cancel();
                }
            },
            touchstart(e) {
                p.igs = true;
                p.cancel();
                p.int = setTimeout(p.long, 1000);
                if (event.targetTouches.length === 1) {
                    p.clientX = event.targetTouches[0].clientX;
                    p.clientY = event.targetTouches[0].clientY;
                }
            },
            touchmove(e) {
                if (!p.igs) return;
                if (event.targetTouches.length === 1) {
                    const deltaX = Math.abs(event.targetTouches[0].clientX - p.clientX)
                    const deltaY = Math.abs(event.targetTouches[0].clientY - p.clientY)
                    if (deltaX > 20 || deltaY > 20) {
                        p.cancel();
                    }
                }
            },
            touchend(e) {
                p.ige = true;
                if (p.ig) {
                    p.cancel();
                    return;
                }
                p.cancel();
                p.ic = true;
            },
            long() {
                p.int = 0;
                p.ig = true;
                emit(vnode, 'longpress');
            },
            cancel() {
                if (p.int) {
                    clearTimeout(p.int);
                    p.int = 0;
                }
                p.ig = false;
                p.ic = false;
            },
            int: 0,
            ig: false,
            ic: false,
            clientX: 0,
            clientY: 0,
        }
        el.addEventListener('mousedown', p.mousedown);
        el.addEventListener('mouseup', p.mouseup);
        el.addEventListener('mouseleave', p.mouseleave);
        el.addEventListener('click', p.click);
        el.addEventListener('touchend', p.touchend);
        el.addEventListener('touchmove', p.touchmove, { passive: true });
        el.addEventListener('touchstart', p.touchstart);
    },
    unbind(el, binding, vnode) {
        let p = el.$press;
        if (p) {
            el.removeEventListener('mousedown', p.mousedown);
            el.removeEventListener('mouseup', p.mouseup);
            el.removeEventListener('mouseleave', p.mouseleave);
            el.removeEventListener('click', p.click);
            el.removeEventListener('touchend', p.touchend);
            el.addEventListener('touchmove', p.touchmove, { passive: true });
            el.removeEventListener('touchstart', p.touchstart);
            delete el.$press;
        }
    }
});



Vue.filter('name', (ctx, value) => {
    return typeof value === 'string' ? value : value ? `${value.lastName} ${value.firstName}` : '';
})

Vue.filter('username', (ctx, value) => {
    if (!value) return ctx.$t('basic.no_user');
    if (typeof value === 'string') return value;
    return Vue.filter('name')(ctx, value.name) || value.email || value._id;
})

Vue.prototype.$moment = moment;

Vue.filter('join', function (ctx, items) {
    if (items instanceof Array) return items.join(', ');
    return items;
})

Vue.filter('enum', function (ctx, value, item, parent, header) {
    const type = ctx.$types.resolve(header.type);
    return ctx.$td((type && type.enumDict[value]) || value);
})

Vue.prototype.$enum = function (value, typeName) {
    const type = this.$types.resolve(typeName);
    return this.$td((type && type.enumDict[value]) || value)
}

Vue.filter('tf', function (ctx, value, item, parent, header) {
    const t = _.get(value, header.tpath + 't');
    const v = (!_.isEmpty(t) && t) || _.get(value, header.tpath);
    return ctx.$td(v);
})