var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "action",
          rawName: "v-action.add",
          value: _vm.addActionClick,
          expression: "addActionClick",
          modifiers: { add: true }
        },
        {
          name: "action",
          rawName: "v-action.search",
          value: _vm.searchActionClick,
          expression: "searchActionClick",
          modifiers: { search: true }
        },
        {
          name: "action",
          rawName: "v-action.export",
          value: _vm.exportActionClick,
          expression: "exportActionClick",
          modifiers: { export: true }
        },
        {
          name: "action",
          rawName: "v-action.refresh",
          value: _vm.refreshActionClick,
          expression: "refreshActionClick",
          modifiers: { refresh: true }
        }
      ],
      class: { "fill-height": _vm.fillHeight }
    },
    [
      _c(
        "v-dialog",
        {
          directives: [
            {
              name: "escape",
              rawName: "v-escape",
              value: function() {
                return _vm.editing && ((_vm.editing = false), true)
              },
              expression: "() => (editing && (editing = false, true))"
            }
          ],
          attrs: {
            "max-width": "80%",
            "content-class": "mDialog",
            scrollable: false,
            persistent: ""
          },
          model: {
            value: _vm.editing,
            callback: function($$v) {
              _vm.editing = $$v
            },
            expression: "editing"
          }
        },
        [
          _vm.editingItem
            ? _c(
                "v-card",
                { attrs: { height: "100%" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { column: "", "fill-height": "" } },
                    [
                      _c("v-card-title", { staticClass: "headline" }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm.editingItem[this.mItemKey]
                                ? _vm.$t("datatable.edit")
                                : _vm.$t("datatable.new")
                            ) +
                            " " +
                            _vm._s(_vm.$td(_vm.data.name)) +
                            "\n                "
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-flex",
                        {
                          staticStyle: {
                            flex: "1",
                            "flex-grow": "1",
                            overflow: "auto"
                          }
                        },
                        [
                          _c(
                            "v-card-text",
                            [
                              _c(
                                "data-table-edit",
                                [
                                  _vm._t("editor", null, {
                                    origin: _vm.editingOrigin,
                                    item: _vm.editingItem,
                                    computed: _vm.getComputed(_vm.editingItem)
                                  })
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card-actions",
                        [
                          _c(
                            "v-layout",
                            {
                              staticStyle: {
                                "justify-content": "space-around"
                              },
                              attrs: { row: "" }
                            },
                            [
                              _c(
                                "div",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        loading: _vm.editLoading,
                                        depressed: "",
                                        large: "",
                                        dark: "",
                                        color: "green"
                                      },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          $event.stopPropagation()
                                          return _vm.save($event)
                                        }
                                      }
                                    },
                                    [_c("v-icon", [_vm._v("done")])],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        disabled: _vm.editLoading,
                                        depressed: "",
                                        large: "",
                                        dark: "",
                                        color: "red"
                                      },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          $event.stopPropagation()
                                          ;(_vm.editing = false),
                                            (_vm.editingItem = null)
                                        }
                                      }
                                    },
                                    [_c("v-icon", [_vm._v("clear")])],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "600px", scrollable: false, persistent: "" },
          model: {
            value: _vm.exporting,
            callback: function($$v) {
              _vm.exporting = $$v
            },
            expression: "exporting"
          }
        },
        [
          _vm.exporting
            ? _c("data-table-export", {
                attrs: {
                  data: _vm.data,
                  mitems: _vm.mitems,
                  query: _vm.pagination,
                  exportFilter: _vm.exportFilter,
                  cursor: _vm.cursor,
                  get: _vm.get,
                  waitPending: _vm.waitPending,
                  headers: _vm.headers,
                  itemKey: _vm.mItemKey,
                  selected:
                    _vm.mselect && _vm.selectedLocal.length
                      ? _vm.selectedLocal
                      : null
                },
                model: {
                  value: _vm.exporting,
                  callback: function($$v) {
                    _vm.exporting = $$v
                  },
                  expression: "exporting"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          directives: [
            {
              name: "escape",
              rawName: "v-escape",
              value: function() {
                return _vm.deleting && ((_vm.deleting = false), true)
              },
              expression: "() => (deleting && (deleting = false, true))"
            }
          ],
          attrs: { "max-width": "80%" },
          model: {
            value: _vm.deleting,
            callback: function($$v) {
              _vm.deleting = $$v
            },
            expression: "deleting"
          }
        },
        [
          _vm.deletingItem
            ? _c(
                "v-card",
                [
                  _c("v-card-title", { staticClass: "headline" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("datatable.delete")) +
                        " " +
                        _vm._s(_vm.$td(_vm.data.name)) +
                        "\n            "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-list",
                        _vm._l(_vm.headers, function(header, idx) {
                          return !header.hideDelete
                            ? _c(
                                "v-list-item",
                                { key: idx },
                                [
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", {
                                        domProps: {
                                          textContent: _vm._s(header.text)
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "v-list-item-subtitle",
                                        [
                                          _c("renderer", {
                                            attrs: {
                                              render: _vm.renderItem,
                                              item: _vm.deletingItem,
                                              header: header
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-layout",
                        {
                          staticStyle: { "justify-content": "space-around" },
                          attrs: { row: "" }
                        },
                        [
                          _c(
                            "div",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    loading: _vm.deleteLoading,
                                    large: "",
                                    color: "red"
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                      return _vm.deleteItemCore()
                                    }
                                  }
                                },
                                [_c("v-icon", [_vm._v("delete")])],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    disabled: _vm.deleteLoading,
                                    large: "",
                                    color: "grey"
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                      _vm.deleting = false
                                    }
                                  }
                                },
                                [_c("v-icon", [_vm._v("arrow_back")])],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm.$vuetify.breakpoint.smAndDown && _vm.searchFilter
        ? _c(
            "v-dialog",
            {
              directives: [
                {
                  name: "escape",
                  rawName: "v-escape",
                  value: function() {
                    return _vm.searching && ((_vm.searching = false), true)
                  },
                  expression: "() => (searching && (searching = false, true))"
                }
              ],
              model: {
                value: _vm.searching,
                callback: function($$v) {
                  _vm.searching = $$v
                },
                expression: "searching"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-layout",
                    { attrs: { "fill-height": _vm.fillHeight, column: "" } },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "v-toolbar",
                            { attrs: { dense: "" } },
                            [
                              _c("v-toolbar-title", {
                                directives: [
                                  {
                                    name: "t",
                                    rawName: "v-t",
                                    value: "datatable.search",
                                    expression: "'datatable.search'"
                                  }
                                ]
                              }),
                              _vm._v(" "),
                              _c("v-spacer"),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  attrs: { icon: "" },
                                  on: { click: _vm.resetFilter }
                                },
                                [_c("v-icon", [_vm._v("clear")])],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  attrs: { icon: "" },
                                  on: { click: _vm.applyFilter }
                                },
                                [_c("v-icon", [_vm._v("filter_list")])],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card-text",
                        { staticStyle: { "flex-grow": "1", flex: "1" } },
                        [
                          _c(
                            _vm.fillHeight ? "perfect-scrollbar" : "div",
                            {
                              tag: "component",
                              class: { "scroll-area": _vm.fillHeight }
                            },
                            [
                              _vm._t("search", null, {
                                search: _vm.searchFilter
                              })
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          directives: [
            {
              name: "escape",
              rawName: "v-escape",
              value: function() {
                return _vm.batchDeleting && ((_vm.batchDeleting = false), true)
              },
              expression:
                "() => (batchDeleting && (batchDeleting = false, true))"
            }
          ],
          attrs: { "max-width": "80%", persistent: "" },
          model: {
            value: _vm.batchDeleting,
            callback: function($$v) {
              _vm.batchDeleting = $$v
            },
            expression: "batchDeleting"
          }
        },
        [
          _vm.batchDeleting
            ? _c(
                "data-table",
                {
                  attrs: {
                    headers: _vm.mheaders,
                    items: _vm.mselected,
                    data: {
                      name: _vm.data.name,
                      static: true
                    },
                    "no-multi": "",
                    "no-export": ""
                  }
                },
                [
                  _c(
                    "v-card-actions",
                    { attrs: { slot: "extend" }, slot: "extend" },
                    [
                      _vm.batchDeleteLoading
                        ? _c("v-progress-linear", {
                            attrs: { value: _vm.batchDeleteProgress }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-layout",
                        {
                          staticStyle: { "justify-content": "space-around" },
                          attrs: { row: "" }
                        },
                        [
                          _c(
                            "div",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    loading: _vm.batchDeleteLoading,
                                    large: "",
                                    color: "red"
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                      return _vm.batchDeleteCore()
                                    }
                                  }
                                },
                                [_c("v-icon", [_vm._v("delete")])],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    disabled: _vm.batchDeleteLoading,
                                    large: "",
                                    color: "grey"
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                      _vm.batchDeleting = false
                                    }
                                  }
                                },
                                [_c("v-icon", [_vm._v("arrow_back")])],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card",
        {
          class: { "fill-height": _vm.fillHeight },
          staticStyle: { overflow: "hidden" }
        },
        [
          _c(
            "v-layout",
            { attrs: { "fill-height": _vm.fillHeight, column: "" } },
            [
              _c(
                "div",
                [
                  !_vm.hideToolBar
                    ? _c(
                        "v-toolbar",
                        { attrs: { dense: "" } },
                        [
                          _vm._t("pre-title"),
                          _vm._v(" "),
                          _c("v-toolbar-title", {
                            directives: [
                              {
                                name: "td",
                                rawName: "v-td",
                                value: _vm.data.name,
                                expression: "data.name"
                              }
                            ]
                          }),
                          _vm._v(" "),
                          _c("v-spacer"),
                          _vm._v(" "),
                          _vm._t("pre-actions"),
                          _vm._v(" "),
                          !_vm.noExport
                            ? _c(
                                "b-btn",
                                {
                                  attrs: { "alt-text": _vm.$t("basic.export") },
                                  on: {
                                    click: function($event) {
                                      _vm.exporting = true
                                    }
                                  }
                                },
                                [_c("v-icon", [_vm._v("fas fa-file-export")])],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.search
                            ? _c(
                                "b-btn",
                                {
                                  attrs: { "alt-text": _vm.$t("basic.search") },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                      return _vm.beginSearch($event)
                                    }
                                  }
                                },
                                [_c("v-icon", [_vm._v("search")])],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.data && !_vm.data.static
                            ? _c(
                                "b-btn",
                                {
                                  attrs: {
                                    "alt-text": _vm.$t("basic.refresh")
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                      return _vm.reload($event)
                                    }
                                  }
                                },
                                [_c("v-icon", [_vm._v("refresh")])],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.add
                            ? _c(
                                "b-btn",
                                {
                                  attrs: {
                                    "alt-text": _vm.$t("basic.add"),
                                    to: _vm.add
                                  }
                                },
                                [_c("v-icon", [_vm._v("add")])],
                                1
                              )
                            : _vm.actions && !_vm.noAdd
                            ? _c(
                                "b-btn",
                                {
                                  attrs: { "alt-text": _vm.$t("basic.add") },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                      return _vm.editItem(null)
                                    }
                                  }
                                },
                                [_c("v-icon", [_vm._v("add")])],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm._t("post-actions")
                        ],
                        2
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                {
                  staticStyle: {
                    "flex-grow": "1",
                    flex: "1",
                    display: "flex",
                    "flex-direction": "column"
                  }
                },
                [
                  _c(
                    "v-slide-y-transition",
                    [
                      _vm.searchFilter && _vm.$vuetify.breakpoint.mdAndUp
                        ? _c(
                            "v-layout",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.searching,
                                  expression: "searching"
                                }
                              ],
                              staticClass: "mb-2",
                              attrs: { "fill-height": "" }
                            },
                            [
                              _c(
                                "v-card",
                                {
                                  class: { "fill-height": _vm.fillHeight },
                                  staticStyle: { width: "100%" }
                                },
                                [
                                  _c(
                                    "v-layout",
                                    {
                                      attrs: {
                                        "fill-height": _vm.fillHeight,
                                        column: ""
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "v-toolbar",
                                            { attrs: { dense: "" } },
                                            [
                                              _c("v-toolbar-title", {
                                                directives: [
                                                  {
                                                    name: "t",
                                                    rawName: "v-t",
                                                    value: "datatable.search",
                                                    expression:
                                                      "'datatable.search'"
                                                  }
                                                ]
                                              }),
                                              _vm._v(" "),
                                              _c("v-spacer"),
                                              _vm._v(" "),
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: { icon: "" },
                                                  on: { click: _vm.resetFilter }
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("clear")
                                                  ])
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: { icon: "" },
                                                  on: { click: _vm.applyFilter }
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("filter_list")
                                                  ])
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-card-text",
                                        {
                                          staticStyle: {
                                            "flex-grow": "1",
                                            flex: "1"
                                          }
                                        },
                                        [
                                          _c(
                                            _vm.fillHeight
                                              ? "perfect-scrollbar"
                                              : "div",
                                            {
                                              tag: "component",
                                              class: {
                                                "scroll-area": _vm.fillHeight
                                              }
                                            },
                                            [
                                              _vm._t("search", null, {
                                                search: _vm.searchFilter,
                                                computed: _vm.getSearchComputed()
                                              })
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-layout",
                    { attrs: { "fill-height": "" } },
                    [
                      _c(
                        _vm.fillHeight ? "perfect-scrollbar" : "div",
                        {
                          tag: "component",
                          class: { "scroll-area": _vm.fillHeight },
                          staticStyle: { width: "100%" }
                        },
                        [
                          _c(
                            _vm.iterator ? "v-data-iterator" : "v-data-table",
                            {
                              tag: "component",
                              attrs: {
                                options: _vm.pagination,
                                items: _vm.mitems,
                                headers: (_vm.actions
                                  ? [{ text: "", value: "", sortable: false }]
                                  : []
                                ).concat(_vm.mheaders),
                                "show-select": _vm.mselect,
                                "no-data-text": _vm.$t("basic.no_data"),
                                "no-results-text": _vm.$t("basic.no_results"),
                                "loading-text": _vm.$t("basic.loading"),
                                "footer-props": {
                                  "items-per-page-text": _vm.$t(
                                    "basic.row_per_page"
                                  )
                                },
                                "item-key": _vm.mItemKey,
                                loading: _vm.loading,
                                "server-items-length": _vm.items
                                  ? undefined
                                  : _vm.pagination.total,
                                "hide-default-footer": "",
                                "multi-sort": ""
                              },
                              on: {
                                "update:options": function($event) {
                                  _vm.pagination = $event
                                }
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "header.data-table-select",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              display: "flex",
                                              "flex-direction": "row",
                                              "align-items": "center"
                                            }
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "mr-4" },
                                              [
                                                _c("v-checkbox", {
                                                  attrs: {
                                                    "input-value":
                                                      _vm.selectState === "all",
                                                    indeterminate:
                                                      _vm.selectState !==
                                                        "none" &&
                                                      _vm.selectState !== "all",
                                                    primary: "",
                                                    "hide-details": ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      $event.stopPropagation()
                                                      return _vm.toggleAll(
                                                        $event
                                                      )
                                                    }
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _vm.mselected.length
                                              ? [
                                                  !_vm.noRemove
                                                    ? _c(
                                                        "b-btn",
                                                        {
                                                          staticClass: "mx-1",
                                                          attrs: {
                                                            "alt-color": "pink",
                                                            "alt-text": _vm.$t(
                                                              "basic.delete"
                                                            )
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              $event.preventDefault()
                                                              $event.stopPropagation()
                                                              return _vm.batchDelete(
                                                                _vm.mselected
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                color: "pink"
                                                              }
                                                            },
                                                            [_vm._v("delete")]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  !_vm.noExport
                                                    ? _c(
                                                        "b-btn",
                                                        {
                                                          attrs: {
                                                            "alt-text": _vm.$t(
                                                              "basic.export"
                                                            )
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              _vm.exporting = true
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c("v-icon", [
                                                            _vm._v(
                                                              "fas fa-file-export"
                                                            )
                                                          ])
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                ]
                                              : _vm._e()
                                          ],
                                          2
                                        )
                                      ]
                                    }
                                  },
                                  {
                                    key: "item",
                                    fn: function(ref) {
                                      var item = ref.item
                                      var isExpanded = ref.isExpanded
                                      var isSelected = ref.isSelected
                                      var expand = ref.expand
                                      var select = ref.select
                                      return [
                                        _c(
                                          "tr",
                                          {
                                            directives: [
                                              {
                                                name: "press",
                                                rawName: "v-press"
                                              }
                                            ],
                                            class: { clickable: _vm.clickable },
                                            on: {
                                              longpress: function($event) {
                                                return select(!isSelected)
                                              },
                                              shortpress: function($event) {
                                                _vm.clickable
                                                  ? _vm.$emit("pickItem", item)
                                                  : select &&
                                                    _vm.selectState !== "none"
                                                  ? select(!isSelected)
                                                  : expand(!isExpanded)
                                              }
                                            }
                                          },
                                          [
                                            _vm.mselect
                                              ? _c(
                                                  "td",
                                                  [
                                                    _c("v-checkbox", {
                                                      staticStyle: {
                                                        "max-width": "50px"
                                                      },
                                                      attrs: {
                                                        value: isSelected,
                                                        primary: "",
                                                        "hide-details": "",
                                                        height: 20
                                                      },
                                                      on: {
                                                        change: function(
                                                          $event
                                                        ) {
                                                          return select($event)
                                                        }
                                                      },
                                                      nativeOn: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          void 0
                                                        }
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.actions
                                              ? _c(
                                                  "td",
                                                  {
                                                    staticStyle: {
                                                      "white-space": "nowrap"
                                                    }
                                                  },
                                                  [
                                                    _vm._t(
                                                      "actions",
                                                      [
                                                        _c(
                                                          "v-layout",
                                                          {
                                                            attrs: {
                                                              "align-content-center":
                                                                "",
                                                              "align-center": ""
                                                            }
                                                          },
                                                          [
                                                            !_vm.noEdit
                                                              ? _c(
                                                                  "b-btn",
                                                                  {
                                                                    staticClass:
                                                                      "mx-1",
                                                                    attrs: {
                                                                      "alt-color":
                                                                        "teal",
                                                                      "alt-text": _vm.$t(
                                                                        "basic.edit"
                                                                      ),
                                                                      to:
                                                                        (_vm.disableInlineEdit &&
                                                                          (_vm.rootPath
                                                                            ? _vm.rootPath
                                                                            : _vm.$route.path.endsWith(
                                                                                "/"
                                                                              )
                                                                            ? _vm
                                                                                .$route
                                                                                .path
                                                                            : _vm
                                                                                .$route
                                                                                .path +
                                                                              "/") +
                                                                            "edit/" +
                                                                            item._id) ||
                                                                        undefined
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        $event.preventDefault()
                                                                        $event.stopPropagation()
                                                                        !_vm.disableInlineEdit &&
                                                                          _vm.editItem(
                                                                            item
                                                                          )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        attrs: {
                                                                          color:
                                                                            "teal"
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "edit"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                            _vm._v(" "),
                                                            !_vm.noEdit &&
                                                            !_vm.noClone
                                                              ? _c(
                                                                  "b-btn",
                                                                  {
                                                                    staticClass:
                                                                      "mx-1",
                                                                    attrs: {
                                                                      "alt-color":
                                                                        "purple",
                                                                      "alt-text": _vm.$t(
                                                                        "basic.clone"
                                                                      ),
                                                                      to:
                                                                        (_vm.disableInlineEdit &&
                                                                          (_vm.$route.path.endsWith(
                                                                            "/"
                                                                          )
                                                                            ? _vm
                                                                                .$route
                                                                                .path
                                                                            : _vm
                                                                                .$route
                                                                                .path +
                                                                              "/") +
                                                                            "edit?clone=" +
                                                                            item._id) ||
                                                                        undefined
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        $event.preventDefault()
                                                                        $event.stopPropagation()
                                                                        !_vm.disableInlineEdit &&
                                                                          _vm.editItem(
                                                                            item,
                                                                            true
                                                                          )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        attrs: {
                                                                          color:
                                                                            "purple"
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "file_copy"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                            _vm._v(" "),
                                                            !_vm.noRemove
                                                              ? _c(
                                                                  "b-btn",
                                                                  {
                                                                    staticClass:
                                                                      "mx-1",
                                                                    attrs: {
                                                                      "alt-color":
                                                                        "pink",
                                                                      "alt-text": _vm.$t(
                                                                        "basic.delete"
                                                                      )
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        $event.preventDefault()
                                                                        $event.stopPropagation()
                                                                        return _vm.deleteItem(
                                                                          item
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        attrs: {
                                                                          color:
                                                                            "pink"
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "delete"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                            _vm._v(" "),
                                                            _vm._t(
                                                              "extraActions",
                                                              null,
                                                              {
                                                                item: item,
                                                                editItem:
                                                                  _vm.editItem
                                                              }
                                                            )
                                                          ],
                                                          2
                                                        )
                                                      ],
                                                      { item: item }
                                                    )
                                                  ],
                                                  2
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm._l(_vm.headers, function(
                                              header,
                                              idx
                                            ) {
                                              return _c("td", { key: idx }, [
                                                _c(
                                                  "span",
                                                  [
                                                    _c("renderer", {
                                                      attrs: {
                                                        render: _vm.renderItem,
                                                        item: item,
                                                        header: header
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ])
                                            })
                                          ],
                                          2
                                        )
                                      ]
                                    }
                                  },
                                  {
                                    key: "expanded-item",
                                    fn: function(ref) {
                                      var item = ref.item
                                      var headers = ref.headers
                                      return [
                                        _c(
                                          "td",
                                          {
                                            staticStyle: { height: "auto" },
                                            attrs: { colspan: headers.length }
                                          },
                                          [
                                            _vm._t("expand", null, {
                                              item: item,
                                              computed: _vm.getComputed(item)
                                            })
                                          ],
                                          2
                                        )
                                      ]
                                    }
                                  },
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var items = ref.items
                                      return [
                                        _c(
                                          _vm.contentTag || "div",
                                          _vm._b(
                                            {
                                              tag: "component",
                                              class: _vm.contentClass
                                            },
                                            "component",
                                            _vm.contentProps,
                                            false
                                          ),
                                          [
                                            _vm._l(items, function(item) {
                                              return [
                                                _vm._t("item", null, {
                                                  item: item,
                                                  headers: _vm.headers,
                                                  get: _vm.get,
                                                  deleteItem: _vm.deleteItem
                                                })
                                              ]
                                            })
                                          ],
                                          2
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              ),
                              model: {
                                value: _vm.mselected,
                                callback: function($$v) {
                                  _vm.mselected = $$v
                                },
                                expression: "mselected"
                              }
                            }
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.paginate &&
              (!_vm.autoHidePage || (_vm.autoHidePage && _vm.pages > 1))
                ? _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-layout",
                        {
                          attrs: {
                            row: "",
                            "align-center": "",
                            "justify-center": ""
                          }
                        },
                        [
                          _vm.cursor
                            ? [
                                _c(
                                  "v-flex",
                                  { staticStyle: { "flex-grow": "1" } },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          icon: "",
                                          disabled:
                                            !_vm.source ||
                                            !_vm.source.cursors ||
                                            !_vm.source.cursors.previous
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.setCursor(
                                              _vm.source.cursors.previous
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("v-icon", [
                                          _vm._v("keyboard_arrow_left")
                                        ])
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          icon: "",
                                          disabled:
                                            !_vm.source ||
                                            !_vm.source.cursors ||
                                            !_vm.source.cursors.next
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.setCursor(
                                              _vm.source.cursors.next
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("v-icon", [
                                          _vm._v("keyboard_arrow_right")
                                        ])
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                !_vm.hideRowsPerPage
                                  ? _c("v-combobox", {
                                      staticStyle: { "max-width": "100px" },
                                      attrs: {
                                        label: _vm.$t("basic.row_per_page"),
                                        "hide-details": "",
                                        items: _vm.rowsPerPageItems
                                      },
                                      model: {
                                        value: _vm.rowsPerPage,
                                        callback: function($$v) {
                                          _vm.rowsPerPage = _vm._n($$v)
                                        },
                                        expression: "rowsPerPage"
                                      }
                                    })
                                  : _vm._e()
                              ]
                            : [
                                _c(
                                  "v-flex",
                                  { staticStyle: { "flex-grow": "1" } },
                                  [
                                    _c("v-pagination", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        circle: "",
                                        "total-visible": 10,
                                        length: _vm.pages
                                      },
                                      model: {
                                        value: _vm.page,
                                        callback: function($$v) {
                                          _vm.page = $$v
                                        },
                                        expression: "page"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticStyle: { width: "120px" } },
                                  [
                                    !_vm.hideRowsPerPage
                                      ? _c("v-combobox", {
                                          attrs: {
                                            label: _vm.$t("basic.row_per_page"),
                                            "hide-details": "",
                                            items: _vm.rowsPerPageItems
                                          },
                                          model: {
                                            value: _vm.rowsPerPage,
                                            callback: function($$v) {
                                              _vm.rowsPerPage = _vm._n($$v)
                                            },
                                            expression: "rowsPerPage"
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass: "px-2",
                                    staticStyle: {
                                      "min-width": "80px",
                                      "text-align": "right"
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.pageText))]
                                )
                              ]
                        ],
                        2
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm._t("extend")
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }