import Vue from 'vue'
import _ from 'lodash'
import { NotificationOpts } from 'serviceTypes'

function getID(item : any) {
    return item._id || item;
}

declare global {
    const PUSH_KEY: string;
}

interface NotificationPayload {
    type: 'orders',
    url : string
    path: string
}

interface ServiceMessage {
    type: "notificationclick",
    payload: NotificationPayload
}

type InferArr<T> = T extends (infer U)[] ? U : any;

class ChatManager {
    constructor(public $root : Vue) {
        $root.$on('login', this.login.bind(this));
        $root.$on('logout', this.logout.bind(this));
        $root.$on('clickNotification', (notification : NotificationOpts) => {
            this.onMessage(<any>{
                data: {
                    type: 'notificationclick',
                    payload: notification.opts.data,
                }
            })
        })
        this.init();

        if(this.$root.$messageQueue.supported) {
            this.$root.$messageQueue.ns('auth').on('pushToken', async token => {
                await this.registerPush();
            });

            this.$root.$messageQueue.ns('auth').on('url', async url => {
                this.$root.$router.replace(url);
            });
        }
    }

    inited : Promise<void> = null;
    //loaded = false;
    loaded = true;

    login() {
        //this.reset();
        if(this.loaded) {
            this.reset();
        }
    }

    logout() {
        this.reset();
        this.loaded = true;
    }

    reset() {
        this.inited = null;
        this.loaded = false;
        this.init();
    }

    init() {
        return (this.inited || (this.inited = this.initCore()))
    }

    async initCore() {
        if(!this.$root.$store.getters.userId) return;
    }

    async registerPush(force : boolean = false) {
        if(this.$root.$messageQueue.supported) {
            const { pushEnabled, pushToken } = await this.$root.$messageQueue.ns('auth').call('registerPush');
            await this.$root.$feathers.updateSession({
                pushEnabled,
                pushToken,
                pushType: 'firebase'
            })
            return;
        }
        this.initServiceWorker();
        if (!("Notification" in window)) {
            return false;
        }
        if(!force && this.$root.$store.state.local.askedPush) return;
        // this.$root.$store.commit('SET_LOCAL', {
        //     askedPush: true
        // })
        try {
            const status = await Notification.requestPermission();
            if(status === 'denied') return;
            if ('serviceWorker' in navigator) {
                const registrations = await navigator.serviceWorker.getRegistrations();
                let flag = false;
                for (const registration of registrations) {
                    const subscribeOptions = {
                        userVisibleOnly: true,
                        applicationServerKey: new Buffer(PUSH_KEY, 'base64')
                    }
                    try {
                        const pushSubscription = await registration.pushManager.subscribe(subscribeOptions);
                        await this.$root.$feathers.updateSession({
                            pushEnabled: true,
                            pushToken: pushSubscription,
                            pushType: 'web'
                        })
                        flag = true;
                    } catch(e) {
                        console.warn(e);
                    }
                }
                return flag;
            }
        } catch(e) {
            console.warn(e);
            return false;
        }
    }

    initedWorker = false;

    initServiceWorker() {
        if(this.initedWorker) return;
        this.initedWorker = true;
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.addEventListener('message', this.onMessage.bind(this));
        }
    }

    processDone = false;

    async processLaunch() {
        if(this.processDone) return;
        this.processDone = true;
        try {
            await this.initServiceWorker();
            if ('serviceWorker' in navigator) {
                navigator.serviceWorker.controller.postMessage({
                    type: 'getLaunch'
                })
            }
        } catch(e) {
            console.warn(e);
        }
    }

    onMessage(message : MessageEvent) {
        if(!message.data) return;
        const data : ServiceMessage = message.data;
        switch(data.type) {
            case 'notificationclick':
                if(this.$root.$route.path === data.payload.path) return;
                this.$root.$router.replace(data.payload.path)
                break;
        }
    }

    showInappNotification(opts : NotificationOpts) {
        if(opts.checkLink && this.$root.$route.path.indexOf(opts.checkLink) !== -1) {
            return;
        }
        this.$root.$store.commit('SET_NOTIFICATION', opts)
    }

    async sendNotficiation(opts : NotificationOpts) {
        if('Notification' in window) {
            if ('serviceWorker' in navigator) {
                // delay for 1s
                await new Promise((resolve => setTimeout(resolve, 1000)));
                navigator.serviceWorker.controller.postMessage({
                    type: 'notification',
                    payload: opts
                });
            } else {
                // fallback when no service worker
                const notification = new Notification(opts.title, opts.opts);
                notification.onclick = () => {
                    notification.close();
                    this.onMessage(<any>{
                        data: {
                            type: 'notificationclick',
                            payload: notification.data,
                        }
                    })
                }
            }
        } else {
            // fallback when no notification
            this.showInappNotification(opts)
        }
    }
}


declare module 'vue/types/vue' {
    export interface Vue {
        $chatManager : ChatManager
    }
}

let chatManager : ChatManager;

Object.defineProperty(Vue.prototype, "$chatManager", {
    get(this : Vue) {
        return chatManager || (chatManager = new ChatManager(this.$root));
    }
})
