
import _ from 'lodash'
import {
    VFlex,
    VLayout,
} from 'vuetify/lib'

export default {
    components: {
        VFlex,
        VLayout,
    },
    render (_c) {
        const items = (this._t('default') || []).map((item, idx) => this.processItem(_c, item, idx === 0));

        return _c('v-layout', {
            attrs: {
                wrap: true,
                row: true,
            }
        }, items, 2);
    },
    methods: {
        processItem (_c, item, root) {
            let size = 6;
            let canFocus = true;
            if(item.text) return;
            if (!item.componentOptions) {
                size = 12;
            } else {
                switch (item.componentOptions.tag) {
                    case 'v-text-field':
                    case 'translate-box':
                        if (item.componentOptions.propsData.multiLine !== undefined) {
                            size = 12;
                        }
                        break;
                    case 'v-btn-toggle':
                    case 'data-table':
                    case 'v-textarea':
                    case 'inventory-list':
                    case 'uploader':
                    case 'simple-list':
                        size = 12;
                        break;
                    case 'v-card':
                        size = 12;
                        _.each(item.componentOptions.children, (child, idx) => {
                            if ((child.data.staticClass || '').indexOf('card__text') !== -1) {
                                child.children = [
                                    _c('v-layout', {
                                        attrs: {
                                            wrap: true,
                                            row: true,
                                        }
                                    }, _.map(child.children, sub => this.processItem(_c, sub)), 2)
                                ]
                            }
                        });
                    case 'v-combobox':
                    case 'v-autocomplete':
                    case 'v-select':
                        canFocus = false;
                        break;                
                    }
            }
            const ret = _c('v-flex', {
                attrs: {
                    md12: true,
                    xs12: true,
                    ['lg' + size]: true,
                },
                staticClass: 'pa-2',
                directives: (root && canFocus && [
                    {
                        name: 'focus',
                        value: true,
                    }
                ]) || undefined,
            }, [
                item
            ]);
            return size === 12 ? [
                _c('v-flex', { attrs: { xs12: true } }),
                ret
            ] : ret;
        }
    }
}
