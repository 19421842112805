var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "highlights-item", staticStyle: { width: "100%" } },
    [
      _c("fusioncharts", {
        attrs: {
          type: _vm.type,
          width: _vm.width,
          height: _vm.height,
          containerbackgroundopacity: _vm.containerbackgroundopacity,
          dataformat: _vm.dataformat,
          datasource: _vm.datasource
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }