var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    {
      directives: [
        {
          name: "action",
          rawName: "v-action.fullscreen",
          value: _vm.fullscreen,
          expression: "fullscreen",
          modifiers: { fullscreen: true }
        },
        {
          name: "event",
          rawName: "v-event.openDialog",
          value: _vm.openDialog,
          expression: "openDialog",
          modifiers: { openDialog: true }
        },
        {
          name: "event",
          rawName: "v-event.modalResult",
          value: _vm.modalResult,
          expression: "modalResult",
          modifiers: { modalResult: true }
        }
      ],
      ref: "app",
      staticClass: "mainBg",
      class: { "full-page-app": _vm.fullPage },
      style:
        "" +
        (_vm.$vuetify.theme.dark
          ? "background-color: #707070;"
          : "background-color: #175566;"),
      nativeOn: {
        touchstart: function($event) {
          return _vm.startMove($event)
        },
        touchmove: function($event) {
          return _vm.canMove($event)
        },
        touchend: function($event) {
          return _vm.endMove($event)
        }
      }
    },
    [
      _c(
        "v-snackbar",
        {
          attrs: { top: "" },
          model: {
            value: _vm.error,
            callback: function($$v) {
              _vm.error = $$v
            },
            expression: "error"
          }
        },
        [
          _vm._v("\n        " + _vm._s(_vm.errorMessage) + "\n        "),
          _c("v-btn", {
            directives: [
              {
                name: "t",
                rawName: "v-t",
                value: "basic.close",
                expression: "'basic.close'"
              }
            ],
            attrs: { text: "", color: "pink" },
            nativeOn: {
              click: function($event) {
                _vm.error = false
              }
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: { top: "" },
          model: {
            value: _vm.success,
            callback: function($$v) {
              _vm.success = $$v
            },
            expression: "success"
          }
        },
        [
          _vm._v("\n        " + _vm._s(_vm.successMessage) + "\n        "),
          _c("v-btn", {
            directives: [
              {
                name: "t",
                rawName: "v-t",
                value: "basic.close",
                expression: "'basic.close'"
              }
            ],
            attrs: { text: "", color: "green" },
            nativeOn: {
              click: function($event) {
                _vm.success = false
              }
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm._l(_vm.dialogs, function(dialog) {
        return _c(
          "v-dialog",
          _vm._b(
            {
              key: dialog.key,
              model: {
                value: dialog.show,
                callback: function($$v) {
                  _vm.$set(dialog, "show", $$v)
                },
                expression: "dialog.show"
              }
            },
            "v-dialog",
            dialog.modal,
            false
          ),
          [
            dialog.loading
              ? [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-text",
                        { staticStyle: { "text-align": "center" } },
                        [
                          _c("v-progress-circular", {
                            attrs: { indeterminate: "", color: "blue" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              : [
                  _c(
                    dialog.component,
                    _vm._b(
                      { tag: "component", attrs: { modalId: dialog.key } },
                      "component",
                      dialog.props,
                      false
                    )
                  )
                ]
          ],
          2
        )
      }),
      _vm._v(" "),
      _c(
        "v-content",
        { class: ["gradientBg", { "fill-height": _vm.fullPage }] },
        [
          _c(
            "v-container",
            {
              staticClass: "gradientBg",
              staticStyle: { height: "100%" },
              attrs: { fluid: "" }
            },
            [
              _c(
                "transition",
                { attrs: { name: "fade", mode: "out-in" } },
                [_c("nuxt")],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-fab-transition",
        [
          _vm.$store.state.fullscreen
            ? _c(
                "v-btn",
                {
                  attrs: {
                    dark: "",
                    fab: "",
                    fixed: "",
                    bottom: "",
                    right: ""
                  },
                  on: { click: _vm.exitFullscreen }
                },
                [_c("v-icon", [_vm._v("fullscreen_exit")])],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }