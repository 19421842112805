var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { width: _vm.width } },
    [
      _vm.label
        ? _c("v-card-title", [
            _c("h5", { domProps: { textContent: _vm._s(_vm.label) } })
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-card-text",
        _vm._l(_vm.def.fields, function(field) {
          return _c(
            "div",
            { key: field.name },
            [
              _vm.evalCond(field)
                ? _c("constant-select", {
                    attrs: {
                      type: field.def,
                      name: _vm.$td(field.label),
                      value: _vm.def.eval(_vm.value, field.name, field.default)
                    },
                    on: {
                      input: function($event) {
                        return _vm.update(field.name, $event)
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }