import { Component, Prop, Watch, Vue } from "nuxt-property-decorator";
import { MetricOpts } from "./deviceTypes";

@Component
export class MetricInstance extends Vue {
    metricNameKey: string = ''
    get metricName() {
        return this.$t(this.metricNameKey, { n: this.n });
    }

    key: string = ''
    icon: string = ''
    icon2: string = ''
    component: string = ''
    showDetail = false;
    hasGraph = true;
    hasDetails = true;
    expandToTop = false;
    n = 0;
    defClass = 'square'
    expandClass = 'rectangle responFullRow responForecast'
    props: any = {};
    metricTypes: {
        name: string
        value: string
    }[] = null;
    currentMetricType: string = '';
    defaultHidden = false;

    get className() {
        const item = {
            half: this.$vuetify.breakpoint.smOnly, 
            xsCard: this.$vuetify.breakpoint.xsOnly, 
        }
        if(this.showDetail && !this.$vuetify.breakpoint.xsOnly) {
            return [this.expandClass, item];
        }
        return [this.defClass, item];
    }

    init(item : Partial<MetricInstance>, key : string, n : number, info : MetricOpts) {
        this.metricNameKey = (item as any).name
        Object.assign(this, item, info);
        if(info.hidden !== undefined) {
            this.defaultHidden = (Array.isArray(info.hidden) ? info.hidden[n - 1] : info.hidden) ?? false;
        }
        this.key = key;
        this.n = n;
        if(this.metricTypes) {
            this.currentMetricType = this.metricTypes[0]?.value;
        }
    }
}

