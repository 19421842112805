<template>
<v-card :width="width">
    <v-card-title v-if="label">
        <h5 v-text="label"/>
    </v-card-title>
    <v-card-text>
        <div v-for="field in def.fields" :key="field.name">
            <constant-select v-if="evalCond(field)" :type="field.def" :name="$td(field.label)" @input="update(field.name, $event)" :value="def.eval(value, field.name, field.default)" />
        </div>
    </v-card-text>
</v-card>
</template>

<script>

import _ from 'lodash'

export default {
    props: {
        'component': {}, 
        'value': {},
        'type': {},
        'label': String,
        'constant': Boolean,
        width: {},
    },
    computed: {
        def() {
            return this.$types.resolve(this.type);
        },
    },
    methods: {
        update(name, value) {
            this.$emit('input', {
                ...this.value,
                [name]: value,
            })
        },
        evalCond(field) {
            if(field.hidden) return false;
            if(field.cond) {
                if(!_.some(field.cond, and => _.every(and, c => {
                    switch(c.cond) {
                        case 'eq': return _.get(this.value, c.value) === c.operand;
                        case 'ne': return _.get(this.value, c.value) !== c.operand;
                        case 'em': return _.get(this.value, c.value) === undefined;
                    }
                })))
                    return false;
            }
            return true;
        }
    }
}
</script>

<style>

</style>
