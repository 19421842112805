import { Component, Prop, Watch, Vue, mixins } from "nuxt-property-decorator";
import type { WeatherDeviceManager } from "./device";
import { arrayToObj, getArrayToObj, isNum, units, floorDP, roundDP } from "./utils";
import moment from 'moment-timezone'

export interface ForecastWeatherCodition {
    id: number
    main: string
    description: string
    icon: string
}

export interface ForecastCurrent {
    dt: number
    clouds: number
    dew_point: number
    feels_like: number
    humidity: number
    pressure: number
    sunrise: number
    sunset: number
    temp: number
    uvi: number
    visibility: number
    weather: ForecastWeatherCodition[]
    wind_deg: number
    wind_gust: number
    wind_speed: number
}

export interface ForecastDaily {
    dt: number
    clouds: number
    dew_point: number
    "feels_like.day": number
    "feels_like.eve": number
    "feels_like.morn": number
    "feels_like.night": number
    humidity: number
    pop: number
    pressure: number
    sunrise: number
    sunset: number
    moonrise: number
    moonset: number
    moon_phase: number
    "temp.day": number
    "temp.eve": number
    "temp.max": number
    "temp.min": number
    "temp.morn": number
    "temp.night": number
    uvi: number
    weather: ForecastWeatherCodition[]
    wind_deg: number
    wind_gust: number
    wind_speed: number
    rain: number
}

export interface ForecastHourly {
    dt: number
    clouds: number
    dew_point: number
    feels_like: number
    humidity: number
    pop: number
    pressure: number
    "rain.1h": number
    temp: number
    uvi: number
    visibility: number
    weather: ForecastWeatherCodition[]
    wind_deg: number
    wind_gust: number
    wind_speed: number
}

const unitMap = {
    m: 'month',
    y: 'year',
    d: 'day',
    h: 'hour',
    w: 'week',
    M: 'minute'
} as const;

@Component
export class ForecastInstance extends Vue {
    parent : WeatherDeviceManager = null;
    type: string;

    current : ForecastCurrent = null;
    daily: ForecastDaily[] = [];
    hourly: ForecastHourly[] = [];

    // Somehow the dp is unused @jason
    formatUnit(type : string, dp?: number) {
        const info = units[type];
        const unit = info?.unit ? this.$store.getters.displaySetting[info.unitType] ?? info?.unit ?? '' : '';
        if(info?.unit && unit !== info.unit) {
            const u = info.units?.find(it => it.unit === unit);
            if(u) {
                return (value : number) => floorDP((value * (u.ratio || 1)) + (u.offset || 0), u.dp ?? info.dp ?? 1);
            }
        }
        return (value : number) => floorDP(value, info?.dp ?? 1)
    }

    formatUnitWithRoundDP(type: string, dp: number) {
        const info = units[type];
        const unit = info?.unit ? this.$store.getters.displaySetting[info.unitType] ?? info?.unit ?? '' : '';
        if(info?.unit && unit !== info.unit) {
            const u = info.units?.find(it => it.unit === unit);
            if(u) {
                return (value : number) => roundDP((value * (u.ratio || 1)) + (u.offset || 0), dp);
            }
        }
        return (value : number) => roundDP(value, dp)
    }

    formatValue(type : string, item : any, key: string, dp? : number) {
        let value : any = item?.[key];
        if(type === 'raw') return value;
        if(!isNum(<any>value)) return '-';

        const convert = this.formatUnit(type, dp);
        if(convert) value = convert(value);
        return value;
    }

    formatValueHardCodeUnit(type : string, item : any, key: string, dp? : number, unit? : string) {
        let value : any = item?.[key];
        if(type === 'raw') return value;
        if(!isNum(<any>value)) return '-';

        const convert = this.formatUnitWithRoundDP(type, dp);
        if(convert) value = convert(value);
        return value + unit;    
    }

    formatValueUnit(type : string, item : any, key: string, dp? : number) {
        const value = this.formatValue(type, item, key, dp);
        return value + (this.$store.getters.displaySetting[type] ?? units[type]?.unit ?? '')
    }

    getCurrent<K extends keyof ForecastCurrent>(key: K, type?: string) {
        return this.formatValue(type, this.current, key);
    }

    getDaily<K extends keyof ForecastDaily>(range : string, key : K, type? : string) {
        const n = parseFloat(range);
        const u = unitMap[range[range.length - 1]];
        const time = moment.tz(this.parent.timezone).startOf('day').add(n, u).unix();
        const end = time + 24*3600;
        const hourly = this.daily.find(it => it.dt >= time && it.dt < end);
        return this.formatValue(type, hourly, key);
    }

    getHourly<K extends keyof ForecastHourly>(range : string, key : K, type? : string) {
        const n = parseFloat(range);
        const u = unitMap[range[range.length - 1]];
        const time = moment.tz(this.parent.timezone).startOf('hour').add(n, u).unix();
        const end = time + 3600;
        const hourly = this.hourly.find(it => it.dt >= time && it.dt < end);
        return this.formatValue(type, hourly, key);
    }

    init(parent : WeatherDeviceManager, type : string) {
        this.parent = parent;
        this.type = type;
        if(this.parent.updateTime > moment().subtract(1, 'day').unix()) {
            this.loadData();
        }
    }

    async loadData() {
        await this.$feathers.service('weatherDevices/forecast').find({
            query: {
                device: this.parent.id,
                type: this.type,
            }
        });
    }

    latitude = null;
    longitude = null;

    update(data : any) {
        if(data.data.current) this.current = getArrayToObj(data.data.current[0], true)(data.data.current[1]);
        if(data.data.daily) {
            const func = getArrayToObj(data.data.daily[0], true);
            this.daily = data.data.daily[1].map(it => func(it));
        }
        if(data.data.hourly) {
            const func = getArrayToObj(data.data.hourly[0], true);
            this.hourly = data.data.hourly[1].map(it => func(it));
        }
        this.latitude = this.parent.device.latitude;
        this.longitude = this.parent.device.longitude;
    }

    @Watch("parent.device.latitude")
    @Watch("parent.device.longitude")
    onLocation() {
        if(this.latitude !== null || this.longitude !== null) {
            this.latitude = this.longitude = null;
            this.loadData();
        }
    }
}


