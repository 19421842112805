<template>
    <div>
        <div class="vue-dropzone dropzone dz-clickable" :class="{'dz-drag-hover': dragging}" @click.prevent.stop="addFile()"  @dragenter="dragenter" @dragover="dragover" @dragleave="dragleave" @drop="drop">
            <div v-if="!files.length" class="dz-default dz-message"><span v-t="'uploader.drag'"></span></div>
            <div @click.prevent.stop="selectFile(file)" v-for="file in files" :key="file.id" :class="{'dz-preview': true, 'dz-processing': file.processing, 'dz-file-preview': !file.thumbnail || editingItem === file, 'dz-image-preview': !!file.thumbnail && editingItem !== file, 'dz-success': file.success, 'dz-complete': file.complete, 'dz-error': file.error}">
                <div class="dz-image" style="width:200px;height:200px">
                    <img data-dz-thumbnail="" :src="$imgHelper.thumbURL(file)" />
                </div>
                <div class="dz-details">
                    <div class="dz-size"><span data-dz-size=""><strong>{{file.size | smartSize(true, false)}}</strong> {{file.size | smartSize(false, true)}}</span></div>
                    <div class="dz-filename">
                        <input v-focus @keyup.enter="saveName(file)" type="text" v-model="file.name" v-if="editingItem === file" @blur="saveName(file)" />
                        <span v-else @click.prevent.stop="editingItem = file" data-dz-name="">{{file.name}}</span>
                    </div>
                </div>
                <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress="" :style="{width:(file.progress * 100) + '%'}"></span></div>
                <div class="dz-error-message"><span data-dz-errormessage="">{{file.error}}</span></div>
                <div class="dz-success-mark"><i class="material-icons">done</i></div>
                <div class="dz-error-mark"><i class="material-icons">error</i></div>
                <div class="dz-remove" data-dz-remove="">
                    <v-btn @click.prevent.stop="deleteFile(file)"><v-icon>delete</v-icon></v-btn>
                    <v-btn @click.stop="false" :href="`${url}/${file.id}/download`"><v-icon>get_app</v-icon></v-btn>
                </div>
            </div>
        </div>
        <v-dialog v-model="model" fullscreen transition="v-dialog-bottom-transition" :overlay="true">
            <div class="card">
                <div class="header">
                    <v-btn icon="icon" @click.native="model = false" light>
                        <v-icon>close</v-icon>
                    </v-btn>
                </div>
                <!--div class="content">
                    <v-carousel ref="carousel" :interval="100000000">
                        <v-carousel-item v-for="(item,i) in mfiles" :key="item.id" src="">
                            <slot>
                                <template v-if="!isImage(item)">
                                    <video :src="`${url}/${item.id}`" controls />
                                </template>
                                <template v-else>
                                    <img :src="`${url}/${item.id}`" />
                                </template>
                            </slot>
                        </v-carousel-item>
                    </v-carousel>
                </div-->
            </div>
        </v-dialog>
    </div>
</template>

<script>

import _ from 'lodash'
import Vue from 'vue';
import uuid from 'uuid/v4'

export default {
    props: {
        'url': { type: String, default: '' },
        'dir': { type: String, default: '' },
        'type': { type: String, default: 'image/*' },
        value: {},
    },

    data() {
        return {
            realFiles: null,
            editingItem: null,
            model: false,
            dragging: false,
        };
    },

    async mounted() {
        await this.loadList();
    },

    computed: {
        mfiles() {
            return this.files.filter(file => file.mime.match(/(image|video)\/.*/));
        },

        inputFiles() {
            return (this.value || []).map(info => _.assign({
                success: true,
                complete: true,
                processing: true,
                error: null,
                progress: 1,
            }, info));
        },

        files() {
            return this.realFiles || this.inputFiles
        },
    },

    methods: {
        async loadList() {
            this.realFiles = this.inputFiles;
        },

        async addFile() {
            const file = document.createElement('input');
            file.style.display = 'none';
            file.type = 'file';
            file.accept = this.type;
            document.body.append(file);
            file.click();
            await new Promise((resolve) => file.onchange = resolve);
            if (file.files.length == 0) return;
            const mfile = file.files[0];
            await this.uploadFile(mfile);
        },

        async uploadFile(mfile) {
            const info = {
                name: mfile.name,
                size: mfile.size,
                mime: mfile.type,
                thumb: null,
                id: uuid(),
                success: false,
                complete: false,
                processing: true,
                error: null,
                progress: 0
            };
            this.files.push(info);

            var reader = new FileReader();
            const rfile = new Promise((resolve) => reader.onload = resolve);
            reader.readAsDataURL(mfile);
            const e = await rfile;

            var img = new Image();
            img.crossOrigin = 'anonymous';
            const rimg = new Promise((resolve) => img.onload = resolve);
            img.src = e.target.result;
            await rimg;

            var data = new FormData();
            data.append('file', mfile, mfile.name);

            try {
                const response = await this.$feathers.post(`attachments/upload/${this.dir}`, data, {
                    onUploadProgress: (progressEvent) => {
                        info.progress = progressEvent.loaded / progressEvent.total;
                    }
                });

                const rinfo = (response.data || {}).info || {};
                _.assign(info, rinfo);
                info.success = true;
                info.complete = true;
                info.progress = 1;
            } catch (e) {
                info.error = e.message;
                info.complete = true;
            }

            this.$emit('input', this.files.filter(s => s.success));
        },

        async selectFile(file) {
            const idx = this.mfiles.indexOf(file);
            /*if(idx !== -1) {
                this.model = true;
                await Vue.nextTick();
                this.$refs.carousel.next();
                await Vue.nextTick();
                this.$refs.carousel.select(idx);
            }*/
        },

        async deleteFile(item) {
            /*await this.$api.delete({
                url: `${this.url}/${item.id}`,
                prompt: true,
                message: '檔案已刪除'
            });*/
            this.files.splice(this.files.indexOf(item), 1);

            this.$emit('input', this.files);
            
        },

        async saveName(item) {
            this.editingItem = null;
            /*await this.$api.put({
                url: `${this.url}/${item.id}`,
                data: {
                    name: item.name
                },
                prompt: true,
                message: '已更新檔案名'
            });*/

            this.$emit('input', this.files);            
        },

        isImage(file) {
            return file.mime.match(/image\/.*/);
        },


        dragenter(e) {
            e.preventDefault();
            e.stopPropagation();
            if(e.dataTransfer.types.includes('Files')) {
                if(!this.dragging) {
                    this.dragging = true;
                    this.$emit('beginDrag');
                }
                e.dataTransfer.dropEffect = "copy"
                return;
            }
            if(this.dragging) {
                this.dragging = false;
                this.$emit('endDrag');
            }
            e.dataTransfer.dropEffect = "none"
        },
        dragover(e) {
            e.preventDefault();
            e.stopPropagation();
            if(e.dataTransfer.types.includes('Files')) {
                if(!this.dragging) {
                    this.dragging = true;
                    this.$emit('beginDrag');
                }
                e.dataTransfer.dropEffect = "copy"
                return;
            }
            if(this.dragging) {
                this.dragging = false;
                this.$emit('endDrag');
            }
            e.dataTransfer.dropEffect = "none"
        },
        async drop(e) {
            if(!this.dragging) return;
            e.preventDefault();
            e.stopPropagation();
            this.dragging = false;
            if(e.dataTransfer.types.includes('Files')) {
                const imgs = _.filter(e.dataTransfer.files, file => file.type.match('^image/'));
                await Promise.all(imgs.map(img => this.uploadFile(img)));
            }
            this.$emit('endDrag');
        },
        dragleave(e) {
            if(!this.dragging) return;
            e.preventDefault();
            e.stopPropagation();            
            this.dragging = false;
            this.$emit('endDrag');
        },



    },
    watch: {
        url() { this.loadList() },
        dir() { this.loadList() },
    }
}

</script>

<style src="~/node_modules/dropzone/dist/dropzone.css"></style>
<style lang="less">

.dropzone {
    &.dz-clickable {
        * {
            cursor: pointer;
        }
    }
}

.dropzone.dz-drag-hover {
    border-color: lightblue;
}

.vue-dropzone {
    border: 2px solid #E5E5E5;
    font-family: 'Roboto', sans-serif;
    letter-spacing: 0.2px;
    color: #777;
    transition: background-color .2s linear;
    &:hover {
        background-color: #F6F6F6;
    }
    i {
        color: #555;
    }
    .dz-preview {
        .dz-image {
            border-radius: 0px;
            z-index: 1;
            &:hover {
                img {
                    transform: none;
                    -webkit-filter: none;
                }
            }
        }
        .dz-details {
            bottom: 0px;
            top: 0px;
            color: black;
            background-color: rgba(255, 255, 255, 0.4);
            border-radius: 20px;
            transition: opacity .2s linear;
            text-align: left;
            z-index: 2;
            .dz-filename span,
            .dz-size span {
                background-color: transparent;
            }
            .dz-filename:not(:hover) span {
                border: none;
            }
            .dz-filename:hover span {
                background-color: rgba(200, 200, 200, 0.8);
                border: none;
            }
        }
        .dz-progress .dz-upload {
            background: #cccccc;
        }
        .dz-remove {
            position: absolute;
            z-index: 3;
            color: white;
            top: inherit;
            bottom: 15px;
            text-decoration: none;

            &:hover {
                text-decoration: none;
            }
            opacity: 0;
        }
        &:hover {
            .dz-remove {
                opacity: 1;
            }
        }
        .dz-success-mark,
        .dz-error-mark {
            margin-left: -25px;
            margin-top: -22px;
            display: block;
            top: 50%;
            left: 50%;
            i {
                color: white!important;
                font-size: 5rem!important;
            }
        }
    }
}

.vue-dropzone .dz-preview .dz-details input {
    color: black;
    background: rgba(200, 200, 200, 0.8);
    border-radius: 3px;
}

.carousel video {
    margin-bottom: 100px;
    max-width: 100%;
    max-height: 100%;
}

.carousel img {
    max-width: 100%;
    max-height: 100%;
}

</style>