<template>
    <v-app
        v-action.fullscreen="fullscreen"
        ref="app"
        @touchstart.native="startMove($event)"
        @touchmove.native="canMove($event)"
        @touchend.native="endMove($event)"
        v-event.openDialog="openDialog"
        v-event.modalResult="modalResult"
        :style="`${$vuetify.theme.dark ? 'background-color: #707070 !important;' : 'background-color: #175566 !important;'}`"
        :class="['gradientBg', { blurView: showDialog, 'full-page-app': fullPage }]"
    >
        <v-snackbar top v-model="error" color="white" :timeout="100000">
            <v-row no-gutters justify="center" class="pt-4 px-4">
                <v-col align-self="center" align="center">
                    <div class="greyText--text subtitle-2 text-center">{{ errorMessage }}</div>
                    <v-btn class="mt-2 ma-0" text color="red lighten-2" @click.native="error = false" v-t="'basic.close'"></v-btn>
                </v-col>
            </v-row>
        </v-snackbar>

        <v-snackbar top v-model="success" color="white">
            <v-row no-gutters justify="center" class="pt-4 px-4">
                <v-col align-self="center" align="center">
                    <div class="greyText--text subtitle-2 text-center">{{ successMessage }}</div>
                    <v-btn class="mt-2 ma-0" text color="green" @click.native="success = false" v-t="'basic.close'"></v-btn>
                </v-col>
            </v-row>
        </v-snackbar>

        <v-dialog v-for="dialog in dialogs" :key="dialog.key" v-model="dialog.show" v-bind="dialog.modal">
            <template v-if="dialog.loading">
                <v-card>
                    <v-card-text style="text-align: center">
                        <v-progress-circular indeterminate color="blue"></v-progress-circular>
                    </v-card-text>
                </v-card>
            </template>
            <template v-else>
                <component :is="dialog.component" v-bind="dialog.props" :modalId="dialog.key" />
            </template>
        </v-dialog>

        <v-navigation-drawer
            dark
            height="100%"
            :style="`padding: 0px !important; background-color: var(--v-greyInactive-base);}`"
            :width="200"
            v-model="drawer"
            app
            right
        >
            <v-layout column fill-height class="greyInactive">
                <v-list>
                    <v-list-item
                        v-for="(item, index) in items"
                        :key="index"
                        :to="item.href"
                        :target="item.target ? item.target : ''"
                        style="min-height:40px;"
                        :class="{ bottomBorder2: index === 6, aboutBorder: index === 0 }"
                    >
                        <v-list-item-title class="menuText greyText--text pb-1">{{ item.title }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-layout>
        </v-navigation-drawer>

        <v-app-bar class="top_bar" height="72" v-if="!$store.state.fullscreen" fixed app flat rounded>
            <v-row no-gutters align="center" class="fill-height fill-width top_bar_container px-sm-5 py-3 justify-stretch">
                <v-row no-gutters  align="center" class="fill-height justify-space-between" style="position:relative;">
                    <v-col cols="auto">
                        <v-btn
                            text
                            depressed
                            :style="`margin-left:${$vuetify.breakpoint.xsOnly ? '6px' : '0px'};`"
                            class="px-0"
                            :ripple="false"
                            to="/dashboard"
                        >
                            <img src="~/assets/images/PWL_logo.png" :height="$vuetify.breakpoint.mdAndUp ? '40px' : '25px'" />
                            <img
                                src="~/assets/images/horizontal-logo.png"
                                style="margin-left: 12px;"
                                :height="$vuetify.breakpoint.mdAndUp ? '25px' : '18px'"
                            />
                        </v-btn>
                    </v-col>

                    <v-col cols="auto" justify="end">
                        <div class="d-flex tab_bar" justify="end">
                            <div v-if="$vuetify.breakpoint.mdAndUp" class="align-self-center">
                                <v-btn
                                    v-for="tab in tabs"
                                    :key="tab.href"
                                    text
                                    depressed
                                    :ripple="false"
                                    class="text-capitalize px-0 mx-3"
                                    :to="tab.href"
                                >
                                    <div>
                                        <div class="mb-2">
                                            {{ tab.title }}
                                        </div>
                                        <div
                                            class="tab-underline"
                                            :style="`${$route.path === tab.href ? 'background-color:#fff  !important;' : ''}`"
                                        />
                                    </div>
                                </v-btn>
                            </div>
                            <div v-else>
                                <v-menu offset-y>
                                    <template v-slot:activator="{ on }">
                                        <v-btn
                                            @click="drawer = false"
                                            text
                                            dark
                                            v-on="on"
                                            :ripple="false"
                                            :height="$vuetify.breakpoint.mdAndUp ? 52 : 40"
                                        >
                                            <div>
                                                <v-icon :size="$vuetify.breakpoint.mdAndUp ? 32 : 24">fas fa-bars</v-icon>
                                            </div>
                                        </v-btn>
                                    </template>
                                    <v-list :style="'background-color: #18687ce0 !important;'">
                                        <v-list-item
                                            v-for="(item, index) in tabs"
                                            :key="index"
                                            :target="item.target ? item.target : ''"
                                            :to="item.href"
                                            style="min-height:40px;"
                                            :class="{ bottomBorder2: index === 6, aboutBorder: index === 0 }"
                                        >
                                            <v-list-item-title class="menuText greyText--text pb-1">{{ item.title }}</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </div>
                            <div
                                class="text-none py-1 px-xs-0 px-3  rounded-xl d-flex justify-center align-center position-relative"
                                :style="`background-color: #00BCE588; height: ${$vuetify.breakpoint.mdAndUp ? 48 : 32}`"
                            >
                                <v-menu offset-y>
                                    <template v-slot:activator="{ on }">
                                        <v-btn fab icon depressed text class="align-center mr-1" height="32" width="32" v-on="on">
                                            <v-img
                                                v-if="$vuetify.breakpoint.mdAndUp"
                                                :src="userAvatar ? userAvatar : require('~/assets/images/avatar.svg')"
                                                height="32"
                                                width="32"
                                                style="border-radius: 50%;"
                                                contain
                                            />
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item
                                            v-for="(item, index) in items"
                                            :key="index"
                                            :to="item.href"
                                            :target="item.target ? item.target : ''"
                                            style="min-height:40px;"
                                            :class="{ bottomBorder2: index === 6, aboutBorder: index === 0 }"
                                        >
                                            <v-list-item-title class="menuText greyText--text pb-1">{{ item.title }}</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>

                                <v-menu offset-y>
                                    <template v-slot:activator="{ on }">
                                        <v-btn fab icon depressed text class="align-center" v-on="on" height="40" width="40">
                                            <v-badge
                                                :content="activeAlerts.length || ''"
                                                :color="hasAlerts ? 'alertBadges' : 'transparent'"
                                                overlap
                                                dot
                                            >
                                                <v-img :src="require('~/assets/images/bell.svg')" contain />
                                            </v-badge>
                                        </v-btn>
                                    </template>
                                    <v-card
                                        max-height="400px"
                                        :min-width="$vuetify.breakpoint.mdAndUp ? '373px' : '300px'"
                                        :style="'background-color: #18687ce0 !important;'"
                                        class="pa-2 rounded-lg"
                                    >
                                        <v-list v-for="(device, index) in alertDevices" :key="index" class="rounded-lg overflow-hidden">
                                            <v-list-item
                                                v-for="(item, idx) in device.activeAlerts"
                                                :key="idx"
                                                class="px-4 bottomBorderDark"
                                                justify="center"
                                            >
                                                <v-list-item-content>
                                                    <v-list-item-title class="greyText--text">
                                                        <v-row no-gutters align="center">
                                                            <v-col cols="auto">
                                                                <v-img :src="device.deviceTypeInfo.icond" contain width="40" height="40" />
                                                            </v-col>
                                                            <v-row
                                                                no-gutters
                                                                class="d-flex flex-column mr-1 ml-2 mr-md-2 ml-md-3"
                                                                :style="'gap:12px'"
                                                            >
                                                                <div class="white--text" v-if="device.device.name || device.device.wsid">
                                                                    {{ device.device.name || device.device.wsid || "" }}
                                                                </div>
                                                                <div
                                                                    v-if="
                                                                        item.triggerEvent == 'Lost data upload over 30 minutes' ||
                                                                            item.triggerEvent == 'Lost link over 30 minutes'
                                                                    "
                                                                >
                                                                    {{ formatGroup(item.itemName) }}:
                                                                    {{ formatTrigger(item.itemName, item.triggerEvent) }}
                                                                </div>
                                                                <div v-else>
                                                                    {{ formatGroup(item.itemName) }}:
                                                                    {{ formatTrigger(item.itemName, item.triggerEvent) }}
                                                                    {{
                                                                        item.condition
                                                                            ? $t("alertCard.is") + " " + $t(`alertCard.${item.condition}`)
                                                                            : ""
                                                                    }}
                                                                    {{ item.value }} {{ item.unit }}
                                                                </div>
                                                                <div>{{ $dateTime(item.lastTrigger) }}</div>
                                                            </v-row>
                                                            <v-icon color="grey0">chevron_right</v-icon>
                                                        </v-row>
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                    </v-card>
                                </v-menu>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </v-row>
        </v-app-bar>

        <v-main
            :class="['gradientBg', { 'fill-height': fullPage }, { 'pt-0': $route.path === '/world' }]"
            style="background: linear-gradient(180deg, #1A7E97 0%, #1E6D81 49.48%, #21616D 100%) !important;"
        >
            <v-container
                fluid
                style="height:100%; padding:0px; overflow: hidden; background: linear-gradient(180deg, #1A7E97 0%, #1E6D81 49.48%, #21616D 100%);"
                class="gradientBG"
            >
                <transition name="fade" mode="out-in">
                    <nuxt></nuxt>
                </transition>
            </v-container>
        </v-main>

        <v-fab-transition>
            <v-btn @click="exitFullscreen" v-if="$store.state.fullscreen" dark fab fixed bottom right>
                <v-icon>fullscreen_exit</v-icon>
            </v-btn>
        </v-fab-transition>
    </v-app>
</template>

<script>
import _ from "lodash";
import Vue from "vue";
import moment from "moment";
import { mapGetters } from "vuex";
import uuid from "uuid/v4";
import { alerts, AlertOpts, AlertKey, AlertType, deviceTypes } from "~/plugins/weatherManager/deviceTypes";

export default {
    data() {
        return {
            drawer: false,
            mini: false,
            lang: false,
            showDialog: false,
            dialogs: [],
            testBanner: TEST_BANNER,
            stationID: "",

            popupNotifications: [],
        };
    },
    head() {
        return {
            htmlAttrs: {
                style: this.fullPage
                    ? `overflow: hidden; height: 100%; --top-height: ${this.$vuetify.breakpoint.mdAndUp ? 52 : 40}px;`
                    : `overflow: auto; --top-height: ${this.$vuetify.breakpoint.mdAndUp ? 52 : 40}px;`,
            },
            bodyAttrs: {
                style: (() => {
                    const string1 = this.fullPage ? `overflow: hidden; height: 100%; ` : "";
                    const string2 = string1 + "background-color: #1a7e97;";
                    return string2;
                })(),
            },
            chatManager: null,
        };
    },
    computed: {
        userAvatar() {
            if (!this.$store && !this.$store.state.user) return null;
            const id = this.$store.state.user.avator;
            if (id) return this.$image ? this.$image(id) : `${API_URL}/api/attachments/${id}.webp`;
            else return null;
        },
        fullPage() {
            return this.$store.state.fullPage;
        },
        mfullscreen() {
            return this.$store.state.fullscreen;
        },
        tabs() {
            return [
                {
                    href: "/dashboard",
                    title: this.$t("pages.dashboard"),
                },
                {
                    href: "/station",
                    title: this.$t("pages.station"),
                },
                {
                    href: "/world",
                    title: this.$t("pages.world"),
                },
                {
                    href: "/settings",
                    title: this.$t("pages.settings"),
                },
            ];
        },
        items() {
            return this.$store.getters["userId"]
                ? [
                      {
                          href: "/dashboard",
                          title: this.$t("pages.dashboard"),
                          action: "dashboard",
                      },
                      {
                          href: "/account",
                          title: this.$t("pages.account"),
                          action: "exit_to_app",
                      },
                      {
                          href: "/station",
                          title: this.$t("pages.device"),
                          action: "home",
                      },
                      {
                          href: "/settings/displaySetting",
                          title: this.$t("pages.unit"),
                          action: "vpn_key",
                      },
                      {
                          href: "/settings/alert",
                          title: this.$t("pages.alert"),
                          action: "create",
                      },
                      {
                          href: "/server",
                          title: this.$t("pages.server"),
                          action: "create",
                      },
                      {
                          href: "/export",
                          title: this.$t("pages.dataExport"),
                          action: "create",
                      },
                      {
                          href: "/help",
                          title: this.$t("footer.help"),
                          action: "create",
                      },
                      {
                          href: "/faq",
                          title: this.$t("pages.faq"),
                          action: "create",
                      },
                      {
                          href: "/logout",
                          title: this.$t("pages.logout"),
                          action: "create",
                      },
                  ]
                : [
                      {
                          href: "/help",
                          title: this.$t("footer.help"),
                          action: "create",
                      },
                      {
                          href: "/login",
                          title: "Log In",
                          action: "create",
                      },
                  ];
        },
        dark() {
            return this.$store.state.dark;
        },
        error: {
            get() {
                return !!this.$store.state.error;
            },
            set(v) {
                if (!v) {
                    this.$store.commit("SET_ERROR");
                }
            },
        },

        errorMessage() {
            return this.$store.state.error;
        },

        success: {
            get() {
                return !!this.$store.state.success;
            },
            set(v) {
                if (!v) {
                    this.$store.commit("SET_SUCCESS");
                }
            },
        },

        successMessage() {
            return this.$store.state.success;
        },

        alertDevices() {
            return this.$weatherManager.devices.filter((device) => device.activeAlerts && device.activeAlerts.length);
        },
        activeAlerts() {
            return this.alertDevices?.flatMap((device) => device.activeAlerts) ?? [];
        },
        hasAlerts() {
            return this.activeAlerts?.length > 0;
        },
        groups() {
            return _.flatMap(
                _.flatMap(this.devices, (device) => device.deviceTypeInfo.alerts),
                (info) => {
                    const item = typeof info === "string" ? { key: info } : info;
                    const alert = alerts.find((it) => it.key === item.key);
                    if (!alert) return [];
                    // AlertType
                    return _.times(item.count ?? 1, (n) => ({
                        ...alert,
                        n: item.count ? n + 1 : undefined,
                        key: item.key + (item.count ? n + 1 : ""),
                    }));
                }
            );
        },

        ...mapGetters(["attributes", "role"]),
    },
    components: {},
    async beforeMount() {
        console.log(this.$route.path);

        await this.$store.dispatch("initLang", {
            ctx: this,
        });
        const user = this.$store.state.user;
        if (!user || !user._id) {
            if (this.$route.name === "help" || this.$route.name === "faq") return;
            this.$router.replace("/login");
        } else if (!user.verified) {
            if (this.$route.name === "help" || this.$route.name === "faq") return;
            this.$router.replace("/signup/verify");
        }

        this.$vuetify.theme.dark = false;
        // if(this.$messageQueue.supported) {
        //     let d = await this.$messageQueue.ns('auth').call('getTheme');
        //     this.$vuetify.theme.dark = d;
        // } else {
        //     if (this.$store.getters.displaySetting.bgColor == 'Light') {
        //         this.$vuetify.theme.dark = false;
        //     } else {
        //         this.$vuetify.theme.dark = true;
        //     }
        // }

        var ua = window.navigator.userAgent;
        var msie = ua.indexOf("MSIE ");
        if (msie > 0) {
            // If Internet Explorer, return version number
            alert("Please use Chrome or Firefox instead");
        }

        try {
            this.$chatManager.init();
            this.chatManager = this.$chatManager; //make reactive
            await this.$chatManager.registerPush(true);
        } catch (e) {
            console.log(e.message);
        }
    },
    async mounted() {
        document.addEventListener("fullscreenchange", this.fullscreenChange);
        document.addEventListener("webkitFullscreenchange", this.fullscreenChange);
        document.addEventListener("gesturestart", this.gesture);

        this.updateFullPage();
    },
    beforeDestroy() {
        document.removeEventListener("fullscreenchange", this.fullscreenChange);
        document.removeEventListener("webkitFullscreenchange", this.fullscreenChange);
        document.removeEventListener("gesturestart", this.gesture);
    },
    methods: {
        async checkID() {
            if (await this.$weatherManager.openDeviceByWSID(this.stationID)) {
                return;
            }
            this.$store.commit("SET_ERROR", this.$t("dashboard.deviceNotFound"));
        },
        gesture(e) {
            e.preventDefault();
        },
        fullscreen() {
            if (document.fullscreenElement) {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                    this.$store.commit("SET_FULLSCREEN", false);
                }
                return;
            }
            if (document.webkitFullscreenElement) {
                if (document.webkitFullscreenElement) {
                    document.webkitExitFullscreen();
                    this.$store.commit("SET_FULLSCREEN", false);
                }
            }
            if (!window.navigator.standalone) {
                const elem = this.$refs.app.$el;
                const rfq = elem.requestFullscreen || elem.webkitRequestFullscreen || elem.mozRequestFullScreen || elem.msRequestFullscreen;
                if (!rfq) return;
                rfq.call(elem);
            }
            this.$store.commit("SET_FULLSCREEN", true);
        },

        exitFullscreen() {
            if (document.fullscreenElement && document.exitFullscreen) document.exitFullscreen();
            if (document.webkitFullscreenElement && document.webkitExitFullscreen) document.webkitExitFullscreen();
            this.$store.commit("SET_FULLSCREEN", false);
        },

        fullscreenChange(event) {
            this.$store.commit("SET_FULLSCREEN", event.fullscreen);
        },

        startMove(event) {
            if (event.targetTouches.length === 1) {
                this._clientX = event.targetTouches[0].clientX;
                this._clientY = event.targetTouches[0].clientY;
            }
        },

        canMove(event) {
            if (this.$store.state.fullPage) {
                event.stopPropagation();
                if (event.targetTouches.length === 1) {
                    if (
                        this.hasScrollable(
                            event.target,
                            event.targetTouches[0].clientX - this._clientX,
                            event.targetTouches[0].clientY - this._clientY
                        )
                    ) {
                        this._scrolling = true;
                    } else if (!this._scrolling) {
                        event.preventDefault();
                    }
                }
            }
        },

        hasScrollable(target, clientX, clientY) {
            if (target.classList.contains("scrollable") || target.classList.contains("v-data-table__wrapper")) {
                if (target.scrollHeight > target.clientHeight && Math.abs(clientY) > Math.abs(clientX)) {
                    if ((target.scrollTop > 0 || clientY < 0) && (target.scrollHeight - target.scrollTop > target.clientHeight || clientY > 0)) {
                        return true;
                    }
                }
                if (target.scrollWidth > target.clientWidth && Math.abs(clientX) > Math.abs(clientY)) {
                    if ((target.scrollLeft > 0 || clientX < 0) && (target.scrollWidth - target.scrollLeft > target.clientWidth || clientX > 0)) {
                        return true;
                    }
                }
            }
            return target.parentElement && this.hasScrollable(target.parentElement, clientX, clientY);
        },

        endMove(event) {
            if (event.targetTouches.length === 0) {
                this._scrolling = false;
            }
        },

        updateFullPage() {
            if (process.server) return;
            const nuxt = document.getElementById("__nuxt");
            if (this.fullPage) nuxt.classList.add("fill-height");
            else nuxt.classList.remove("fill-height");

            const layout = document.getElementById("__layout");
            if (this.fullPage) layout.classList.add("fill-height");
            else layout.classList.remove("fill-height");
        },

        openDialog(e) {
            let item;
            const hide = (result, err) => {
                if (!item._show) return;
                item._show = false;

                if (err) {
                    e.reject(err);
                } else {
                    e.resolve(result);
                }

                setTimeout(() => {
                    const idx = this.dialogs.indexOf(item);
                    idx !== -1 && this.dialogs.splice(idx, 1);
                }, 500);
            };
            item = {
                key: uuid(),
                props: e.props,
                loading: false,
                _show: true,
                get show() {
                    return this._show;
                },
                set show(v) {
                    if (!v) {
                        hide();
                    }
                },
                hide,
            };
            if (e.component.then) {
                item.loading = true;
                item.modal = { maxWidth: "100px", persistent: true };
                e.component
                    .then((c) => {
                        item.loading = false;
                        item.modal = e.modal;
                        item.component = c.default || c;
                    })
                    .catch((e) => {
                        console.warn(e);
                        hide(undefined, e);
                    });
            } else {
                item.component = e.component;
                item.modal = e.modal;
            }
            this.dialogs.push(item);
        },

        modalResult(e) {
            const item = this.dialogs.find((it) => it.key === e.id);
            if (item) {
                item.hide(e.result);
            }
        },
        formatGroup(groupKey) {
            const gp = this.groups.find((it) => it.key === groupKey);
            return gp ? this.$t(gp.name, { n: gp.n }) : "-";
        },

        formatTrigger(groupKey, metricKey) {
            const gp = this.groups.find((it) => it.key === groupKey);
            const metric = gp?.metrics?.find((it) => it.key === metricKey);
            return metric ? this.$t(metric.name) : "-";
        },
    },
    watch: {
        fullPage(val) {
            this.updateFullPage();
        },
        mfullscreen(val) {
            if (val) this.drawer = false;
        },
        drawer(val) {
            if (val && this.$vuetify.breakpoint.smAndUp) {
                Vue.nextTick(() => {
                    this.drawer = false;
                });
            }
        },
        "$weatherManager.device"(v) {
            if (!v) return;
            if (v.owned) this.stationID = "";
            else this.stationID = v.device.wsid;
        },
    },
};
</script>

<style>
.v-navigation-drawer .v-list__tile::before {
    content: "";
    display: block;
    width: 0;
    height: 1px;
    position: absolute;
    bottom: 8px;
    left: 40px;
    background: #626262;
    z-index: 1;
    opacity: 0;
    transition: all 0.7s cubic-bezier(0.55, 0, 0.1, 1);
}

.v-navigation-drawer .v-list__group__items .v-list__tile::before {
    left: 0px;
}

.v-navigation-drawer .v-list__tile--link:hover,
.v-navigation-drawer .v-list__group--link:hover {
    background: inherit !important;
}

.v-navigation-drawer .v-list__tile:hover::before {
    width: 100px;
    opacity: 1;
    transform: translate(20px);
}

a.v-list-item--active.v-list-item.v-list-item--link + a.v-list-item.v-list-item--link {
    border-radius: 0 0 0 0 !important;
}

.v-applicaiton {
    width: "100%";
}

.full-page-app {
    height: 100%;
}

.full-page-app .v-application--wrap {
    min-height: initial;
}

.bottomBorder {
    border-bottom: 1px solid;
}

.bottomBorderDark {
    border-bottom: 1px solid #0f3c45;
}
.v-card {
    border: none !important;
}

.v-application .display-4,
.v-application .display-3,
.v-application .display-2,
.v-application .display-1,
.v-application .headline,
.v-application .title,
.v-application .subtitle-1,
.v-application .subtitle-2,
.v-application .body-1,
.v-application .body-2,
.v-application .caption,
.v-application .overline {
    font-family: "Lato", sans-serif !important;
}
input {
    font-family: "Lato", sans-serif !important;
}
div {
    font-family: "Lato", sans-serif !important;
}
span {
    font-family: "Lato", sans-serif !important;
}
.upperText {
    text-transform: uppercase !important;
}

.innerCardpadding {
    padding-left: 8px;
    padding-right: 16px;
    padding-bottom: 16px;
}
</style>

<style scoped>
.v-text-field--outlined >>> .v-input__slot {
    min-height: 24px !important;
}

::v-deep .v-text-field input:-webkit-autofill,
::v-deep .v-text-field input:-webkit-autofill:hover,
::v-deep .v-text-field input:-webkit-autofill:focus,
::v-deep .v-text-field input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.v-toolbar {
    transition: 0.5s all;
}

.v-list__tile__action {
    justify-content: center;
    min-width: 28px;
    margin-right: 16px;
}

.v-list__tile__content ~ .v-list__tile__action:not(.v-list__tile__action--stack) {
    margin-right: 0px;
    margin-left: 16px;
    justify-content: center;
}

.scroll-area {
    position: relative;
    margin: auto;
    width: 100%;
    height: 100%;
    flex: 1;
    flex-grow: 1;
}

.v-navigation-drawer >>> .v-navigation-drawer__content {
    padding-top: constant(safe-area-inset-top) !important;
    padding-top: env(safe-area-inset-top) !important;
}

.theme--light.v-app-bar.v-toolbar.v-sheet {
    background: transparent;
}

.theme--light.v-sheet {
    background: #12414e88;
    box-shadow: none;
}

.top_bar {
    padding-top: constant(safe-area-inset-top) !important;
    padding-top: env(safe-area-inset-top) !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    height: calc(constant(safe-area-inset-top) + var(--top-height)) !important;
    height: calc(env(safe-area-inset-top) + var(--top-height)) !important;
    z-index: 99999;
}

.top_bar_container {
    border-radius: 36px;
    background-color: #1a7e97e0 !important;
}

.v-content {
    padding-top: calc(constant(safe-area-inset-top) + var(--top-height)) !important;
    padding-top: calc(env(safe-area-inset-top) + var(--top-height)) !important;
}

.top_bar >>> .v-toolbar__content {
    padding: 0px 0px !important;
}

.v-list {
    padding: 0px !important;
}

.bottomBorder2 {
    border-bottom: 1.5px solid #b7b7b7;
}

.aboutBorder {
    /* margin-left: 16px;
    margin-right: 16px; */
    /* padding: 0 24px !important; */
    border-bottom: 1.5px solid #b7b7b7;
}

.v-list-item {
    padding: 0px 24px;
    background-color: #12414e;
}

.v-btn::before {
    opacity: 0 !important;
}

.tab_bar >>> .v-btn {
    color: #91aec2;
}

.tab_bar >>> .v-btn--active {
    color: #fff;
    font-weight: 700;
}

.tab-underline {
    height: 1px !important;
    width: auto !important;
}

.v-list-item--active:focus::before {
    opacity: 0 !important;
}

.v-list-item:focus::before {
    opacity: 0 !important;
}

.v-list-item--active::before {
    background: #0fadcf;
}

.menuText {
    letter-spacing: 1.5px;
}

/* .top_bar >>> .v-btn:not(.v-btn--round).v-size--default{
    height: 60px !important;
} */

.tab_bar >>> .v-menu__content >>> .v-list-item {
    padding: 0 32px !important;
}

.app-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s all;
    padding-top: 8px;
    padding-bottom: 8px;
}

#search {
    min-height: 20px !important;
}

.gradientBg {
    background: linear-gradient(180deg, #1a7e97 0%, #1e6d81 49.48%, #21616d 100%);
}
</style>
