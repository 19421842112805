import { render, staticRenderFns } from "./PerfectScrollBar.vue?vue&type=template&id=b196c558&"
import script from "./PerfectScrollBar.vue?vue&type=script&lang=js&"
export * from "./PerfectScrollBar.vue?vue&type=script&lang=js&"
import style0 from "perfect-scrollbar/css/perfect-scrollbar.css?vue&type=style&index=0&lang=css&"
import style1 from "./PerfectScrollBar.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/domorelabs/Documents/domorelabs/weather/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b196c558')) {
      api.createRecord('b196c558', component.options)
    } else {
      api.reload('b196c558', component.options)
    }
    module.hot.accept("./PerfectScrollBar.vue?vue&type=template&id=b196c558&", function () {
      api.rerender('b196c558', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/PerfectScrollBar.vue"
export default component.exports