<template>
    <v-menu
        ref="menu"
        :close-on-content-click="false"
        v-model="menu"
        transition="scale-transition"
        offset-y
        :nudge-right="40"
        min-width="290px"
        :return-value.sync="date"
    >
        <template v-slot:activator="{ on }">
            <div v-on="on">
                <v-text-field
                    class="content white--text mt-1"
                    append-icon="event"
                    hide-details
                    solo
                    :label="label"
                    v-model="date"
                    readonly
                    :clearable="clearable"
                />
            </div>
        </template>
        <v-date-picker v-model="date" no-title scrollable color="primary">
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false">{{$t('basic.cancel')}}</v-btn>
            <v-btn text color="primary" @click="$refs.menu.save(date)">{{$t('basic.ok')}}</v-btn>
        </v-date-picker>
    </v-menu>
</template>

<script>

import moment from 'moment'

export default {
    props: {
        value: {},
        label: {},
        clearable: { type: Boolean, default: false, },
        local: { type: Boolean, default: false },
    },
    data () {
        return {
            menu: false
        }
    },
    computed: {
        date: {
            get () { 
                if(!this.value) return '';
                if(this.local) {
                    return moment(this.value || new Date()).format('YYYY-MM-DD')
                } else {
                    return moment(this.value || new Date()).utcOffset('+8').format('YYYY-MM-DD')
                }
            },
            set (val) { 
                this.$emit('input', val ? this.local ? moment(val, 'YYYY-MM-DD').toDate() : moment.parseZone(val, 'YYYY-MM-DD').toDate() : '')
            }
        }
    }
}
</script>

<style scoped>
.content {
    font-size: 18px;
}

.theme--light.v-btn:hover::before {
    opacity: 0;
}

.v-text-field {
    padding-top: 0px;
    margin-top: 0px;
}

.v-text-field >>> input {
    padding: 4px 0px 4px 0px;
}

.v-text-field.v-input--is-readonly >>> .v-input__slot::before,
.v-text-field.v-input--is-readonly >>> .v-input__slot::after {
    border-color: transparent !important;
}

.textBorder {
    border-bottom: 1.5px solid rgba(0,0,0,0.42);
}

.v-input--selection-controls {
    margin-top: 0px !important;
    padding-top: 0px !important;
}

.v-input >>> .v-input__slot {
    /* width: 200px !important; */
    min-height: 36px !important;
}

.v-btn:not(.v-btn--round).v-size--large {
    min-width: 90px;
}

.rightBorder {
    border-left: 1.5px solid rgba(0,0,0,0.42);
}

#export .v-text-field >>> .v-input__control {
    min-height: 36px;
}

.v-text-field >>> .v-icon {
    font-size: 19px;
}

.theme--dark.v-text-field >>> .v-input__slot  {
    background: #fff !important;
}

.theme--dark.v-input >>> input {
    color: #818181 !important;
}
</style>