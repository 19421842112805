<script>
import Chart from 'chart.js'
import { Bar } from 'vue-chartjs'
import 'chartjs-plugin-labels';
import _ from 'lodash'
import reactive from './reactive'

var originalDraw = Chart.controllers.bar.prototype.draw;
Chart.controllers.bar.prototype.draw = function(ease) {
  originalDraw.call(this, ease);
};

export default {
    extends: Bar,
    mixins: [reactive()],
    computed: {
        moptions() {
            return _.merge({
            }, this.options || {});
        }
    },
    mounted() {
        this.renderChart(this.chartData, this.moptions)
    }
}
</script>
