<template>
    <resize-observer @notify="handleResize" />
</template>


<script>
import { ResizeObserver } from 'vue-resize'
import _ from 'lodash'

import 'vue-resize/dist/vue-resize.css'

export default {
    components: {
        ResizeObserver,
    },

    props: {
        debounce: { 
            type: Number, 
            default: 50, 
            validator: function validator(v) {
                return v >= 0;
            } 
        }
    },

    methods: {
        handleResize() {
            this.emitResized();
        }
    },

    computed: {
        emitResized() {
            return _.debounce(() => {
                // console.log('resize', {
                //     width: this.$el.clientWidth,
                //     height: this.$el.clientHeight,
                // });
                return this.$emit("resized", {
                    width: this.$el.clientWidth,
                    height: this.$el.clientHeight,
                });
            }, this.debounce);
        }
    }
}
</script>
