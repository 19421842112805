
export default {
    "users": {
    },
    "appUsers": {
        fields: ["name"]
    },
    "doctorprofessions": {
        fields: ["name"]
    },
    "doctordistricts": {
        fields: ["name"]
    },
    "doctorgenders": {
        fields: ["name"]
    },
}
