var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.$props.tagname,
    {
      directives: [
        {
          name: "ps-scroll",
          rawName: "v-ps-scroll",
          value: { settings: _vm.settings, size: _vm.size },
          expression: "{settings, size}"
        }
      ],
      tag: "section",
      staticClass: "ps-container scrollable",
      on: {
        "ps-scroll-y": _vm.scrollHanle,
        "ps-scroll-x": _vm.scrollHanle,
        "ps-scroll-up": _vm.scrollHanle,
        "ps-scroll-down": _vm.scrollHanle,
        "ps-scroll-left": _vm.scrollHanle,
        "ps-scroll-right": _vm.scrollHanle,
        "ps-y-reach-start": _vm.scrollHanle,
        "ps-y-reach-end": _vm.scrollHanle,
        "ps-x-reach-start": _vm.scrollHanle,
        "ps-x-reach-end": _vm.scrollHanle
      }
    },
    [
      _c("div", [_vm._t("default")], 2),
      _vm._v(" "),
      _c("resize-sensor", { on: { resized: _vm.handleResize } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }