<template>
    <div class="highlights-item" style="width: 100%;">
        <fusioncharts
            :type="type"
            :width="width"
            :height="height"
            :containerbackgroundopacity="containerbackgroundopacity"
            :dataformat="dataformat"
            :datasource="datasource"
        ></fusioncharts>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {
            type: "angulargauge",
            width: "100%",
            height: "100%",
            containerbackgroundopacity: 0,
            dataformat: "json",
            datasource: {
                chart: {
                    caption: "",
                    // subcaption: "Last week",
                    lowerLimit: "0",
                    upperLimit: "15",
                    chartBottomMargin: "50",
                    theme: "fusion"
                },
                colorRange: {
                    color: [
                        {
                            minValue: "0",
                            maxValue: "5",
                            code: "#f0f8ff"
                        },
                        {
                            minValue: "6",
                            maxValue: "10",
                            code: "#62bcf5"
                        },
                        {
                            minValue: "11",
                            maxValue: "15",
                            code: "#29459e"
                        }
                    ]
                },
                dials: {
                    dial: [
                        {
                            value: "12",
                            bgColor: "#283569"
                            // borderColor: "#111111",
                            // borderThickness: "1",
                            // borderAlpha: "100"
                        }
                    ]
                }
            }
        };
    },
    methods: {},
    computed: {},
    watch: {
        // highlights: {
        //   handler: function() {
        //     this.datasource.colorrange.color[0].maxvalue = this.highlights.uvIndex.toString();
        //     this.datasource.colorrange.color[1].minvalue = this.highlights.uvIndex.toString();
        //     this.datasource.annotations.groups[0].items[0].text = this.highlights.uvIndex.toString();
        //   },
        //   deep: true
        // }
    }
};
</script>