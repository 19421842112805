

import _ from 'lodash'

import Vue from 'vue'

import newsStatus from './newsStatus'
import campaignStatus from './campaignStatus'
import adStatus from './adStatus'
import adType from './adType'
import productStatus from './productStatus'
import adminType from './adminType'
import exportFormat from './exportFormat'
import eventLocation from './eventLocation'
import gender from './gender'

export const framework = {
    newsStatus,
    campaignStatus,
    adStatus,
    adType,
    productStatus,
    adminType,
    exportFormat,
    eventLocation,
    gender
}

export class Type {

    constructor(system, { type, elementType, editor, def, name, label, args, subtype } = params) {
        this.system = system;
        this.args = args;
        this.subtype = subtype;
        if(editor) {
            this.hasType = false;
            this.type = {};
            this.editor = editor;
            this.name = editor;
            this.def = def;
            this.readName = label || name || editor;
        }
        else if(elementType) {
            this.hasType = true;
            this.type = { length: { type: 'number', label: { $t: 'edit_component.array.length' } } };
            this.elementType = elementType;
            this.editor = 'array'
            this.name = elementType.name + '[]';
            this.readName = {
                $t: 'edit_component.array.name',
                $ta: {
                    item: elementType.readName
                }
            };
        } else {
            this.hasType = true;
            this.type = _.mapValues(type.fields, v => typeof v === 'string' ? {
                type: v
            } : v);
            this.editor = type.editor || 'object';
            this.name = name || type.type || 'object'
            this.readName = label || type.label || name || type.name || type.type || 'object'
        }
    }

    resolve() {
        if(!this._fields) {
            this._fields = _.map(this.type, (v, k) => {
                const def = (typeof v.type === 'string') ? this.system.resolve(v.type, v) : new Type(this.system, { type: v.type });
                return {
                    def,
                    type: def.editor,
                    name: k,
                    label: v.label || k,
                    cond: v.cond,
                    default: v.default,
                    hidden: v.hidden,
                }
            })
        }
        return this._fields;
    }

    get fields() {
        return this.resolve();
    }

    get enums() {
        if(!this.$enums) {
            const type = this;
            this.$enums = (type && type.args && type.args.enum || type && type.enum || []).map((it, idx) => ({
                text: it.name || it.id || it || '' + idx,
                value: it.id || it || idx,
                avator: it.avator || undefined,
            }));
        }
        return this.$enums;
    }

    get enumDict() {
        if(!this.$enumDict) {
            this.$enumDict = _.fromPairs(_.map(this.enums, it => [it.value, it.text]));
        }
        return this.$enumDict;
    }

    asArray() {
        return (this._array || (this._array = new Type(this.system, { elementType: this })))
    }

    asGenerics(types) {
        return this;
    }

    asSubType(args) {
        if(!this.subtype) return this;
        return new Type(this.system, {
            editor: args.editor || this.editor,
            type: this.type, 
            elementType: this.elementType, 
            def: this.def, 
            name: this.name, 
            label: this.label,
            enum: this.enum,
            subtype: this.subtype,
            args
        })
    }

    create(def) {
        if(def !== undefined) return def;
        if(this.def !== undefined) return this.def;
        else if(this.editor === 'array') return [];
        const f = this.fields;
        return _.fromPairs(f.map(it => [it.name, it.def.create(it.default)]));
    }

    set(scope, path, value, parent, key) {
        if(!scope) {
            scope = this.create();
            Vue.set(parent, key, scope);
        }
        if(path === '') {
            Vue.set(parent, key, value);
            return;
        }
        const pidx = path.indexOf('.');
        const part = pidx === -1 ? path : part.substr(0, pidx);
        const remain = pidx === -1 ? '' : path.substr(pidx + 1);
        
        const isNumber = !Number.isNaN(Number.parseInt(part));
        if(isNumber && this.editor === 'array') {
            if(!(scope instanceof Array)) {
                console.warn('scope is non-array');
                return;
            } else {
                const idx = parseInt(part);
                if(idx < 0) {
                    console.warn('invalid index ' + sidx);
                    return;
                }
            }
            return this.elementType.set(scope[idx], remain, value, scope, idx);
        }
        if(!this.hasType) {
            console.warn(`${this.name}: accessing non-object`);
            return;
        } else {
            if(!this.type[part]) {
                console.warn('accessing undefined field', part);
                return;
            }
        }
        return this.system.resolve(this.type[part].type).set(scope[part], remain, value, scope, part);
    }

    evalType(path) {
        if(path === '') return this;
        const pidx = path.indexOf('.');
        const part = pidx === -1 ? path : path.substr(0, pidx);
        const remain = pidx === -1 ? '' : path.substr(pidx + 1);

        const isNumber = !Number.isNaN(Number.parseInt(part));
        if(isNumber && this.editor === 'array') {
            if(this.editor !== 'array') {
                console.warn('indexing non-array');
                return this.system.resolve('object');
            }
            return this.elementType.evalType(remain);
        }
        if(!this.hasType) {
            console.warn(`${this.name}: accessing non-object`);
            return this.system.resolve('object');
        } else if(!this.type[part]) {
            console.warn('accessing undefined field', part);
            return this.system.resolve('object');
        }
        return this.system.resolve(this.type[part].type).evalType(remain);
    }

    eval(scope, path, def) {
        if(path === '') {
            if(!scope) return this.create(def);
            return scope;
        }
        const pidx = path.indexOf('.');
        let inner;
        const part = pidx === -1 ? path : part.substr(0, pidx);
        const remain = pidx === -1 ? '' : path.substr(pidx + 1);

        const isNumber = !Number.isNaN(Number.parseInt(part));
        if(isNumber && this.editor === 'array') {
            let inner;
            if(!(scope instanceof Array)) {
                console.warn('scope is non-array');
                inner = this.elementType.create();
            } else {
                const idx = parseInt(part);
                if(idx < 0 || idx >= scope.length) {
                    console.warn('invalid index ' + sidx);
                    inner = this.elementType.create();
                }
                inner = scope[idx];
            }
            return this.elementType.eval(inner, remain, def);
        }

        if(!this.hasType) {
            console.warn(`${this.name}: accessing non-object`);
            inner = this.create();
        } else {
            if(!this.type[part]) {
                console.warn('accessing undefined field', part);
                return null;
            }
            if(typeof scope !== 'object') {
                scope = this.create();
            }
            inner = scope[part];
        }
        return this.system.resolve(this.type[part].type).eval(inner, remain, def);
    }

    assignableFrom(type) {
        if(this === type) return true;
        if(this.editor === 'array' && type.editor === 'array') {
            return this.system.resolve(this.elementType).assignableFrom(this.system.resolve(type.elementType));
        }
        if(this.hasType && type.hasType) {
            if(this.fields === type.fields) return true;
            return _.every(this.fields, field => {
                const other = type.fields.filter(s => s.name === field.name);
                return other[0] && field.def.assignableFrom(other[0].def);
            });
        }
        return this.editor === type.editor;
    }
}



export default class TypeSystem {
    constructor() {
        this.types = {};
        this.addType({ type: 'object', fields: {} })
        this.addType('number', new Type(this, { editor: 'number', def: 0 }))
        this.addType('range', new Type(this, { editor: 'range', def: { min: 0, max: 0 }, subtype: true }))
        this.addType('dim', new Type(this, { editor: 'dim', def: 'auto' }))
        this.addType('dim-num', new Type(this, { editor: 'dim', def: '0', args: { number: true } }))
        this.addType('boolean', new Type(this, { editor: 'boolean', def: false }))
        this.addType('string', new Type(this, { editor: 'string', def: '' }))
        this.addType('enum', new Type(this, { editor: 'enum', def: '', subtype: true }))
        this.addType('multiEnum', new Type(this, { editor: 'multiEnum', def: '', subtype: true }))
        this.addType('icon', new Type(this, { editor: 'icon', def: '' }))
        this.addType('css', new Type(this, { editor: 'css', def: {} }))
        this.addType('color', new Type(this, { editor: 'color', def: {} }))
    }

    addType(name, type) {
        if(!type) {
            type = name;
            name = type.type;
        }
        if(type instanceof Type) {
            this.types[name] = type;
        } else {
            if(type.inherit) {
                this.types[name] = this.resolveCore(type.inherit).asSubType(type);
            } else {
                this.types[name] = new Type(this, { type, name });
            }
        }   
    }

    resolve(type, args) {
        /*let ret = this.types[type];
        if(!ret) {
            ret = this.types[type] = this.resolveCore(type);
        }
        return ret;*/
        if(type instanceof Type) return type;
        else if(typeof type === 'object') {
            args = type;
            type = type.type;
        }
        if(typeof args === 'object' && Object.keys(args).length > 1) {
            return this.resolveCore(type).asSubType(args);
        }
        return this.resolveCore(type);
    }    

    resolveCore(type) {
        if(type instanceof Type) return type;
        if(typeof type === 'string') {
            type = type.trim();
            const array = type.match(/(.+?)\[\s*\]$/);
            if(array) {
                return this.resolve(array[1]).asArray();
            }
            //const generics = type.match(/(.+?)<(.+?)>/);
            //if(generics) {
            //    return this.resolve(generics[1]).asGenerics(generics[2]);
            //}
            const def = this.types[type];
            //console.log(def);
            if(!def) {
                console.warn(`Cannot resolve type "${type}"`);
                return this.resolve('object');
            }
            return def;
        } else {
            try {
                throw new Error();
            } catch(e) {
                console.warn(type, e.stack);
            }
            return this.resolve('object');
        }
    }
}

