import feathers, { MApplication, Paginated } from '@feathersjs/feathers';
import { AppApplication } from 'serviceTypes';
import fio from '@feathersjs/socketio-client';
import * as SocketIO from 'socket.io-client';
import authentication from '@feathersjs/authentication-client';
import Vue from 'vue';
import { EventEmitter } from 'events';
import decode from 'jwt-decode';
import axios from 'axios'
import { Context } from '@nuxt/types/app';
import { Store } from 'vuex';

declare global {
    const API_URL: string;
}

export default function (ctx : Context) {
    if(process.server) {
        return;
    }
    let $root: Vue;
    let $store : Store<any>
    let connected = false;
    let app : feathers.Application;
    let mapp : MApplication<AppApplication>;
    let socket : SocketIOClient.Socket;

    $store = ctx.store;
    if(process.browser) {
        $store.commit('INIT');
    }

    socket = SocketIO.connect(API_URL, {
        path: '/api/socket.io',
        transports: ['websocket']
    });

    socket.on('connect', function() {
        console.log('Socket connected');
        $store.commit('SET_CONNECTED', true);
        $root && $root.$emit('connected');
        mapp.emit('connected');
        connected = true;
    });
    
    socket.on('reconnect', function() {
        mapp.authenticated = false;
        mapp.authState = false;
    });
    
    socket.on('disconnect', function() {
        console.log('Socket disconnected');
        mapp.authenticated = false;
        mapp.authState = false;
        connected = false;
        $store.commit('SET_CONNECTED', false);
    });

    app = feathers()
        .configure(fio(socket, { timeout: 30000 }))
        .configure(authentication({ timeout: 30000 }));
    app.hooks({
        before: {
            async all(hook) {
                if(!connected) {
                    await new Promise(resolve => socket.once('connect', resolve));
                }
                if(hook.params.noAuth) return;
                if (!mapp.authState) await tryLogin();
            }
        },
        error: {
            async all(hook) {
                if (hook.error.className === 'not-authenticated') {
                    if (!(await tryLogin())) {
                        if (!mapp.notifyLogin) {
                            Vue.nextTick(async () => {
                                $root && $root.$router.replace('/login');
                            });
                            mapp.notifyLogin = true;
                        }
                        throw hook.error;
                    }
                    let args: Array<any> = [hook.params];
                    switch (hook.method) {
                        case 'find':
                        case 'patch':
                        case 'update':
                        case 'create':
                            args.unshift(hook.data);
                            break;
                    }
                    switch (hook.method) {
                        case 'patch':
                        case 'update':
                        case 'remove':
                            args.unshift(hook.id);
                            break;
                    }
                    hook.result = await hook.service[hook.method].apply(hook.service, args);
                }
            }
        }
    });

    mapp = <any>app;
    mapp.updateSession = <any>updateSession;
    mapp.setRoot = function(root) {
        $root = root;
    };
    mapp.updateProfile = async function(userId, cacheId) {
        try {
            const picUrl = `${API_URL}/api/users/pic/${userId}?${cacheId}`;
            const buffer = await axios.get(picUrl, {
                responseType: 'arraybuffer'
            })
            const pic = new Buffer(buffer.data).toString('base64');
            $store.commit('SET_USER_PROFILE', `data:${buffer.headers['content-type']};base64,${pic}`);
        } catch (e) {
            console.error(e);
        }
    };
    
    mapp.login = async function(strategy: string, props: any) {
        const l = grantLock();
        await l.promise;
        try {
            if (mapp.authenticated) {
                await logout();
            }
            const user = await login({
                strategy,
                ...props
            });
            // add avatar
            mapp.updateProfile(user._id, (<any>user).avatar).then(() => {});
        } finally {
            l.release();
        }
    };

    mapp.logout = async function() {
        const l = grantLock();
        await l.promise;
        try {
            await logout();
        } finally {
            l.release();
        }
    };

    const a = axios.create({ baseURL: API_URL + '/api' })

    mapp.post = <any>function(url, data, params) {
        const accessToken = $store.state.jwt;
        return a.post(`${url}?token=${encodeURIComponent(accessToken)}`, data, params);
    }

    ctx.app.$feathers = mapp;

    type LockQueue = { promise: Promise<void>; release: () => void; resolve?: () => void };
    let queues: LockQueue[] = [];
    function grantLock() {
        let r;
        const q : LockQueue = {
            promise: new Promise(resolve => {
                r = resolve;
            }),
            release: () => {
                const idx = queues.indexOf(q);
                idx !== -1 && queues.splice(idx, 1);
                if(queues.length) queues[0].resolve();
            }
        }
        q.resolve = r;
        const first = queues.length === 0;
        queues.push(q);
        if(first) {
            q.resolve();
        }
        return q;
    }
    
    async function tryLogin() {
        const l = grantLock();
        await l.promise;
        try {
            if (mapp.authState) return mapp.authenticated;
            mapp.authState = true;
            if ($store.state.jwt) {
                try {
                    await login({
                        strategy: 'jwt',
                        accessToken: $store.state.jwt
                    });
                    return true;
                } catch (e) {
                    if (e.message === 'Authentication timed out') {
                        // console.log('try next');
                        mapp.authState = false;
                    } else if (e.className === 'not-authenticated') {
                        await logout();
                    }
                    console.warn(e);
                    return false;
                }
            } else {
                return false;
            }
        } finally {
            l.release();
        }
    }

    async function updateSession(params? : typeof mapp._services.sessions._objectType, noAuth? : boolean) {
        try {
            const session = await mapp.service('sessions').create({
                _id: $store.state.settings.deviceId,
                ...params
            }, {
                noAuth
            })
            if(session._id !== $store.state.settings.deviceId) {
                $store.commit('SET_SETTINGS', {
                    deviceId: session._id,
                });
            }
            return session;
        } catch(e) {
            console.warn(e);
        }

    }
    
    async function login(credentials: any) {
        const resp = await (<any>app).passport.authenticateSocket(credentials, socket, 'emit');
        const token = decode(resp.accessToken);
        mapp.authState = true;
        mapp.authenticated = true;
        const user = await mapp.service('users').get(token.userId);
        $store.commit('SET_JWT', resp.accessToken);
        $store.commit('SET_USER', user);
        await updateSession({
            associateUser: true
        })
        mapp.emit('login');
    
        return user;
    }
    
    async function logout() {
        try {
            await (<any>app).passport.logoutSocket(socket, 'emit');
        } catch (e) {
            console.log('Logout error, try reconnect', e.message);
            socket.disconnect();
            socket.connect();
            mapp.authenticated = false;
            mapp.authState = false;
            mapp.notifyLogin = false;
        }
        $store.commit('SET_USER');
        $store.commit('SET_JWT', null);
        $store.commit('SET_USER_PROFILE', null);
        await updateSession({
            associateUser: true
        }, true)
    
    }
}

if (!Vue.prototype.hasOwnProperty('$feathers')) {
    Object.defineProperty(Vue.prototype, '$feathers', {
        get(this : Vue) { return this.$root.$options.$feathers; },
        enumerable: false
    });
}

declare module 'vue/types/options' {
    export interface ComponentOptions<
        V extends Vue,
        Data=DefaultData<V>,
        Methods=DefaultMethods<V>,
        Computed=DefaultComputed,
        PropsDef=PropsDefinition<DefaultProps>,
        Props=DefaultProps> {
            //$feathers?: MApplication<AppApplication>
            $feathers?: any
    }
}
