import { Component, Prop, Watch, Vue } from "nuxt-property-decorator";
import { MetricTrend } from './trend'
import type {
    WeatherDeviceManager,

} from './index'

import {
    units,
    getOptions,
    MetricType,
    CurrentDataType,
    isNum,
    floorDP,
    MetricUnitType,
    roundDP,
} from './utils'
import { MetricRange } from "./rangeValue";
import _, { times } from "lodash";

@Component
export class MetricValue extends Vue {
    parent : WeatherDeviceManager;
    info : Partial<MetricType>;
    trendItem : MetricTrend;
    nameKey: string = '';

    get displayName() {
        return this.nameKey ? this.$t(this.nameKey) : '';
    }

    get displayNameUnit() {
        return this.displayName + (this.unit ? ` (${this.unit})` : '');
    }

    values : MetricValue[];
    ranges: { [key : string]: MetricRange } = {};
    compute : (...values : number[]) => number;

    combine(values : MetricValue[], compute : (...values : number[]) => number, unit? : keyof typeof units, name? : string) {
        const metricValue = new MetricValue(getOptions(this.$options));
        metricValue.init2(this.parent, [this, ...values], compute, units[unit]);
        if(name) metricValue.nameKey = name;
        return metricValue;
    }

    init(parent : WeatherDeviceManager, info : MetricType) {
        this.parent = parent;
        this.info = info;
    }

    init2(parent : WeatherDeviceManager, values : MetricValue[], compute : (...values : number[]) => number, info : Partial<MetricType>) {
        this.parent = parent;
        this.values = values;
        this.compute = compute;
        this.info = info;
    }

    get time() {
        if(this.values) {
            return this.values?.[0]?.time;
        } else {
            return this.parent.currentData.time;
        }
    }

    get converter() {
        if(this.unitInfo?.unit && this.unit !== this.unitInfo.unit) {
            const u = this.unitInfo.units?.find(it => it.unit === this.unit);
            if(u) {
                return (value : number) => floorDP((value * (u.ratio || 1)) + (u.offset || 0), u.dp ?? this.unitInfo.dp ?? 1)
            }
        }
        return (value : number) => floorDP(value, this.unitInfo?.dp ?? 1)
    }

    get roundConverter() {
        if(this.unitInfo?.unit && this.unit !== this.unitInfo.unit) {
            const u = this.unitInfo.units?.find(it => it.unit === this.unit);
            if(u) {
                return (value : number) => roundDP((value * (u.ratio || 1)) + (u.offset || 0), u.dp ?? this.unitInfo.dp ?? 1)
            }
        }
        return (value : number) => roundDP(value, this.unitInfo?.dp ?? 1)
    }

    get unitType() {
        return this.info?.unitType ?? '';
    }

    get unit() : string {
        // return this.info?.unit ? this.$store.getters.displaySetting[this.info.unitType] ?? this.info?.unit ?? '' : '';
        const u = this.info?.unit ? this.$store.getters.displaySetting[this.info.unitType] ?? this.info?.unit ?? '' : '';

        // Translation
        const key = `unit.${u}`
        if(this.$t(key) !== key) return this.$t(key) as string // If translation exist just return the translation

        return u
    }

    get unitNoTranslate() : string {
        const u = this.info?.unit ? this.$store.getters.displaySetting[this.info.unitType] ?? this.info?.unit ?? '' : '';
        return u
    }

    get unitInfo() : MetricType {
        if(this.unitType) {
            const info = units[this.unitType] ?? this.info;
            return info;
        }
        return null;
    }

    get unitConvertInfo() {
        if(this.unitInfo) {
            if(this.unit !== this.unitInfo.unit) {
                const u = this.unitInfo.units?.find(it => it.unit === this.unit);
                if(u) return u;
            }
            return this.unitInfo as MetricUnitType;
        }
        return null;
    }

    get displayUnit(): string {
        let unit = ''
        if(this.unitInfo) {
            if(this.unit !== this.unitInfo.unit) {
                const u = this.unitInfo.units?.find(it => it.unit === this.unit);
                if(u) return u.displayName || u.unit;
            }
            unit = this.unitInfo.displayName || this.unitInfo.unit;
        } else if(this.unit === '**') {
            return ''
        } else {
            unit = this.unit
        }
        return unit;
    }

    get displayUnitWithValue() {
        return isNum(this.valueRaw) ? this.displayUnit : '';
    }

    formatValue(value : number) {
        if(!isNum(value)) return '---';
        return `${this.converter(value)}`;
    }

    formatRoundValue(value : number) {
        if(!isNum(value)) return '---';
        return `${this.roundConverter(value)}`;
    }

    formatValueUnit(value : number) {
        if(!isNum(value)) return '---';
        return this.converter(value) + this.displayUnit;
    }

    formatValueRaw(value : number) {
        if(!isNum(value)) return null;
        return +this.converter(value);
    }

    get isDirect() {
        return !this.values;
    }

    computeValue(data : CurrentDataType, prefix = '') {
        if(!data) return null;
        if(this.values) {
            if(this.compute) {
                return this.compute(...this.values.map(v => v.computeValue(data, prefix)));
            } else {
                return this.values?.[0]?.computeValue(data, prefix);
            }
        }
        return <number>data[prefix + this.info.key];
    }

    get valueRaw() : number {
        return this.computeValue(this.parent.currentData)
    }

    get valueChecked() : number {
        if (this.valueRaw < this.info.min || this.valueRaw > this.info.max) {
            return undefined
        }
        return this.valueRaw
    }

    get value() {
        return this.formatValue(this.valueChecked);
    }

    get roundValue() {
        return this.formatRoundValue(this.valueChecked);
    }

    get valueNum() {
        return this.formatValueRaw(this.valueChecked);
    }

    get valueUnit() {
        return this.formatValueUnit(this.valueChecked);
    }

    get keys() : string[] {
        if(this.values) {
            return _.flatMap(this.values, v => v.keys);
        } else {
            return [this.info.key]
        }
    }

    trend(sensitive = 0.5) {
        if(!this.trendItem) {
            this.trendItem = new MetricTrend(getOptions(this.$options));
            this.trendItem.setup(this, sensitive);
        }
        return this.trendItem;
    }

    range(from : string, to? : string) {
        const range = from + '_' + (to || '');
        let item = this.ranges[range];
        if(!item) {
            this.ranges[range] = item = new MetricRange(getOptions(this.$options));
            item.setup(this, from, to);
        }
        // console.log(range, item);
        return item;
    }

    toString() {
        return this.value
    }
}
