
import helper from '~/plugins/helper'
import TypeSystem, { framework } from './framework/types'
import _ from 'lodash'

export default helper('types', (ctx) => {
    const ts = new TypeSystem();
    _.each(framework, it => ts.addType(it));
    return ts;
});


