var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      ref: "menu",
      attrs: {
        "close-on-content-click": false,
        transition: "scale-transition",
        "offset-y": "",
        "nudge-right": 40,
        "min-width": "290px",
        "return-value": _vm.date
      },
      on: {
        "update:returnValue": function($event) {
          _vm.date = $event
        },
        "update:return-value": function($event) {
          _vm.date = $event
        }
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            return [
              _c(
                "div",
                _vm._g({}, on),
                [
                  _c("v-text-field", {
                    staticClass: "content white--text mt-1",
                    attrs: {
                      "append-icon": "event",
                      "hide-details": "",
                      solo: "",
                      label: _vm.label,
                      readonly: "",
                      clearable: _vm.clearable
                    },
                    model: {
                      value: _vm.date,
                      callback: function($$v) {
                        _vm.date = $$v
                      },
                      expression: "date"
                    }
                  })
                ],
                1
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.menu,
        callback: function($$v) {
          _vm.menu = $$v
        },
        expression: "menu"
      }
    },
    [
      _vm._v(" "),
      _c(
        "v-date-picker",
        {
          attrs: { "no-title": "", scrollable: "", color: "primary" },
          model: {
            value: _vm.date,
            callback: function($$v) {
              _vm.date = $$v
            },
            expression: "date"
          }
        },
        [
          _c("v-spacer"),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: { text: "", color: "primary" },
              on: {
                click: function($event) {
                  _vm.menu = false
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("basic.cancel")))]
          ),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: { text: "", color: "primary" },
              on: {
                click: function($event) {
                  return _vm.$refs.menu.save(_vm.date)
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("basic.ok")))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }