<template>
    <section class="container">
        <!--img src="~assets/img/logo.png" alt="Nuxt.js Logo" class="logo" width="100" /-->
        <div class="alert-info alert-error">
            <div class="font-weight-bold">{{ error.statusCode }}</div>
            <div>
                <p>{{ error.message }}</p>
            </div>
            <div>
                <nuxt-link class="button" to="/" v-if="error.statusCode === 404"
                    >Homepage</nuxt-link
                >
            </div>
        </div>
    </section>
</template>
<script>
export default {
    props: ["error"],
    created() {
        this.$store.commit("SET_TITLE", "Error");
    },
};
</script>

<style scoped></style>
