import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_plugin_60b52d02 from 'nuxt_plugin_plugin_60b52d02' // Source: ./components/plugin.js (mode: 'all')
import nuxt_plugin_plugin_764f062c from 'nuxt_plugin_plugin_764f062c' // Source: ./vuetify/plugin.js (mode: 'all')
import nuxt_plugin_recaptcha_f4fae4f2 from 'nuxt_plugin_recaptcha_f4fae4f2' // Source: ./recaptcha.js (mode: 'all')
import nuxt_plugin_moment_6653a980 from 'nuxt_plugin_moment_6653a980' // Source: ./moment.js (mode: 'all')
import nuxt_plugin_workbox_1f2b5a34 from 'nuxt_plugin_workbox_1f2b5a34' // Source: ./workbox.js (mode: 'client')
import nuxt_plugin_metaplugin_9b1da572 from 'nuxt_plugin_metaplugin_9b1da572' // Source: ./pwa/meta.plugin.js (mode: 'all')
import nuxt_plugin_bootstrap_68fdc73f from 'nuxt_plugin_bootstrap_68fdc73f' // Source: ../plugins/bootstrap.js (mode: 'all')
import nuxt_plugin_feathers_3c83dcb4 from 'nuxt_plugin_feathers_3c83dcb4' // Source: ../plugins/feathers (mode: 'all')
import nuxt_plugin_i18n_1fba523a from 'nuxt_plugin_i18n_1fba523a' // Source: ../plugins/i18n.js (mode: 'all')
import nuxt_plugin_typeSystem_3db7a8d0 from 'nuxt_plugin_typeSystem_3db7a8d0' // Source: ../plugins/typeSystem.js (mode: 'all')
import nuxt_plugin_external_7c60fd16 from 'nuxt_plugin_external_7c60fd16' // Source: ../plugins/external.js (mode: 'all')
import nuxt_plugin_dialogHandler_0d36f54b from 'nuxt_plugin_dialogHandler_0d36f54b' // Source: ../plugins/dialogHandler.js (mode: 'all')
import nuxt_plugin_chatManager_2168e6ce from 'nuxt_plugin_chatManager_2168e6ce' // Source: ../plugins/chatManager.ts (mode: 'all')
import nuxt_plugin_weatherManager_f871abe2 from 'nuxt_plugin_weatherManager_f871abe2' // Source: ../plugins/weatherManager (mode: 'all')
import nuxt_plugin_messageQueue_3226f36d from 'nuxt_plugin_messageQueue_3226f36d' // Source: ../plugins/messageQueue.ts (mode: 'all')
import nuxt_plugin_stripe_64a3c50a from 'nuxt_plugin_stripe_64a3c50a' // Source: ../plugins/stripe.ts (mode: 'all')
import nuxt_plugin_mapManager_79d77ab2 from 'nuxt_plugin_mapManager_79d77ab2' // Source: ../plugins/mapManager (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root.$options.$nuxt
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":true,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const router = await createRouter(ssrContext, config)

  const store = createStore(ssrContext)
  // Add this.$router into store actions/mutations
  store.$router = router

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"title":"ProWeatherLive","meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no, viewport-fit=cover"},{"hid":"keywords","name":"keywords","content":"pwl, proweatherlive, pro weather live, Pro weather live, Proweatherlive"},{"hid":"robots","name":"robots","content":"noindex,nofollow"},{"hid":"description","name":"description","content":"ProWeatherLive lets you see your personal weather station data and local forecasts. ProWeatherLive allows you to see your home environment, such as wind speed and direction, rainfall, UV, Light, temperature, and humidity, as well as customize alerts, and monitor your weather with graphs. Enjoy real time data by ProWeatherLive."},{"name":"apple-mobile-web-app-capable","content":"yes"}],"link":[{"rel":"icon","type":"image\u002Fx-icon","href":"\u002Ffavicon.ico"}],"script":[{"src":"https:\u002F\u002Fcdn.fusioncharts.com\u002Ffusioncharts\u002Flatest\u002Ffusioncharts.js"},{"src":"https:\u002F\u002Fjs.stripe.com\u002Fv3"},{"src":"https:\u002F\u002Fpay.google.com\u002Fgp\u002Fp\u002Fjs\u002Fpay.js"},{"src":"https:\u002F\u002Fmaps.googleapis.com\u002Fmaps\u002Fapi\u002Fjs?key=AIzaSyAxQ6fvK9kIhf4BfoVC5RsZ8eel9hA2Gkg&loading=async&libraries=maps,marker&v=beta"}],"style":[]},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_plugin_60b52d02 === 'function') {
    await nuxt_plugin_plugin_60b52d02(app.context, inject)
  }

  if (typeof nuxt_plugin_plugin_764f062c === 'function') {
    await nuxt_plugin_plugin_764f062c(app.context, inject)
  }

  if (typeof nuxt_plugin_recaptcha_f4fae4f2 === 'function') {
    await nuxt_plugin_recaptcha_f4fae4f2(app.context, inject)
  }

  if (typeof nuxt_plugin_moment_6653a980 === 'function') {
    await nuxt_plugin_moment_6653a980(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_workbox_1f2b5a34 === 'function') {
    await nuxt_plugin_workbox_1f2b5a34(app.context, inject)
  }

  if (typeof nuxt_plugin_metaplugin_9b1da572 === 'function') {
    await nuxt_plugin_metaplugin_9b1da572(app.context, inject)
  }

  if (typeof nuxt_plugin_bootstrap_68fdc73f === 'function') {
    await nuxt_plugin_bootstrap_68fdc73f(app.context, inject)
  }

  if (typeof nuxt_plugin_feathers_3c83dcb4 === 'function') {
    await nuxt_plugin_feathers_3c83dcb4(app.context, inject)
  }

  if (typeof nuxt_plugin_i18n_1fba523a === 'function') {
    await nuxt_plugin_i18n_1fba523a(app.context, inject)
  }

  if (typeof nuxt_plugin_typeSystem_3db7a8d0 === 'function') {
    await nuxt_plugin_typeSystem_3db7a8d0(app.context, inject)
  }

  if (typeof nuxt_plugin_external_7c60fd16 === 'function') {
    await nuxt_plugin_external_7c60fd16(app.context, inject)
  }

  if (typeof nuxt_plugin_dialogHandler_0d36f54b === 'function') {
    await nuxt_plugin_dialogHandler_0d36f54b(app.context, inject)
  }

  if (typeof nuxt_plugin_chatManager_2168e6ce === 'function') {
    await nuxt_plugin_chatManager_2168e6ce(app.context, inject)
  }

  if (typeof nuxt_plugin_weatherManager_f871abe2 === 'function') {
    await nuxt_plugin_weatherManager_f871abe2(app.context, inject)
  }

  if (typeof nuxt_plugin_messageQueue_3226f36d === 'function') {
    await nuxt_plugin_messageQueue_3226f36d(app.context, inject)
  }

  if (typeof nuxt_plugin_stripe_64a3c50a === 'function') {
    await nuxt_plugin_stripe_64a3c50a(app.context, inject)
  }

  if (typeof nuxt_plugin_mapManager_79d77ab2 === 'function') {
    await nuxt_plugin_mapManager_79d77ab2(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    router.push(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
