


export default {
    functional: true,
    render(_c, { children, props, parent, data }) {
        const altText = props.altText;
        const altColor = props.altColor;
        const iconText = parent.$store.getters.iconText;
        return (altText && iconText) ? 
            _c('v-btn', { ...data, props: { ...data.props, icon: false, color: altColor }, style: { minWidth: '40px', width: '60px', padding: 0} }, [_c('span', altText ), ...(props.altIcon ? children : [])]) :
            _c('v-btn', { ...data, props: { ...data.props, icon: true, small: true } }, children);
    }
};

