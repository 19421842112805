import { Component, Prop, Watch, Vue, mixins } from "nuxt-property-decorator";
import { DeviceBase } from "./deviceBase";

import {
    GraphGroupOptions,
    graphFilters,
    GraphFilter,
    CurrentDataType,
    msgpack,
    arrayToObj,
} from "./utils";
import moment from "moment";
import _ from "lodash";
export interface GraphData {
    metrics: string[];
    startTime: Date;
    endTime: Date;
    values: CurrentDataType[];
}

const durations = {
    "5m": 300,
    "30m": 1800,
    "1h": 3600,
    "1d": 86400,
};

@Component
export class GraphMixin extends mixins(DeviceBase) {
    graphs: GraphGroupOptions[] = [];
    graphData: GraphData;
    graphLoading = false;
    graphRefresh = false;

    get graphReady() {
        return !this.graphLoading && !this.graphRefresh;
    }

    // need to check exists first
    addGraph(options: GraphGroupOptions, metricKey: string) {
        this.graphs.unshift({
            ...options,
            metricKey,
            graphs: options.graphs.map((g) => ({
                ...g,
                datasets: g.datasets.map((i) => Object.freeze(i)),
                chartData: null,
            })),
        });
    }

    removeGraph(key: string) {
        const current = this.graphs.findIndex((it) => it.key === key);
        if (current !== -1) {
            this.graphs.splice(current, 1);
        }
    }

    async toggleGraph(options: GraphGroupOptions, metricKey: string) {
        const current = this.graphs.findIndex((it) => it.key === options.key);
        if (current === -1) {
            this.addGraph(options, metricKey);
            this.startRefresh();

            await new Promise((resolve) => setTimeout(resolve, 100));
            const elem = document.querySelector(".graph");
            if (elem) {
                this.$vuetify.goTo(
                    elem.getBoundingClientRect().y +
                        document.scrollingElement.scrollTop,
                    {
                        duration: 600,
                        offset: 0,
                        easing: "easeOutCubic",
                    }
                );
            }
        } else {
            this.graphs.splice(current, 1);
        }
        await this.uploadDisplay();
    }

    graphFilter: GraphFilter = graphFilters[0];
    customDate: string = moment(new Date()).format("YYYY-MM-DD");
    maxDate = moment(new Date()).format("YYYY-MM-DD");
    minDate = moment(new Date()).format("YYYY-MM-DD");
    monthDate = moment(new Date()).format("YYYY-MM");
    multiDates = [
        moment(new Date())
            .subtract(1, "month")
            .subtract(7, "days")
            .format("YYYY-MM-DD"),
        moment(new Date())
            .subtract(1, "month")
            .format("YYYY-MM-DD"),
    ];

    get graphFilterName() {
        return this.$t("dashboard.graphFilters." + this.graphFilter.key);
    }

    get graphCustom() {
        return this.graphFilter.key === "custom";
    }
    get graphMonth() {
        return this.graphFilter.key === "month";
    }
    get graphType() {
        return this.graphFilter.key === "month" ? "month" : "date";
    }

    get graphStartDate() {
        return this.graphCustom
            ? this.multiDates[0] > this.multiDates[1]
                ? this.multiDates[1]
                : this.multiDates[0]
            : this.graphMonth
            ? moment(this.monthDate)
                  .startOf("month")
                  .format("YYYY-MM-DD")
            : this.customDate;
    }
    get graphEndDate() {
        return this.graphCustom
            ? this.multiDates[0] > this.multiDates[1]
                ? this.multiDates[0]
                : this.multiDates[1]
            : this.graphMonth
            ? moment(this.monthDate)
                  .endOf("month")
                  .format("YYYY-MM-DD")
            : moment().format("YYYY-MM-DD");
    }

    get graphPeriodText() {
        return `${this.graphPeriod} (${this.graphFilterName})`;
    }

    get graphPeriod() {
        switch (this.graphFilter.key) {
            case "day":
                return this.customDate;
            case "last24H":
                return this.minDate;
            case "week":
                return `${moment(this.customDate)
                    .startOf("week")
                    .format("YYYY-MM-DD")} - ${moment(this.customDate)
                    .endOf("week")
                    .format("YYYY-MM-DD")}`;
            case "month":
                return `${moment(this.monthDate)
                    .startOf("month")
                    .format("YYYY-MM-DD")} - ${moment(this.monthDate)
                    .endOf("month")
                    .format("YYYY-MM-DD")}`;
            case "year":
                return `${moment(this.customDate)
                    .startOf("year")
                    .format("YYYY-MM-DD")} - ${moment(this.customDate)
                    .endOf("year")
                    .format("YYYY-MM-DD")}`;
            case "custom":
                return `${this.graphStartDate} - ${this.graphEndDate ||
                    this.maxDate}`;
        }
    }

    @Watch("customDate", { deep: true })
    onCustomDate(value, oldValue) {
        if (this.graphMonth) {
            this.customDate = moment(value)
                .startOf("month")
                .format("YYYY-MM-DD");
        }
    }

    get graphRange() {
        var startTime: Date = null;
        var endTime: Date = null;
        var labelType: string = null;
        var range: string = null;

        let m = moment().startOf("minute");
        m = m.add(5 - (m.minute() % 5), "minute");

        switch (this.graphFilter.key) {
            case "last24H":
                startTime = m
                    .clone()
                    .subtract(24, "hours")
                    .toDate();
                endTime = m.clone().toDate();
                labelType = "1d";
                range = "5m";
                break;
            case "day":
                startTime = moment(this.graphStartDate)
                    .tz(
                        this.$weatherManager.device?.timezone ??
                            "Asia/Hong_Kong",
                        true
                    )
                    .startOf("day")
                    .toDate();
                endTime = moment(startTime)
                    .add(1, "day")
                    .toDate();
                labelType = "fullDay";
                range = "5m";
                break;
            case "week":
                startTime = moment(this.graphStartDate)
                    .tz(
                        this.$weatherManager.device?.timezone ??
                            "Asia/Hong_Kong",
                        true
                    )
                    .startOf("week")
                    .toDate();
                endTime = moment(startTime)
                    .add(1, "week")
                    .toDate();
                labelType = "week";
                range = "30m";
                break;
            case "month":
                startTime = moment(this.graphStartDate)
                    .tz(
                        this.$weatherManager.device?.timezone ??
                            "Asia/Hong_Kong",
                        true
                    )
                    .startOf("month")
                    .toDate();
                endTime = moment(startTime)
                    .add(1, "month")
                    .toDate();
                labelType = "month";
                range = "1h";
                break;
            case "year":
                startTime = moment(this.graphStartDate)
                    .tz(
                        this.$weatherManager.device?.timezone ??
                            "Asia/Hong_Kong",
                        true
                    )
                    .startOf("year")
                    .toDate();
                endTime = moment(startTime)
                    .add(1, "year")
                    .toDate();
                labelType = "year";
                range = "1d";
                break;
            case "custom":
                startTime = moment(this.graphStartDate)
                    .tz(
                        this.$weatherManager.device?.timezone ??
                            "Asia/Hong_Kong",
                        true
                    )
                    .startOf("day")
                    .toDate();
                endTime = moment
                    .min(
                        m,
                        moment(this.graphEndDate)
                            .tz(
                                this.$weatherManager.device?.timezone ??
                                    "Asia/Hong_Kong",
                                true
                            )
                            .endOf("day")
                    )
                    .toDate();
                // let diff = moment(graphEndDate).toDate().diff(moment(graphStartDate).toDate(), 'days')
                let diff = Math.ceil(
                    moment
                        .duration(moment(endTime).diff(moment(startTime)))
                        .asDays()
                );
                labelType =
                    diff >= 32
                        ? "year"
                        : diff >= 8
                        ? "month"
                        : diff >= 2
                        ? "week"
                        : "1d";
                range =
                    diff >= 32
                        ? "1d"
                        : diff >= 8
                        ? "1h"
                        : diff >= 2
                        ? "30m"
                        : "5m";
                break;
            default:
                startTime = moment()
                    .tz(
                        this.$weatherManager.device?.timezone ??
                            "Asia/Hong_Kong",
                        true
                    )
                    .startOf("day")
                    .toDate();
                endTime = moment
                    .min(
                        m,
                        moment()
                            .tz(
                                this.$weatherManager.device?.timezone ??
                                    "Asia/Hong_Kong",
                                true
                            )
                            .endOf("day")
                    )
                    .toDate();
                labelType = "1d";
                range = "1h";
        }
        return { startTime: startTime, endTime: endTime, labelType, range };
    }

    @Watch("graphRange")
    onRange() {
        if (this.graphLoading) {
            this.graphRefresh = true;
        } else {
            this.startRefresh();
        }
    }

    graphRefreshPromise: Promise<void> = null;
    @Watch("$store.getters.displaySetting", { deep: true })
    onUnitChanged() {
        this.startRefresh();
    }

    startRefresh() {
        if (!this.graphRefreshPromise) {
            this.graphRefreshPromise = this.refreshCore();
        }
        this.graphRefresh = true;
        return this.graphRefreshPromise;
    }

    async refreshCore() {
        try {
            await Vue.nextTick();
            this.graphRefresh = false;
            this.graphLoading = true;
            const { startTime, endTime, labelType, range } = this.graphRange;
            console.log(
                "load graph",
                labelType,
                startTime.toISOString(),
                endTime.toISOString()
            );
            const metrics = _.uniq(
                _.flatMap(this.graphs, (g) =>
                    _.flatMap(g.graphs, (it) =>
                        _.flatMap(it.datasets, (jt) => {
                            const a =
                                typeof jt.value === "function"
                                    ? jt.value()
                                    : jt.value;
                            const keys = a.keys;
                            if (range === "5m") return keys;
                            const agg = jt.aggregate ?? "mean";
                            return keys.map((k) => agg + "_" + k);
                        })
                    )
                )
            );
            if (metrics.length) {
                const binaryData = await this.$feathers
                    .service("weatherMetrics/queryGroup")
                    .find({
                        query: {
                            device: this.id,
                            range,
                            metrics,
                            startTime: startTime.toISOString(),
                            endTime: endTime.toISOString(),
                        },
                    });
                const data = msgpack.decode(binaryData);
                const convert = arrayToObj(data[0], this.id);
                const allData: CurrentDataType[] = data[1].map(convert);
                
                // This is intended to add back missing data
                if (range === "5m") {
                    const st = moment(startTime).unix();
                    const et = moment(endTime).unix();
                    const kData: Record<
                        number,
                        CurrentDataType
                    > = Object.fromEntries(allData.map((e) => [e.time, e]));
                    const realAllData: CurrentDataType[] = [];
                    for (let i = st; i <= et; i += durations[range]) {
                        const v = kData[i];
                        if (!!v) {
                            realAllData.push(v);
                        } else {
                            realAllData.push({ time: i, device: this.id });
                        }
                    }
                    this.graphData = {
                        metrics: data[0],
                        values: realAllData,
                        startTime,
                        endTime,
                    };
                } else {
                    this.graphData = {
                        metrics: data[0],
                        values: allData,
                        startTime,
                        endTime,
                    };
                }

                const self = this;

                for (let group of this.graphs) {
                    for (let graph of group.graphs) {
                        var graphItem = {
                            isBarChart: graph.isBarChart
                                ? graph.isBarChart()
                                : false,
                            labels: this.generateLabels(labelType, this.graphData.values),
                            datasets: [],
                        };
                        for (let dataset of graph.datasets) {
                            const prefix =
                                range === "5m"
                                    ? ""
                                    : (dataset.aggregate ?? "mean") + "_";
                            const a =
                                typeof dataset.value === "function"
                                    ? dataset.value()
                                    : dataset.value;
                            let data = _.map(this.graphData.values, (it) =>
                                a.formatValueRaw(a.computeValue(it, prefix))
                            );

                            // sor dk how to fix
                            if (graph.key === "winddir") {
                                data = data.map((v) =>
                                    v === null ? null : Math.floor(v)
                                );
                            }

                            const accum =
                                typeof dataset.accum === "function"
                                    ? dataset.accum(range)
                                    : dataset.accum;
                            if (accum) {
                                let v = 0;
                                for (let i = 0; i < data.length; i++) {
                                    const cur = data[i] || 0;
                                    data[i] = v + cur;
                                    v += cur;
                                }
                            }
                            if (_.every(data, (it) => it === null)) {
                                data = [];
                            }
                            graphItem.datasets.push({
                                get label() {
                                    const a =
                                        typeof dataset.value === "function"
                                            ? dataset.value()
                                            : dataset.value;
                                    return (
                                        (dataset.name
                                            ? self.$t(dataset.name)
                                            : a.displayName) +
                                        (a.displayUnit && a.displayUnit !== "**"
                                            ? ` (${a.displayUnit})`
                                            : "")
                                    );
                                },
                                fill: false,
                                hidden: dataset.hidden ?? false,
                                borderWidth: 3,
                                lineTension: 0,
                                borderColor: dataset.color,
                                backgroundColor: dataset.color,
                                data,
                                ...(dataset.type === "scatter"
                                    ? {
                                          type: "scatter",
                                          pointRadius: 1,
                                      }
                                    : {
                                          pointRadius: 0,
                                      }),
                            });
                        }
                        graph.chartData = graphItem;
                    }
                }
            }
        } finally {
            this.graphLoading = false;
        }
        if (this.graphRefresh) {
            await new Promise((resolve) => setTimeout(resolve, 1000));
            this.graphRefreshPromise = null;
            this.graphRefresh = false;
            return (this.graphRefreshPromise = this.refreshCore());
        } else {
            this.graphRefreshPromise = null;
        }
    }

    generateLabels(type: string, list: CurrentDataType[]) {
        var result = [];
        if (type == "fullDay") {
            result = _.map(list, (it) =>
                moment
                    .unix(it.time)
                    .tz(this.$weatherManager.device.timezone)
                    .format("HH:mm")
            );
            const lastOne = _.last(list);
            if (lastOne && lastOne.time) {
                var current = moment
                    .unix(lastOne.time)
                    .tz(this.$weatherManager.device.timezone)
                    .add(5, "minutes");
                while (current.isBefore(this.graphRange.endTime)) {
                    result.push(current.format("HH:mm"));
                    current = moment(current)
                        .tz(this.$weatherManager.device.timezone)
                        .add(5, "minutes");
                }
            }
            return result;
        }
        if (type == "1d") {
            result = _.map(list, (it) =>
                moment
                    .unix(it.time)
                    .tz(this.$weatherManager.device.timezone)
                    .format("HH:mm")
            );
            return result;
        }
        if (_.indexOf(["past7Day", "thisweek", "week"], type) >= 0) {
            var index = 0;
            _.forEach(list, (it) => {
                if (index % 48 == 0) {
                    result.push(
                        moment
                            .unix(it.time)
                            .tz(this.$weatherManager.device.timezone)
                            .format("YYYY-MM-DD")
                    );
                } else {
                    result.push(
                        moment
                            .unix(it.time)
                            .tz(this.$weatherManager.device.timezone)
                            .format("HH:mm")
                    );
                }
                index += 1;
            });
            return result;
        }
        if (
            _.indexOf(["past30Day", "thisMonth", "month", "custom"], type) >= 0
        ) {
            return list.map((it) =>
                moment
                    .unix(it.time)
                    .tz(this.$weatherManager.device.timezone)
                    .format("YYYY-MM-DD HH:mm")
            );
        }

        result = _.map(list, (it) =>
            moment
                .unix(it.time)
                .tz(this.$weatherManager.device.timezone)
                .format("YYYY-MM-DD")
        );
        return result;
    }
}
