var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "container" }, [
    _c("div", { staticClass: "alert-info alert-error" }, [
      _c("div", { staticClass: "font-weight-bold" }, [
        _vm._v(_vm._s(_vm.error.statusCode))
      ]),
      _vm._v(" "),
      _c("div", [_c("p", [_vm._v(_vm._s(_vm.error.message))])]),
      _vm._v(" "),
      _c(
        "div",
        [
          _vm.error.statusCode === 404
            ? _c("nuxt-link", { staticClass: "button", attrs: { to: "/" } }, [
                _vm._v("Homepage")
              ])
            : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }