import helper from '~/plugins/helper'

export default helper('external', (ctx) => {
    if(process.server) return;
    
    const loadScript = require('simple-load-script');

    let stripe, facebook, google;

    return {
        // loadStripe() {
        //     return (stripe = (stripe || loadScript('https://js.stripe.com/v3/')));
        // },

        loadGoogle() {
            return (google = (google || (async () => {
                await loadScript('https://apis.google.com/js/platform.js?onload=googleInit', { callBackName: 'googleInit' });
                const gapi = global.gapi;
                await new Promise(resolve => gapi.load('auth2', resolve));
                const auth = await gapi.auth2.init({
                    client_id: GOOGLE_ID,
                })

                return auth;
            })()));
        },

        loadFacebook() {
            return (facebook = (facebook || (async () => {
                await loadScript('https://connect.facebook.net/en_US/sdk.js', { callBackName: 'fbAsyncInit' });
                const FB = global.FB;
                FB.init({
                    appId: FB_APPID,
                    cookie: true,
                    xfbml: true,
                    version: FB_VER,
                });
                FB.loginAsync = function(opts) {
                    return new Promise((resolve, reject) => {
                        FB.login(resp => {
                            if(!resp.authResponse) {
                                throw new Error("Cannot login");
                            }
        
                            resolve(resp.authResponse);
                        }, opts)
                    })
                }

                FB.logoutAsync = function() {
                    return new Promise((resolve, reject) => {
                        FB.logout(resp => {
                            resolve(resp);
                        })
                    })
                }

                FB.apiAsync = function(url, method) {
                    return new Promise((resolve, reject) => {
                        if(method) {
                            FB.api(url, method, resp => {
                                resolve(resp);
                            });
                        } else {
                            FB.api(url, resp => {
                                resolve(resp);
                            });
                        }
                    })
                }

                FB.deleteAsync = function() {
                    return FB.apiAsync('/me/permissions', 'DELETE')
                }

                return FB;
            })()));
        },
    }
})
