import { Component, Prop, Watch, Vue } from "nuxt-property-decorator";
import _ from 'lodash'
import type {
    MetricValue,
} from './index'
import moment from "moment";
import "moment-timezone";
import { CurrentDataType, isNum } from "./utils";
import { throws } from "assert";
import { WeatherDeviceManager } from "./device";

const unitMap = {
    m: 'month',
    y: 'year',
    d: 'day',
    h: 'hour',
    w: 'week',
    M: 'minute'
} as const;

@Component
export class MetricRange extends Vue {
    item : MetricValue = null;
    parent : WeatherDeviceManager;

    from: { n: number, u : moment.unitOfTime.Base, o: '' | '-' | '+' };
    to: { n: number, u : moment.unitOfTime.Base, o: '' | '-' | '+' };

    min : number = null;
    max : number = null;
    mean : number = null;
    last : number = null;

    get minNormalized() { return this.item.formatValueRaw(this.min) }
    get maxNormalized() { return this.item.formatValueRaw(this.max) }
    get meanNormalized() { return this.item.formatValueRaw(this.mean) }
    get lastNormalized() { return this.item.formatValueRaw(this.last) }

    get minNormalizedStr() { return this.item.formatValue(this.min) }
    get maxNormalizedStr() { return this.item.formatValue(this.max) }
    get meanNormalizedStr() { return this.item.formatValue(this.mean) }
    get lastNormalizedStr() { return this.item.formatValue(this.last) }

    get minNormalizedUnit() { return this.item.formatValueUnit(this.min) }
    get maxNormalizedUnit() { return this.item.formatValueUnit(this.max) }
    get meanNormalizedUnit() { return this.item.formatValueUnit(this.mean) }
    get lastNormalizedUnit() { return this.item.formatValueUnit(this.last) }

    loaded = false;
    minuteOnly  : boolean
    includeMinuteAccum  : boolean
    includeDayAccum  : boolean
    isDirect : boolean
    isSepValue : boolean

    accumState: {
        values: number[]
        timeValues: [number, number][];
        minValues: number[]
        minTimeValues: [number, number][];
        maxValues: number[]
        maxTimeValues: [number, number][];
        sum: number
        count: number
        last: number
    };

    parseRange(range : string) {
        const matches = range.match(/^(\d*?)((?:(?![\d\+\-]).)*?)([\+\-]?)$/) || [];
        return {
            n: parseFloat(matches[1]),
            u: unitMap[matches[2]] || 'second',
            o: (matches[3] || '') as ('+' | '-' | ''),
        }
    }

    setup(item : MetricValue, from : string, to? : string) {
        this.item = item;
        to = to ?? '0';

        this.from = this.parseRange(from);
        this.to = this.parseRange(to);
        this.minuteOnly = moment.duration(this.from.n + (this.from.o === '-' ? 1 : 0), this.from.u).asHours() <= 48;
        this.includeMinuteAccum = moment.duration(this.to.n, this.to.u).asMinutes() < 5;
        this.includeDayAccum = !this.minuteOnly && moment.duration(this.to.n, this.from.u).asDays() < 1;
        this.parent = this.item.parent;
        this.isDirect = this.item.isDirect;
        this.isSepValue = this.isDirect && !this.minuteOnly;

        this.compute();
        this.item.parent.$on('accumReset', type => {
            if(type !== '5m' && this.minuteOnly) return;
            this.compute();
        });

        if(this.includeDayAccum) {
            this.item.parent.$on('updateAccumDay', this.updateFromState);
            this.item.parent.$on('appendAccumDay', (it) => {
                this.appendItem(it);
                this.updateFromState();
            });
        }

        if(this.includeMinuteAccum) {
            // this.item.parent.$on('updateAccumMin', this.updateFromState);
            if(this.minuteOnly) {
                this.item.parent.$on('appendAccumMin', (it) => {
                    this.appendItem(it);
                    this.updateFromState();
                });
            }
        }
    }

    calOffset(v : { n: number, u : moment.unitOfTime.Base, o: '' | '-' | '+' }) {
        let cur = moment.unix(this.item.parent.getUnixMinute());
        if(v.o === '-') cur = cur.startOf(v.u);
        else if(v.o === '+') cur = cur.endOf(v.u);
        return cur.subtract(v.n, v.u).unix()
    }

    compute() {
        const parent = this.item.parent;
        if(parent.loading) return;
        const from = this.calOffset(this.from)
        const to = this.calOffset(this.to);
        if(!parent.ensureHistory(from, to)) {
            console.log('[DATA NOT READY]', this.item.displayName, 'range', this.from, this.to);
            return;
        }
        console.log('[COMPUTE]', this.item.displayName, 'range', this.from, this.to);
        this.accumState = {
            values: [],
            timeValues: [],
            minValues: [],
            minTimeValues: [],
            maxValues: [],
            maxTimeValues: [],
            sum: 0,
            count: 0,
            last: 0,
        }

        this.appendFromSoruce(from, to);
        this.updateFromState();
    }

    appendFromSoruce(from : number, to : number) {
        const dataSource = this.minuteOnly ? this.parent.minuteData : this.parent.dailyData;
        const fromIndex = _.sortedIndexBy(dataSource, { time: from, device: null }, s => s.time);
        const toIndex = _.sortedLastIndexBy(dataSource, { time: to, device: null }, s => s.time);

        const delayed = (toIndex - fromIndex) > 10;

        for(let i = fromIndex; i < toIndex; i++) {
            const it = dataSource[i];
            this.appendItem(it, delayed);
        }

        if(delayed && this.accumState) {
            this.accumState.values.sort((a, b) => a - b);
            this.accumState.minValues.sort((a, b) => a - b);
            this.accumState.maxValues.sort((a, b) => a - b);
        }
        // if(!this.minuteOnly) {
        //     const minFrom = (this.accumState.timeValues[this.accumState.timeValues.length - 1]?.[0] || from) + 24*3600;
        //     const minTo = Math.min(to, this.calOffset({ n: -1, u: 'day', o: '-'}));

        //     const fromIndex = _.sortedIndexBy(this.parent.minuteData, { time: minFrom, device: null }, s => s.time);
        //     const toIndex = _.sortedLastIndexBy(this.parent.minuteData, { time: minTo, device: null }, s => s.time);

        //     // missing current daily
        //     console.log(minFrom, minTo, fromIndex, toIndex);
        // }
    }

    appendItem(it : CurrentDataType, delayed? : boolean) {
        const accum = this.accumState;
        if(!accum) return;
        if(this.isSepValue) {
            const min = this.item.computeValue(it, 'min_');
            const max = this.item.computeValue(it, 'max_');
            const val = this.item.computeValue(it, 'mean_');
            if(isNum(val)) {
                accum.timeValues.push([it.time, val])
                accum.sum += val;
                accum.count++;
                accum.last = val;
            }
            if(isNum(min)) {
                accum.minTimeValues.push([it.time, min]);
                if(delayed) {
                    accum.minValues.push(min);
                } else {
                    const idx = _.sortedIndex(accum.minValues, min);
                    accum.minValues.splice(idx, 0, min);
                }
            }
            if(isNum(max)) {
                accum.maxTimeValues.push([it.time, max]);
                if(delayed) {
                    accum.maxValues.push(max);
                } else {
                    const idx = _.sortedIndex(accum.maxValues, max);
                    accum.maxValues.splice(idx, 0, max);
                }
            }
        } else {
            const val = this.item.computeValue(it, this.minuteOnly ? '' : 'mean_');
            if(isNum(val)) {
                accum.timeValues.push([it.time, val])
                if(delayed) {
                    accum.values.push(val);
                } else {
                    const idx = _.sortedIndex(accum.values, val);
                    accum.values.splice(idx, 0, val);
                }
                accum.sum += val;
                accum.count++;
                accum.last = val;
            }
        }
    }

    updateFromState() {
        const accum = this.accumState;
        if(!accum) return;

        const parent = this.item.parent;
        const from = this.calOffset(this.from)
        const to = this.calOffset(this.to);

        console.log('[UPDATE]', this.item.displayName, this.from.n, this.from.u, this.to.n, this.to.u, 'range', moment.unix(from).format("YYYY-MM-DD HH:mm:ss"), moment.unix(to).format("YYYY-MM-DD HH:mm:ss"), "current start", moment.unix(accum.timeValues[0]?.[0]).format("YYYY-MM-DD HH:mm:ss"))

        while(from > accum.timeValues[0]?.[0]) {
            // remove outdated items
            const [time, val] = accum.timeValues.shift();
            if(!this.isSepValue) {
                const idx = _.sortedIndexOf(accum.values, val);
                idx !== -1 && accum.values.splice(idx, 1);
            }
            
            accum.sum -= val;
            accum.count--;
            if(!accum.count) accum.last = null;
        }

        if(this.isSepValue) {
            while(from > accum.minTimeValues[0]?.[0]) {
                // remove outdated items
                const [time, val] = accum.minTimeValues.shift();
                const idx = _.sortedIndexOf(accum.minValues, val);
                idx !== -1 && accum.minValues.splice(idx, 1);
            }
            while(from > accum.maxTimeValues[0]?.[0]) {
                // remove outdated items
                const [time, val] = accum.maxTimeValues.shift();
                const idx = _.sortedIndexOf(accum.maxValues, val);
                idx !== -1 && accum.maxValues.splice(idx, 1);
            }
        }

        if(to > accum.timeValues[accum.timeValues.length - 1]?.[0]) {
            const fromTime = accum.timeValues[accum.timeValues.length - 1][0];
            this.appendFromSoruce(fromTime + (this.minuteOnly ? 300 : 3600*24), to);
        }

        let min = this.isSepValue ? accum.minValues[0] : accum.values[0];
        let max = this.isSepValue ? accum.maxValues[accum.maxValues.length - 1] : accum.values[accum.values.length - 1];
        let sum = accum.sum;
        let count = accum.count;
        let last = accum.last;

        if(this.includeDayAccum) {
            const ratio = Math.max(1, (parent.getUnixLocalToday() - parent.getUnixUtcToday() + 3600*24) % (3600*24) / 300) / 288;
            if(this.isSepValue) {
                const newMin = this.item.computeValue(parent.accumDay, 'min_');
                const newMax = this.item.computeValue(parent.accumDay, 'max_');
                const newMean = this.item.computeValue(parent.accumDay, 'mean_');
                const newLast = this.item.computeValue(parent.accumDay, 'last_');

                if(isNum(newMin) && (!isNum(min) || newMin < min)) min = newMin;
                if(isNum(newMax) && (!isNum(max) || newMax > max)) max = newMax;
                if(isNum(newMean)) {
                    sum += newMean * ratio;
                    count += ratio;
                }
                if(isNum(newLast)) last = newLast;
            } else {
                const val = this.item.computeValue(parent.accumDay, 'mean_');
                if(isNum(val)) {
                    if((!isNum(min) || val < min)) min = val;
                    if((!isNum(max) || val > max)) max = val;
                    sum += val * ratio;
                    count += ratio;
                    last = val;
                }
            }
        }

        if(this.includeMinuteAccum) {
            // this part includes also real time data in min / max
            // const val = this.item.computeValue(parent.accumMin);
            // if(isNum(val)) {
            //     let ratio = Math.max(1, (Date.now() / 1000 - parent.getUnixMinute()) / 12) / 25;
            //     if(!this.minuteOnly) ratio /= 288;

            //     if((!isNum(min) || val < min)) min = val;
            //     if((!isNum(max) || val > max)) max = val;
            //     sum += val * ratio;
            //     count += ratio;
            //     last = val;
            // }
        }

        this.min = min;
        this.max = max;
        this.last = last;
        this.mean = count ? sum / count : null;
        this.loaded = true;
    }

    
}


