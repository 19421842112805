
import { Component, Prop, Watch, Vue } from "nuxt-property-decorator";
import _ from 'lodash'
import { 
    openDB,
    IDBPDatabase,
} from 'idb'


export * from './trend'
export * from './device'
export * from './deviceGraph'
export * from './deviceBase'
export * from './deviceAlert'
export * from './utils'
export * from './value'
export * from './metric'
export * from './deviceTypes'

import {
    WeatherDeviceManager
} from './device'

import {
    MetricType,
    IDB,
    WeatherDevice,
    DisplaySetting,
    msgpack,
    getOptions,
    CurrentDataType,
    arrayToObj,
} from './utils'

@Component
export class WeatherManager extends Vue {
    devices : WeatherDeviceManager[] = [];
    deviceDict: {
        [key : string] : WeatherDeviceManager
    } = {};

    loading = false;
    error = false;
    loadingPromise : Promise<void>;
    device : WeatherDeviceManager = null;
    get deviceId() { return this.device?.id }

    subscribed = false;
    setupPromise: Promise<void>;
    db : IDBPDatabase<IDB>;
    metrics : MetricType[] = [];
    metricsHash : string = null;
    metricsConverter : (data : any) => CurrentDataType;
    manageMode = false;
    publicSync = false;

    get ownedDevices() {
        return this.devices.filter(device => device.owned);
    }

    setup() {
        return (this.setupPromise || (this.setupPromise = this.setupCore()));
    }

    async beginManage() {
        this.manageMode = true;
        await this.subscribeDevice();
        await Promise.all(this.devices.map(async device => {
            await device.preloadLatest();
        }))
    }

    async endManage() {
        this.manageMode = false;
        await this.subscribeDevice();
    }

    hourTimer: any;
    updateTimer : any = null

    async setupCore() {
        this.updateTimer = setInterval(this.updateLastUpdate, 1*1000);
        this.$feathers.service('weatherDevices').on('patched', <any>this.onDeviceUpdated);
        this.$feathers.service('weatherDevices').on('created', <any>this.onDeviceCreated);
        this.$feathers.service('weatherDevices').on('removed', <any>this.removeDevice);
        this.$feathers.service('deviceDisplaySettings').on('patched', this.onDisplaySettings);
        this.$feathers.service('weatherMetrics').on('created', this.onWeatherMetrics);
        this.$feathers.service('weatherMetrics/5m').on('created', this.onWeatherMetricsHistory);
        this.$feathers.service('weatherMetrics/forecast').on('created', this.onWeatherForecast);
        this.$feathers.service('alerts').on('created', this.onWeatherAlert);
        this.$feathers.on("connected", this.reconnect);
        this.$feathers.on("login", this.login);
        this.$feathers.on("logout", this.logout);

        this.hourTimer = setTimeout(this.updateHourly, (3600 - ((Date.now() / 1000) | 0) % 3600) * 1000);

        this.db = await openDB<IDB>("metrics", 1, {
            upgrade(db) {
                const metrics = db.createObjectStore("metrics", {
                    keyPath: 'id',
                    autoIncrement: true,
                });
                metrics.createIndex('byDeviceTime', ['device', 'time'], { unique: true });
                const metricsDaily = db.createObjectStore("metricsDaily", {
                    keyPath: 'id',
                    autoIncrement: true,
                });
                metricsDaily.createIndex('byDeviceTime', ['device', 'time'], { unique: true });
            }
        })
    }

    updateLastUpdate() {
        for(let device of this.devices) {
            device.updateLastUpdate();
        }
    }

    updateHourly() {
        console.log('[Hourly Timer]');
        this.device?.reloadForecast();
        this.hourTimer = setTimeout(this.updateHourly, (3600 - ((Date.now() / 1000) | 0) % 3600) * 1000);
    }

    init() {
        return (this.loadingPromise || (this.loadingPromise = this.initCore()));
    }

    async initCore() {
        this.loading = true;
        await this.setup();
        try {
            const localMetricsHash = localStorage.getItem("metricsHash");
            const metricsData = await this.$root.$feathers.service('metrics').find({
                query: {
                    hash: localMetricsHash,
                }
            });
            if(metricsData.data) {
                localStorage.setItem("metrics", JSON.stringify(this.metrics = msgpack.decode(metricsData.data)));
                localStorage.setItem("metricsHash", this.metricsHash = metricsData.hash);
            } else {
                this.metrics = JSON.parse(localStorage.getItem("metrics"));
                this.metricsHash = localStorage.getItem("metricsHash");
            }
            this.metricsConverter = arrayToObj(['_id', 'time', 'unstablei', ...this.metrics.map(it => it.key)]);

            const u = this.$store.getters.userId;
            await this.loadDevices(this.publicSync);
        } catch(e) {
            this.error = true;
        } finally {
            this.loading = false;
        }
    }

    async loadDevices(isPublic: boolean = false) {
        if (isPublic) this.publicSync = true;
        const query = isPublic ? {} : {
            $devices: {
                owned: true,
                shared: true,
                public: this.$store.state.settings.publicDevices
            },
        };

        let devices : WeatherDevice[] = <any>(await this.$root.$feathers.service("weatherDevices").find({
            query: {
                $paginate: false,
                ...query
            },
        }));
        for(let device of this.devices) {
            device.order = -1;
        }
        for(let i = 0; i < devices.length; i++) {
            const manager = this.addDevice(devices[i]);
            manager.order = i;
        }
        for(let remove of this.devices.filter(it => it.order === -1)) {
            this.removeDevice(remove.device);
        }
        if(_.some(this.devices, (d, i) => d.order !== i)) {
            this.devices.sort((a, b) => (a.sortGroup - b.sortGroup) || (a.order - b.order))
        }
        await this.loadLastBatch();
    }

    async loadLastBatch() {
        const data = msgpack.decode(await this.$feathers.service('weatherMetrics/batchLastData').find({
            query: {
                devices: this.devices.map(it => it.id)
            }
        }));


        const [columns, series] = data;
        const convert = arrayToObj(columns);
        for(let item of series) {
            const current : CurrentDataType = convert(item);
            const device = this.deviceDict[current.device];
            if(device) device.updateMetrics(current);
        }
        for(let item of this.devices) {
            item.loading = false;
        }
    }

    addDevice(device : WeatherDevice) {
        let manager = this.deviceDict[device._id];
        if(!manager) {
            manager = new WeatherDeviceManager(getOptions(this.$options));
            manager.setup(this, device);
            this.deviceDict[device._id] = manager;
            const idx = this.devices.findIndex(it => it.sortGroup > manager.sortGroup);
            if(idx === -1) this.devices.push(manager);
            else this.devices.splice(idx, 0, manager);
        } else {
            manager.update(device);
        }
        manager.updatePlan();
        return manager;
    }

    removeDevice(device : WeatherDevice) {
        let manager = this.deviceDict[device._id];
        if(manager) {
            delete this.deviceDict[device._id];
            const idx = this.devices.indexOf(manager);
            if(idx !== -1) this.devices.splice(idx, 1);
        }
    }

    async forgetDevice(device : WeatherDeviceManager) {
        if(!device.public) return;
        this.removeDevice(device.device);
        if(this.device === device) {
            await this.selectDevice(this.devices[0]);
        }
        this.$store.commit("SET_SETTINGS", {
            publicDevices: this.devices.filter(it => it.public).map(it => it.device._id),
        })
    }

    async openDeviceByID(id : string) {
        if(!id) return false;
        let device = this.devices.find(it => it.id === id);
        if(device) {
            await this.selectDevice(device);
            return true;
        }
        const deviceInfo = (await this.$feathers.service('weatherDevices').find({
            query: {
                _id: id,
                $paginate: false,
            }
        }))[0];
        if(!deviceInfo) return false;
        await this.selectDevice(this.addDevice(deviceInfo));
        return true;
    }

    async openDeviceByWSID(wsid : string) {
        if(!wsid) return false;
        let device = this.devices.find(it => it.device.wsid === wsid);
        if(device) {
            this.device = device;
            await this.selectDevice(device);
            return true;
        }
        const deviceInfo = (await this.$feathers.service('weatherDevices').find({
            query: {
                wsid,
                $paginate: false,
            }
        }))[0];
        if(!deviceInfo) return false;
        await this.selectDevice(this.addDevice(deviceInfo));
        this.$store.commit("SET_SETTINGS", {
            publicDevices: this.devices.filter(it => it.public).map(it => it.device._id),
        })
        return true;
    }

    reset(dispose = false) {
        this.loadingPromise = null;
        this.subscribed = false;
        if(dispose) {
            this.deviceDict = {};
            this.devices = [];
        }
    }

    async selectDevice(device : WeatherDeviceManager) {
        if(this.device === device) return;
        this.device = device;
        await this.subscribeDevice();
        if(this.device) {
            await Promise.all([
                this.device.preload(),
                this.device.prepare()
            ])
        }
    }

    async subscribeDevice() {
        if(this.subscribed) {
            this.subscribed = false;
            await this.$feathers.service('weatherDevices/watch').remove(null);
        }

        if(this.manageMode || this.device) {
            this.subscribed = true;
            await this.$feathers.service('weatherDevices/watch').create({
                id: this.manageMode ? this.devices.map(it => it.id) : this.device.id,
            })
        }
    }

    onDeviceUpdated(data : WeatherDevice) {
        this.addDevice(data);
    }

    onDeviceCreated(data : WeatherDevice) {
        this.addDevice(data);
        if(this.manageMode) {
            this.subscribeDevice();
        }
    }

    onDisplaySettings(data : DisplaySetting) {
        const device = this.deviceDict[`${data.weatherDevice}`];
        if(device) device.updateDisplay(data);
    }

    async onWeatherMetrics(buf : any) {
        const data = msgpack.decode(buf);
        if(Buffer.from(data[0]).toString('hex') !== this.metricsHash) {
            console.warn("Metrics changed, need reinit");
            this.loadingPromise = null;
            this.reset();
            await this.init();
        }
        if(!this.metricsConverter) return;
        const item = this.metricsConverter(data[1]);
        const device = this.deviceDict[item._id];
        if(device) device.updateMetrics(item);
    }

    onWeatherMetricsHistory(buf : any) {
        const data = msgpack.decode(buf);
        const device = this.deviceDict[data._id];
        if(device) device.updateMetricsHistory(data);
    }

    onWeatherForecast(buf : any) {
        const data = msgpack.decode(buf);
        const device = this.deviceDict[data._id];
        if(device) {
            device.updateForecast(data);
        }
    }

    onWeatherAlert(data : any) {
        const device = this.deviceDict[data.device];
        if(device) device.updateAlert(data);
    }

    async reconnect() {
        if(this.loading) return;
        this.reset();
        await this.init();
        await this.subscribeDevice();
    }

    login() {
        if(this.loading) return;
        this.reconnect();
    }

    async logout() {
        this.reset(true);
    }
}

declare module 'vue/types/vue' {
    export interface Vue {
        $weatherManager : WeatherManager
    }
}

let weatherManager : WeatherManager;

Object.defineProperty(Vue.prototype, "$weatherManager", {
    get(this : Vue) {
        return weatherManager || (weatherManager = new WeatherManager(getOptions(this.$root.$options)));
    }
})
