import { StripePlugin } from '@vue-stripe/vue-stripe'
import { Vue } from 'nuxt-property-decorator'

declare var STRIPE_PUBLIC_KEY: string;

const options = {
  pk: STRIPE_PUBLIC_KEY
}

Vue.use(StripePlugin, options)
