import { Component, Prop, Watch, Vue, mixins } from "nuxt-property-decorator";
import { DeviceBase } from './deviceBase'
import type DB from '@db'
import _ from 'lodash'

export type AlertSetting = (typeof DB)['AlertSetting']['_mongoType']

@Component
export class AlertMixin extends mixins(DeviceBase) {
    alertReady = false;
    alertSettings: AlertSetting = null;
    activeAlerts : any[] = [];

    loadAlertPromise: Promise<void>;
    loadAlert() {
        return this.loadAlertPromise || (this.loadAlertPromise = this.loadAlertCore());
    }

    async saveAlert() {
        if(this.alertSettings._id) {
            await this.$feathers.service('alertSettings').patch(this.alertSettings._id, this.alertSettings);
        } else {
            this.alertSettings = _.assign({
                weatherDevice: <any>this.id,
                settings: [],
                disableAll: false,
            }, await this.$feathers.service('alertSettings').create(this.alertSettings));
        }
    }

    async loadAlertCore() {
        const setting : AlertSetting = (<any>(await this.$feathers.service('alertSettings').find({
            query: {
                weatherDevice: this.id,
                $paginate: false,
            }
        })))[0];
        this.alertSettings = _.assign({
            weatherDevice: <any>this.id,
            settings: [],
            disableAll: false,
        } as any as AlertSetting, setting);
        this.alertReady = true;
    }

    async updateAlerts() {
        await this.$feathers.service("alerts").find({
            query: {
                device: this.id,
            }
        });
        this.activeAlerts = [];
    }

    async hideThisAlert(id : string) {
        if (!id) return;
        try {
            await this.$feathers.service("alerts/hide").find({
                query: {
                    device: this.id,
                    id: id
                }
            });
            this.activeAlerts = _.filter(this.activeAlerts, it => it._id != id);
        } catch (e : any) {
            console.error(e);
        }
    }

    updateAlert(data : any) {
        if (data.status != 'active' || !data.lastTrigger) return;
        this.activeAlerts = _.filter(this.activeAlerts, it => it._id != data._id);
        this.activeAlerts.push(data);
    }
}

