import type { ComponentOptions } from 'vue'
import Msgpack from 'msgpack5'
import type DB from '@db'
export type WeatherDevice = typeof DB['WeatherDevice']['_mongoType']
export type DisplaySetting = typeof DB['DeviceDisplaySetting']['_mongoType']
import { 
    DBSchema,
} from 'idb'
import type { MetricValue } from './value'
import _ from 'lodash'
import moment from 'moment-timezone'
import { MetricRange } from './rangeValue'

export interface MetricUnitType {
    name?: string
    displayName?: string
    unit: string
    ratio? : number
    offset? : number
    dp? : number
}

export interface MetricType {
    key: string
    unitType?: string
    unit: string
    dp?: number
    name? : string
    displayName?: string
    min? : number
    max? : number
    test? : number
    testStatic? : number | string
    ratio? : number
    offset? : number
    units? : MetricUnitType[],
    type?: 'int' | 'float' | 'str',
    export: boolean
    save? : boolean
    sync? : boolean
}

export type CurrentDataType = {
    [key : string]: number | string
    device: string
    time: number
}

export const msgpack = Msgpack();

export function getOptions(v : ComponentOptions<Vue>) {
    return {
        router: v.router,
        store: v.store,
        vuetify: v.vuetify,
        nuxt: (v as any).nuxt,
        $feathers: v.$feathers,
        i18n: v.i18n,
    }
}

export interface IDB extends DBSchema {
    metrics: {
        key: string,
        indexes: { byDeviceTime: [string, number] },
        value: {
            device: string,
            time: number,
            [key : string]: number | string,
        }
    },
    metricsDaily: {
        key: string,
        indexes: { byDeviceTime: [string, number] },
        value: {
            device: string,
            time: number,
            [key : string]: number | string,
        }
    }
}

export function isNum(v : number | null | undefined) {
    return typeof v === 'number' && !isNaN(v);
}

export function arrayToObj(fields : string[], device? : string) : any {
    return new Function("item", `
        return {
            ${device ? `device: ${JSON.stringify(device)},` : ''}
            ${fields.map((f, idx) => `${f}: item[${idx}]`)}
        }
    `);
}

export type ObjDef = string | [string, ObjDef[]]

export function getArrayToObjStr(metrics : ObjDef[], flatten = false) {
    const funcCode : string[] = [];

    const metricsWithIndex = metrics.map((it, idx) => Array.isArray(it) ? ({
        group: it[0].split('.')[0],
        self: it[0].indexOf('.') === -1 || flatten,
        key: it[0],
        idx,
        it,
    }) : ({
        group: it.split('.')[0],
        self: it.indexOf('.') === -1 || flatten,
        key: it,
        idx,
        it,
    }));
    const metricGps = _.toPairs(_.groupBy(metricsWithIndex, m => flatten ? m.key : m.group));
    funcCode.push('{');
    for(let [m, v] of metricGps) {
        const isSelf = v.every(m => m.self);
        let current = `"${m}": `
        if(isSelf) {
            const item = v[0];
            current += `item[${item.idx}]`;
            if(Array.isArray(item.it)) {
                current += `.map(item=>{return ${getArrayToObjStr(item.it[1])}})`
            }
        } else {
            current += getArrayToObjStr(v.map(it => Array.isArray(it.it) ? [it.it[0].substr(m.length + 1), it.it[1]] : it.it.substr(m.length + 1)));
        }
        funcCode.push(current + ',');
    }
    funcCode.push('}');
    return funcCode.join('\n');

}

export function getArrayToObj(metrics : ObjDef[], flatten = false) : (item : any) => any {
    const func = getArrayToObjStr(metrics, flatten);
    return <any>new Function("item", `return ${func}`);
}

export interface UnitOpts {
    unitType: string
    unit: string
    dp: number
    min?: number
    max?: number
    units?: {
        unit: string
        ratio?: number
        offset?: number
        dp?: number
    }[]
}

export const units = {
    temperature: {
        unitType: "temperature",
        unit: "°C",
        displayName: "°",
        dp: 1,
        min: -40,
        max: 60,
        units: [
            {
                unit: '°F',
                displayName: "°",
                ratio: 1.8,
                offset: 32,
                dp: 1,
            }
        ]
    },
    rain: {
        unitType: "rain",
        unit: "mm",
        displayName: "mm/h",
        dp: 1,
        min: 1,
        max: 50,
        units: [
            {
                unit: 'in',
                displayName: "in/h",
                ratio: 0.03937,
                offset: 0,
                dp: 2,
            }
        ]
    },
    humidity: {
        unitType: 'humidity',
        unit: '%',
        dp: 1,
        min: 1,
        max: 99,
    },
    uvi: {
        unitType: 'uvi',
        unit: '**',
        dp: 1,
        min: 1,
        max: 16,
    },
    windSpeed: {
        unitType: "windSpeed",
        unit: "m/s",
        dp: 1,
        min: 0.1,
        max: 50,
        units: [
            {
                unit: 'km/h',
                ratio: 3.6,
                offset: 0,
                dp: 1,
            },
            {
                unit: 'mph',
                ratio: 2.23693,
                offset: 0,
                dp: 1,
            },
            {
                unit: 'knot',
                ratio: 1.94384,
                offset: 0,
                dp: 1,
            },
        ]
    },
    baroPressure: {
        unitType: "baroPressure",
        unit: "hPa",
        dp: 1,
        min: 1,
        max: 20,
        units: [
            {
                unit: 'inHg',
                ratio: 0.02953,
                offset: 0,
                dp: 2,
            },
            {
                unit: 'mmHg',
                ratio: 0.75006,
                offset: 0,
                dp: 1,
            }
        ]
    },
    lightIntensity: {
        unitType: "lightIntensity",
        unit: "W/m\u00B2",
        dp: 2,
        min: 1,
        max: 200000,
        units: [
            {
                unit: 'Lux',
                ratio: 126.58,
                offset: 0,
                dp: 0,
            },
            {
                unit: 'Kfc',
                ratio: 0.01175928,
                offset: 0,
                dp: 2,
            }
        ]
    },
    pm: {
        unitType: 'pm',
        unit: 'µg/m³',
        dp: 1,
        min: 500,
    },
    forecast_visibility: {
        unitType: 'forecast_visibility',
        unit: 'm',
        dp: 1,
        units: [
            {
                unit: 'km',
                ratio: 1/1000,
                offset: 0,
                dp: 1,
            }
        ]
    },
    probability: {
        unitType: 'probability',
        unit: '**',
        dp: 2,
        units: [
            {
                unit: '%',
                ratio: 100,
                offset: 0,
                dp: 0,
            }
        ]
    },
    hcho: {
        unitType: "hcho",
        unit: "ppb",
        dp: 0,
        units: [
            {
                unit: 'mg/m\u00B3',
                ratio: 0.001218,
                offset: 0,
                dp: 3,
            },
        ]
    },
    co2: {
        unitType: "co2",
        unit: "ppm",
        dp: 1,
        units: [
            {
                unit: 'mg/m\u00B3',
                ratio: 1.8,
                offset: 0,
                dp: 0,
            },
        ]
    },
    pm2510: {
        unitType: "pm2510",
        unit: 'ug/m\u00B3',
        dp: 0,
    },
    distance: {
        unitType: "distance",
        unit: "km",
        dp: 0,
        units: [
            {
                unit: 'miles',
                ratio: 0.62137119,
                offset: 0,
                dp: 1,
            },
        ]
    }
}

export interface MetricDetailInfo {
    name: string
    key: string
    value?: MetricValue
    values?: MetricValue[]
    mins? : (MetricValue | MetricRange)[]
    maxs? : (MetricValue | MetricRange)[]
    minColor?: string
    maxColor?: string
    showHigh? : boolean
    showLow? : boolean
    min: number
    max: number
    minKey?: string
    maxKey?: string
    dynamicRange?: boolean
}

export interface GraphDataOptions {
    key?: string
    value?: MetricValue | (()=>MetricValue);
    color: string
    name?: string
    aggregate? : 'mean' | 'min' | 'max' | 'last'
    hidden?: boolean,
    unit?: string
    type?: string
    accum? : boolean | ((range : string) => boolean)
}

export interface GraphOptions {
    key: string
    datasets: GraphDataOptions[]
    beginAtZero? : boolean
    min? : number
    max? : number
    stepSize? : number
    isBarChart?: () => boolean
    chartData? : any
    callback?: (value : any, index : any, values : any) => string
}

export interface GraphGroupOptions {
    key: string
    graphs: GraphOptions[]
    nameKey?: string
    nameNo?: number
    channelName?: string
    metricKey?: string
}

export interface GraphFilter {
    key: string
    pickerType?: string
    graphOptions?: any
    mobileGraphOptions?: any
}

export const graphFilters : GraphFilter[] = [
    { 
        key: 'day',
        graphOptions: {
            autoSkip: false,
            callback: function(value, index, values) {
                if (_.endsWith(value, '00') && (+value.split(':')[0] % 2 === 0)) return value;
            }
        },
        mobileGraphOptions: {
            autoSkip: false,
            callback: function(value, index, values) {
                if (_.endsWith(value, '00') && (+value.split(':')[0] % 4 === 0)) return value;
            }
        }
    },
    { 
        key: 'last24H',
        graphOptions: {
            autoSkip: false,
            callback: function(value, index, values) {
                if (_.endsWith(value, '00') && (+value.split(':')[0] % 2 === 0)) return value;
            }
        },
        mobileGraphOptions: {
            autoSkip: false,
            callback: function(value, index, values) {
                if (_.endsWith(value, '00') && (+value.split(':')[0] % 4 === 0)) return value;
            }
        }
    },
    { 
        key: 'week',
        graphOptions: {
            autoSkip: false,
            callback: function(value, index, values) {
                if (value && value.length > 5) return value.substr(5);
            }
        }
    },
    { 
        key: 'month', 
        pickerType: 'month',
        graphOptions: {
            autoSkip: false,
            callback: function(value, index, values) {
                if(value) {
                    const lastDate = values[index - 1];
                    if(!lastDate || lastDate.substr(8, 2) !== value.substr(8, 2)) {
                        if((+value.substr(8, 2)) % 2 === 0) return;
                        return value.substr(8, 2);
                    }
                }
                
            }
        }
    },
    { 
        key: 'year', 
        pickerType: 'year',
        graphOptions: {
            autoSkip: false,
            callback: function(value, index, values) {
                if (_.endsWith(value, '-01')) return value.substr(5, 2);
            }
        }
    },
    // { 
    //     key: 'custom',
    //     graphOptions: {
    //         autoSkip: false,
    //         callback: function(value, index, values) {
    //             const isDay = values?.[0]?.length === 5;
    //             if(isDay) {
    //                 if (_.endsWith(value, '00') && (+value.split(':')[0] % 2 === 0)) return value;
    //                 return;
    //             }
    //             const isYear = values?.[0]?.length === 10 && values?.[1]?.length === 10;
    //             if(isYear) {
    //                 if (_.endsWith(value, '-01')) return value.substr(5, 2);
    //                 return;
    //             }
    //             if(value) {
    //                 const lastDate = values[index - 1];
    //                 if(value.length === 10) return value;
    //                 else if(value.length === 5) return 
    //                 if(!lastDate || lastDate.substr(5, 2) !== value.substr(5,2)) {
    //                     return value.substr(5, 5);
    //                 } else if(lastDate.substr(8, 2) !== value.substr(8, 2)) {
    //                     if((+value.substr(8, 2)) % 2 === 0) return;
    //                     return value.substr(8, 2);
    //                 }
    //             }
                
    //         }
    //     }
    // },
];

export function floorDP(value : number, dp : number) {
    if(dp === 0) return Math.floor(value);
    const dpRatio = Math.pow(10, dp);
    return Math.floor(value * dpRatio) / dpRatio;
}

export function roundDP(value : number, dp : number) {
    if(dp === 0) return Math.round(value);
    const dpRatio = Math.pow(10, dp);
    return Math.round(value * dpRatio) / dpRatio;
}

export const ranges = [
    { key: 'today', label: 'today', range: '0d-' },
    { key: 'week', label: 'week', range: '0w-' },
    { key: 'month', label: 'month', range: '0m-' },
    { key: 'year', label: 'year', range: '0y-' },
];
